import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import BerryLogo from "../../assets/png/Berry Logo.png";
import { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../reducers/ChangeLanguageSlice";
import { useTheme } from "@emotion/react";
import { withTranslation } from "react-i18next";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import Cookies from "js-cookie";
import i18next from "i18next";
import { REPORTS_MENU_ITEMS } from "../../constants/DowntimeReports";
import { logOutUser } from "../../reducers/logoutSlice";

export const LanguageChange = () => {
  const selectedLanguage = Cookies.get("i18next") ? Cookies.get("i18next") : "en";
  const languageList = ["en", "fr"];
  const [language, setLanguage] = useState(selectedLanguage);
  const [anchorLanguage, setAnchorLanguage] = useState(null);

  const dispatch = useDispatch();

  const handleLanguageChange = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const closeLanguageChoose = (event) => {
    const { lang } = event.currentTarget.dataset;
    setAnchorLanguage(null);
    if (lang) {
      setLanguage(lang);
      i18next.changeLanguage(lang);
      const fetchApi = () => {
        dispatch(changeLanguage(lang));
      };
      fetchApi();
    }
  };

  
  const theme = useTheme();

  const fontSizeStyle = {
    fontSize: "0.875rem",
  };

  return (
    <>
      <LanguageIcon sx={{ color: theme.palette.primary.main }} />
      <Typography
        sx={{
          ml: 1,
          cursor: "pointer",
          display: "flex",
          borderRight: `1px solid ${theme.palette.text.secondary}`,
          alignItems: "center",
          paddingRight: "0.938rem",
          color: theme.palette.text.primary,
          ...fontSizeStyle,
        }}
        onClick={handleLanguageChange}
      >
        {language.toUpperCase()}
        {Boolean(anchorLanguage) ? <ArrowDropUp /> : <ArrowDropDown />}
      </Typography>
      <Menu
        sx={{ mt: "25px" }}
        anchorEl={anchorLanguage}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorLanguage)}
        onClose={closeLanguageChoose}
        autoFocus={false}
      >
        {languageList.map((lang, index) => {
          return (
            <MenuItem
              data-lang={lang}
              key={index}
              onClick={closeLanguageChoose}
            >
              <Typography>{lang.toUpperCase()}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const Header = ({
  authProvider,
  userDetails = {
    userName: authProvider?.account?.userName,
    name: authProvider?.account?.name,
  },
  isAdmin,
  t,
}) => {
  const [anchorElReport, setAnchorElReport] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    dispatch(logOutUser());
    authProvider.logout();
  };

  const handleOpenReportMenu = (event) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleCloseReportMenu = () => {
    setAnchorElReport(null);
  };

  const theme = useTheme();
  const name = userDetails?.name || "";
  const fontSizeStyle = {
    fontSize: "0.875rem",
  };

  function stringAvatar() {
    const checker = name.includes(" ");
    const displayName = checker
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${String(name[0]).toUpperCase()}${[String(name[1]).toUpperCase()]}`;
    return {
      sx: {},
      children: displayName,
    };
  }

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const activeStyles =
    splitLocation && splitLocation[2] && splitLocation[2].includes("reports")
      ? {
          color: theme.palette.primary.main,
          textDecoration: "underline",
          fontWeight: "700",
          marginLeft: "2.5rem",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          ...fontSizeStyle,
        }
      : {
          marginLeft: "2.5rem",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          ...fontSizeStyle,
        };

  const activeHomeStyle =
    splitLocation.length <= 2 && splitLocation[1] === "home" 
      ? {
          fontWeight: "700",
          color: theme.palette.primary.main,
          textDecoration: "underline",
        }
      : {
          fontWeight: "700",
          color: theme.palette.text.primary,
          textDecoration: "none",
        };
  return (
    <AppBar color="secondary">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: "0.938rem",
        }}
        variant="dense"
        disableGutters
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            sx={{ height: 40, width: 65, mr: 1 }}
            alt="berry global logo"
            src={BerryLogo}
          />
          {!isAdmin && <NavLink
            to="/home"
            style={{
              ...activeHomeStyle,
              borderLeft: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography
              sx={{
                ml: 3,
                ...activeHomeStyle,
                ...fontSizeStyle,
              }}
            >
              {t("header.home_nav")}
            </Typography>
          </NavLink>}
          {!isAdmin && <Typography
            className={splitLocation.includes("reports") ? "active" : ""}
            sx={
              splitLocation.includes("reports")
                ? activeStyles
                : {
                    marginLeft: "2.5rem",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    ...fontSizeStyle,
                  }
            }
            onClick={handleOpenReportMenu}
          >
            {t("header.report_nav")}
            {Boolean(anchorElReport) ? <ArrowDropUp /> : <ArrowDropDown />}
          </Typography>}
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorElReport}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorElReport)}
            onClose={handleCloseReportMenu}
          >
            {REPORTS_MENU_ITEMS.map(
              ({ id, label, url, translationKey }, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(url);
                      handleCloseReportMenu();
                    }}
                  >
                    <Typography>{t(translationKey)}</Typography>
                  </MenuItem>
                );
              }
            )}
          </Menu>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LanguageChange />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 3,
            }}
          >
            <Avatar
              alt="User"
              {...stringAvatar()}
              sx={{
                width: 22,
                height: 22,
                backgroundColor: theme.palette.primary.main,
                fontSize: "10px",
              }}
            />
            <Typography
              sx={{
                ml: 2,
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.primary,
                pr: "0.938rem",
                ...fontSizeStyle,
              }}
              onClick={handleOpenUserMenu}
            >
              {name}
              <ArrowDropDown />
            </Typography>
          </Box>
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{t("header.logout")}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default withTranslation()(Header);
