import { Snackbar, Modal,Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";

const Notification = ({ severity, position, open, onClose, message }) => {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          p: 4,
        }}
      >
        <Snackbar
          open={open}
          // autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            onClose();
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default Notification;
