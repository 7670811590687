import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: [],
  message: "",
};

export const logOutUser = createAsyncThunk(
  "logOutUser",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}user/logout`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed logout user");
    }
  }
);

const LogOutUserSlice = createSlice({
  initialState,
  name: "userState",
  reducers: {},
  extraReducers: {
    [logOutUser.pending]: (state) => {
      state.isLoading = true;
    },
    [logOutUser.fulfilled]: (state, action) => {
      state.message = "success";
      state.items = action.payload;
      state.isLoading = false;
    },
    [logOutUser.rejected]: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
  },
});

export default LogOutUserSlice.reducer;
