import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: [],
  message: "",
  statusText: "",
  severity: "",
};

/*

Get Manage Permissions

*/
export const getManagePermissions = createAsyncThunk(
  "getManagePermissions",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }user-permission-details`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get permissions");
    }
  }
);

export const updateManagePermissions = createAsyncThunk(
  "updateManagePermissions",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}updatePermissions`;
    try {
      const resp = await axios.post(url, params);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get permissions");
    }
  }
);

export const deleteManagePermissions = createAsyncThunk(
  "deleteManagePermissions",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}deletePermissions`;
    try {
      const resp = await axios.post(url, params);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to delete permissions");
    }
  }
);

export const copyPermissionForNewEmail = createAsyncThunk(
  "copyPermissionForNewEmail",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}addPermission`;
    try {
      const resp = await axios.post(url, params);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response && error.response.data && error.response.data.message);
    }
  }
);

export const managePermissions = createSlice({
  initialState,
  name: "managePermissions",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getManagePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getManagePermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
        state.items && state.items.map((row)=>{
          row.isDirty= false;
          row.isPlantChanged = false;
        })
      })
      .addCase(getManagePermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(updateManagePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateManagePermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(updateManagePermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(deleteManagePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteManagePermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(deleteManagePermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(copyPermissionForNewEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(copyPermissionForNewEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(copyPermissionForNewEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "error";
        state.severity = "error";
      })
  },
});

export default managePermissions.reducer;
