import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  descriptionText,
  noButtonText,
  yesButtonText,
  onNoClick,
  onYesClick,
}) => {
  const theme = useTheme();
  const buttonStyles = {
    textTransform: "uppercase",
    fontWeight: "600",
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        variant="h5"
        fontWeight="500"
        lineHeight="2rem"
        fontSize="1.25rem"
        color={theme.palette.common.black}
        sx={{ pb: 0 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <DialogContentText
          sx={{
            mt: 1,
            color: theme.palette.common.black,
          }}
        >
          {descriptionText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNoClick} sx={buttonStyles}>
          {noButtonText}
        </Button>
        <Button onClick={onYesClick} sx={buttonStyles}>
          {yesButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
