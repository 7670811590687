export const REPORTS_MENU_ITEMS = [
  {
    id: "current",
    label: "Current Entries",
    url: "/home/reports/current-entries",
    translationKey: "reports.menu_items.current_entries",
  },
  {
    id: "downtime",
    label: "Downtime",
    url: "/home/reports/downtime",
    translationKey: "reports.menu_items.downtime",
  },
];

export const DOWNTIME_REPORTS_COLUMNS = [
  // {
  //   id: "id",
  //   label: "reports.current_entries.table_columns.sno",
  // },
  {
    id: "input_date",
    label: "reports.current_entries.table_columns.input_date",
  },
  {
    id: "shift",
    label: "reports.downtime.table_columns.shift",
  },
  {
    id: "machine",
    label: "reports.downtime.table_columns.machine",
  },
  {
    id: "reason",
    label: "reports.downtime.table_columns.reason",
  },
  {
    id: "remark",
    label: "reports.downtime.table_columns.remark",
  },
  {
    id: "downtime",
    label: "reports.downtime.table_columns.downtime",
  },
];
