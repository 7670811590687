export const DATE_FORMAT = 'yyyy-MM-dd';
export const SELECT_DATE_FORMAT = 'dd/MM/yyyy';
export const USER_SUGGESTED_DATE_FORMAT = 'MM/dd/yyyy'
export const PROCEED_BUTTON_TEXT = "Proceed";
export const TEXTFIELD_MAXLENGTH = 20;
export const REMARKS_MAXLENGTH = 150;
export const SCRAP_ITEMS_MAXLENGTH = 100;
export const QUATERLY_OPTIONS = [];
for(let i=0; i<=11; i++){
  for(let j=1; j<=4; j++){
    QUATERLY_OPTIONS.push(String(i+(0.25*j)));
  }
};
// QUATERLY_OPTIONS.unshift('0');

export const DISABLED_TYPES = ["NA", "NS"];

export const DATA_ENTRY = "Data Entry";
export const DATA_PROCESSING = "Data Processing";

export const onlyCapitalAlphanumeric = /^[A-Z0-9]+$/;