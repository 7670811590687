export const CURRENT_ENTRIES_REPORTS_COLUMNS = [
  // {
  //   id: "id",
  //   label: "reports.current_entries.table_columns.sno",
  // },
  {
    id: "input_date",
    label: "reports.current_entries.table_columns.input_date",
  },
  {
    id: "shift",
    label: "reports.current_entries.table_columns.shift",
  },
  {
    id: "machine",
    label: "reports.current_entries.table_columns.machine",
  },
  {
    id: "runtime",
    label: "reports.current_entries.table_columns.runtime",
  },
];
