import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  isTimezoneLoading: false,
  isPlantsLoading: false,
  isDepartmentsLoading: false,
  plantsList: [],
  departmentsList: [],
  timezonesList: [],
  message: "",
  statusText: "",
  severity: "",
};

/*
  Get timezones
*/
export const getTimezones = createAsyncThunk(
  "getTimezones",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}timezone`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get timezones");
    }
  }
);

export const getPlantsList = createAsyncThunk(
  "getPlantsList",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}plant-list`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get plants list");
    }
  }
);

export const getDepartmentsList = createAsyncThunk(
  "getDepartmentsList",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}department`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get departments list");
    }
  }
);

export const createNewPlantAndDepartment = createAsyncThunk(
  "createNewPlantAndDepartment",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }plantAndDepartmentMapping`;
    try {
      const resp = await axios.post(url, params);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const AddNewPlantDepartment = createSlice({
  initialState,
  name: "addNewPlantDepartment",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTimezones.pending, (state) => {
        state.isTimezoneLoading = true;
      })
      .addCase(getTimezones.fulfilled, (state, action) => {
        state.message = "success";
        state.timezonesList = action.payload;
        state.isTimezoneLoading = false;
      })
      .addCase(getTimezones.rejected, (state, action) => {
        state.message = action.payload;
        state.isTimezoneLoading = false;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(getPlantsList.pending, (state) => {
        state.isPlantsLoading = true;
      })
      .addCase(getPlantsList.fulfilled, (state, action) => {
        state.message = "success";
        state.plantsList = action.payload;
        state.isPlantsLoading = false;
      })
      .addCase(getPlantsList.rejected, (state, action) => {
        state.message = action.payload;
        state.isPlantsLoading = false;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(getDepartmentsList.pending, (state) => {
        state.isDepartmentsLoading = true;
      })
      .addCase(getDepartmentsList.fulfilled, (state, action) => {
        state.message = "success";
        state.departmentsList = action.payload;
        state.isDepartmentsLoading = false;
      })
      .addCase(getDepartmentsList.rejected, (state, action) => {
        state.message = action.payload;
        state.isDepartmentsLoading = false;
        state.statusText = "error";
        state.severity = "error";
      })
      .addCase(createNewPlantAndDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPlantAndDepartment.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(createNewPlantAndDepartment.rejected, (state, action) => {
        state.message = action.payload.response.data.statusText;
        state.isLoading = false;
        state.statusText = "error";
        state.severity = "error";
      });
  },
});

export default AddNewPlantDepartment.reducer;
