import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const proceedTextStyles = {
  fontWeight: "400",
  letterSpacing: "0.25px",
  lineHeight: "32px",
  fontSize: "18px",
};

const EmptyList = ({ t }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h6"
      sx={{ ...proceedTextStyles, color: theme.palette.custom.darkerText }}
    >
      {t('machine_list.empty_list_text')}
    </Typography>
  );
};

export default withTranslation()(EmptyList);