import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  plantList: [],
  roles: [],
  departments: [],
  message: "",
  statusText: "",
  severity: "",
};

/**
 * Get Plant details
 */
export const getPlantRequestDetails = createAsyncThunk(
  "getPlantRequestDetails",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}plant-list`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to get Plant details list");
    }
  }
);

/**
 * Get Role details
 */
export const getAllRoles = createAsyncThunk(
  "getAllRoles",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}allroles`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to get Role list");
    }
  }
);

/**
 * Get Department details
 */
export const getDepartmentFromPlantId = createAsyncThunk(
  "getDepartmentFromPlantId",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }plant-dept-mapping/${params}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to get Role list");
    }
  }
);

/**
 * Save Request Access Details
 */
export const addAccessRequestDetails = createAsyncThunk(
  "addAccessRequestDetails",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }plantRequest`;
    try {
      const resp = await axios.post(url,params);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response && error.response.data && error.response.data.message);
    }
  }
);
export const PlantRequestSlice = createSlice({
  initialState,
  name: "plantRequestState",
  extraReducers: (builder) => {
    builder
      .addCase(getPlantRequestDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlantRequestDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.plantList = action.payload;
      })
      .addCase(getPlantRequestDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //for roles state
      .addCase(getAllRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.roles = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //for department state
      .addCase(getDepartmentFromPlantId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartmentFromPlantId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.departments = action.payload;
      })
      .addCase(getDepartmentFromPlantId.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //for access request state
      .addCase(addAccessRequestDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAccessRequestDetails.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(addAccessRequestDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
        state.statusText = "failed";
        state.severity = "error";
      });
  },
});

export default PlantRequestSlice.reducer;
