import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {ALL_CREWS} from '../constants/HomeScreen';

const initialState = {
    isLoading: false,
    items: [],
    message: '',
    statusText: '',
    severity:'',
}

export const getRunTimeData = createAsyncThunk('getRunTimeData', async (params, thunkAPI) => {
    const newParams = {...params, crew: params.crew === ALL_CREWS ? null : params.crew};
    const url = `${JSON.parse(localStorage.berryConfig).URL}productiondetails`;
    try{
        const resp = await axios.post(url, newParams);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue('Failed to get Runtime list');
    }
});

export const addRunTimeData = createAsyncThunk('addRunTimeData', async (params, thunkAPI) => {
    const newParams = {...params, crew: params.crew === ALL_CREWS ? null : params.crew};
    const url = `${JSON.parse(localStorage.berryConfig).URL}productiondetails/add`;
    try{
        const resp = await axios.post(url, newParams);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue(err.response && err.response.data && err.response.data.message);
    }
});

export const deleteRunTimeData = createAsyncThunk('deleteRunTimeData', async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}productiondetails/delete`;
    try{
        const resp = await axios.delete(url, {data: params});
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue(err.response && err.response.data && err.response.data.message);
    }
});

export const RuntimeDetailSlice = createSlice({
    initialState,
    name: 'runtimeState',
    reducers: {

    },
    extraReducers: {
        [getRunTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [getRunTimeData.fulfilled]: (state, action) => {
            state.items = action.payload;
            state.isLoading = false;
        },
        [getRunTimeData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        },

        [addRunTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [addRunTimeData.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.statusText = 'success';
            state.severity = 'success';
        },
        [addRunTimeData.rejected]: (state, action) => {
            state.message = action.payload;
            state.isLoading = false;
            state.statusText = 'failed';
            state.severity = 'error';
        },

        [deleteRunTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteRunTimeData.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.statusText = 'success';
            state.severity = 'success';
        },
        [deleteRunTimeData.rejected]: (state, action) => {
            state.message = action.payload;
            state.isLoading = false;
            state.statusText = 'failed';
            state.severity = 'error';
        }
    }
});

export default RuntimeDetailSlice.reducer;