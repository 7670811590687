import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoading: false,
    userDetails: {},
    message: '',
}

export const getUserData = createAsyncThunk('getUserData', async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}user/plant-details`;
    try{
        const resp = await axios.get(url);
        return resp.data
    } catch (err){
        return thunkAPI.rejectWithValue('Failed get Machine list');
    }
});

const UserSlice = createSlice({
    initialState,
    name: 'userState',
    reducers: {

    },
    extraReducers:{
        [getUserData.pending]: (state) => {
            state.isLoading = true;
        },
        [getUserData.fulfilled]: (state, action) => {
            state.message = "success";
            state.userDetails = action.payload;
            state.isLoading = false;
        },
        [getUserData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        }
    }
});

export default UserSlice.reducer;