export const MANAGE_PERMISSIONS_TABLE_COLUMNS = [
  {
    id: "user_email_id",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.email_id",
      width: 400,
  },
  {
    id: "user_employee_id",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.employee_id",
      width: 200,
        
  },
  {
    id: "plant_name",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.plant_name",
      width: 200,
  },
  {
    id: "department",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.department",
      width: 350,
  },
  {
    id: "role",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.role",
      width: 300,
  },
  {
    id: "action",
    translationKey:
      "admin_page.manage_permissions.manage_permissions_table.action",
      width: 250,
  },
];

export const ROW_PER_PAGE_INITIAL_COUNT = 50;
export const ROWS_PER_PAGE_OPTIONS = [10,20,50, 100, 200];

export const roles = {
  Admin: 1,
  "Data Entry": 2,
  "Data Processing": 3,
};

export const getRoleId = (roleName) => {
  return roles[roleName];
};
