import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import { store } from "./stores";
import { theme } from "./themes/theme";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import berryConfig from "./appConfig/config.json";
import berryProdConfig from "./appConfig/prodConfig.json";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import CurrentEntries from "./components/Reports/CurrentEntries";
import Downtime from "./components/Reports/Downtime";
import LandingPage from "./components/Landing/LandingPage";
import { authProvider } from "./authProvider";
import PlantRequest from "./components/Landing/PlantRequest";
import AzureAD from "react-aad-msal";


localStorage.setItem(
  "berryConfig",
  JSON.stringify(
    process.env.NODE_ENV === "production" ? berryProdConfig : berryConfig
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));

export function PrivateRoute() {
  const navigate = useNavigate();

  /*

  This is true because we're using azure AD login
  internal autheticate logic is done by azure AD only

  every user is added into client AD, hence only valid user can login
  hence we don't have to implement external implementation for authentication
  */
  const isAuthenticated = true;
  // const isAuthenticated = Boolean(localStorage.getItem("name")); // Add your authentication check logic here
  return isAuthenticated ? <Outlet /> : navigate("/");
}

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Suspense fallback={<h1>Loading...</h1>}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <App authProvider={authProvider} /> */}
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/plant-request" element={<PlantRequest />} />
              <Route path="/home" element={<PrivateRoute />}>
                <Route
                  path="/home"
                  element={
                    <AzureAD provider={authProvider} forceLogin={true}>
                      <App />
                    </AzureAD>
                  }
                />
                <Route
                  path="/home/reports/current-entries"
                  element={
                    <AzureAD provider={authProvider} forceLogin={true}>
                      <CurrentEntries />
                    </AzureAD>
                  }
                />
                <Route
                  path="/home/reports/downtime"
                  element={
                    <AzureAD provider={authProvider} forceLogin={true}>
                      <Downtime />
                    </AzureAD>
                  }
                />

                {/* <Route
                  path="/home/reports/current-entries"
                  element={<CurrentEntries />}
                />
                <Route path="/home/reports/downtime" element={<Downtime />} /> */}
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Suspense>
    </Provider>
  </I18nextProvider>
);
