import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import NewPlantRequest from "./NewPlantRequest";
import ManagePermissions from "./ManagePermissions";
import ShiftConfigurations from "./ShiftConfigurations";
// import NewRequest from "./NewPlantRequest";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const AdminPage = ({ t }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        mt: "3rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: "0.938rem",
      }}
    >
      <Typography fontWeight="600" fontSize="18px" color="text.primary">
        {t("admin_page.heading")}
      </Typography>
      <Box sx={{ mt: 1.5 }}>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("admin_page.access_request_tab")} index={1} />
          <Tab label={t("admin_page.permissions")} index={2} />
          <Tab label={t("admin_page.shift_configuration")} index={3} />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Box sx={{ mt: 1 }}>
            <NewPlantRequest />
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Box sx={{ p: 1 }}>
            {/* <Typography>Tab 2</Typography> */}
            <ManagePermissions />
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Box sx={{ p: 1 }}>
            <ShiftConfigurations />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default withTranslation()(AdminPage);
