import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  getDepartmentsList,
  getPlantsList,
  getTimezones,
} from "../../reducers/AddNewPlantDepartmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { onlyCapitalAlphanumeric } from "../../constants/GlobalConstants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const NewPlantModal = ({
  open,
  title,
  handleClose,
  onCancelClick,
  onCreateClick,
  isLoading,
  t,
}) => {
  const theme = useTheme();

  const buttonStyles = {
    textTransform: "uppercase",
    fontWeight: "500",
    height: 32,
  };

  //  plant radio type new plant
  const [plantValue, setPlantValue] = useState("new_plant");
  // Timezone state
  const [newPlantValues, setPlantFormValues] = useState({
    plantName: "",
    plantCity: "",
    plantCountry: "",
  });
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  // Existing plant name state
  const [existingPlantName, setExistingPlantName] = useState("");

  //  department radio type new deparment
  const [departmentValue, setDepartmentValue] = useState("new_department");
  const [newDepartmentFormValues, setNewDepartmentFormValues] = useState({
    departmentName: "",
    departmentFullName: "",
    departmentType: "",
  });

  const [existingDepartmentName, setExistingDepartmentName] = useState("");

  const dispatch = useDispatch();

  const addNewPlantDepartmentState = useSelector(
    (state) => state.addNewPlantDepartmentState
  );

  useEffect(() => {
    if(open){
      dispatch(getTimezones());
      dispatch(getPlantsList());
      dispatch(getDepartmentsList());
    }
    
  }, [open]);

  const handlePlantValueChange = (event) => {
    setPlantValue(event.target.value);
  };

  const handleNewPlantInputChange = (event) => {
    let value = event.target.value;

    if (event.target.name === 'plantName') {
      value = value.toUpperCase();
    }

    setPlantFormValues({
      ...newPlantValues,
      [event.target.name]: value.trimStart(),
    });
  };

  const handleDepartmentValueChange = (event) => {
    setDepartmentValue(event.target.value);
  };

  const handleNewDepartmentInputChange = (event) => {

    let value = event.target.value;

    if (event.target.name === 'departmentName' || event.target.name === 'departmentType') {
      value = value.toUpperCase();
    }

    setNewDepartmentFormValues({
      ...newDepartmentFormValues,
      [event.target.name]: value.trimStart(),
    });
  };

  const resetValues = () => {
    setPlantValue("new_plant");
    setPlantFormValues({
      plantName: "",
      plantCity: "",
      plantCountry: "",
    });
    setSelectedTimeZone("");
    setExistingPlantName("");
    setDepartmentValue("new_department");
    setNewDepartmentFormValues({
      departmentName: "",
      departmentFullName: "",
      departmentType: "",
    });
    setExistingDepartmentName("");
  };

  const checkDisabledOfPlantLevel = () => {
    switch (plantValue) {
      case "new_plant":
        return (
          Object.values(newPlantValues).every(Boolean) &&
          selectedTimeZone !== ""
        );
      case "existing_plant":
        return existingPlantName !== "";
      default:
        return false;
    }
  };

  const checkDisabledOfDepartmentLevel = () => {
    switch (departmentValue) {
      case "new_department":
        return Object.values(newDepartmentFormValues).every(Boolean);
      case "existing_department":
        return existingDepartmentName !== "";
      default:
        return false;
    }
  };

  const generatePayload = () => {
    const isExistingPlantSelected = plantValue === "existing_plant";
    const selectPlantDetails = addNewPlantDepartmentState.plantsList.find(
      (ele) => ele.plantId === existingPlantName
    );

    const isExistingDepartmentSelected =
      departmentValue === "existing_department";
    const selectDepartmentDetails =
      addNewPlantDepartmentState.departmentsList.find(
        (ele) => ele.departmentId === existingDepartmentName
      );

    const data = {
      // department values
      isExistingDepartment: isExistingDepartmentSelected,
      departmentId: isExistingDepartmentSelected
        ? selectDepartmentDetails.departmentId
        : 0,
      departmentName: isExistingDepartmentSelected
        ? selectDepartmentDetails.department
        : newDepartmentFormValues.departmentName,
      departmentType: isExistingDepartmentSelected
        ? selectDepartmentDetails.departmentType
        : newDepartmentFormValues.departmentType,
      departmentFullName: isExistingDepartmentSelected
        ? selectDepartmentDetails.departmentName
        : newDepartmentFormValues.departmentFullName,

      // plant values
      isExistingPlant: isExistingPlantSelected,
      plantId: isExistingPlantSelected ? selectPlantDetails.plantId : 0,
      plantName: isExistingPlantSelected
        ? selectPlantDetails.plantName
        : newPlantValues.plantName,
      plantCity: isExistingPlantSelected ? "" : newPlantValues.plantCity,
      plantCountry: isExistingPlantSelected ? "" : newPlantValues.plantCountry,
      timezone: isExistingPlantSelected ? "" : selectedTimeZone,
    };

    return data;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetValues();
      }}
      sx={{
        pb: 0,
        "& .MuiDialog-paper": { minWidth: "800px" },
      }}
    >
      <DialogTitle
        variant="h5"
        fontWeight="500"
        lineHeight="2rem"
        fontSize="1.25rem"
        color={theme.palette.common.black}
        sx={{ pb: 0 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Box>
          <RadioGroup
            row
            aria-label="plant type"
            defaultValue="new_plant"
            value={plantValue}
            onChange={handlePlantValueChange}
            name="plant-radio-buttons-group"
          >
            <FormControlLabel
              value="new_plant"
              control={<Radio />}
              label={t(
                "shiftConfiguration.add_new_modal.radio_button_labels.new_plant"
              )}
            />
            <FormControlLabel
              value="existing_plant"
              control={<Radio />}
              label={t(
                "shiftConfiguration.add_new_modal.radio_button_labels.existing_plant"
              )}
            />
          </RadioGroup>
          {plantValue === "new_plant" && (
            <>
              <Box display="flex" gap="1rem">
                <TextField
                  margin="dense"
                  name="plantName"
                  label={t(
                    "shiftConfiguration.add_new_modal.new_plant_textfield_labels.plant_name"
                  )}
                  type="text"
                  size="small"
                  required
                  value={newPlantValues.plantName}
                  onChange={(event) => {
                    const { value } = event.target;
                    const uppercaseValue = value.toUpperCase();
                    if (uppercaseValue === '' || onlyCapitalAlphanumeric.test(uppercaseValue)) {
                      handleNewPlantInputChange(event);
                    }
                  }}
                  sx={{ width: "300px" }}
                  inputProps={{ maxLength: 12 }}
                />
                <TextField
                  margin="dense"
                  name="plantCity"
                  label={t(
                    "shiftConfiguration.add_new_modal.new_plant_textfield_labels.plant_city"
                  )}
                  type="text"
                  size="small"
                  required
                  value={newPlantValues.plantCity}
                  onChange={handleNewPlantInputChange}
                  sx={{ width: "300px" }}
                />
                <TextField
                  margin="dense"
                  name="plantCountry"
                  label={t(
                    "shiftConfiguration.add_new_modal.new_plant_textfield_labels.plant_country"
                  )}
                  type="text"
                  size="small"
                  required
                  value={newPlantValues.plantCountry}
                  onChange={handleNewPlantInputChange}
                  sx={{ width: "300px" }}
                />
              </Box>
              <Box>
                <FormControl sx={{ mt: 1, width: 240 }} size="small" required>
                  <InputLabel id="demo-select-small-label">
                    {t(
                      "shiftConfiguration.add_new_modal.new_plant_textfield_labels.timezone"
                    )}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    size="small"
                    // inputProps={{ fontSize: "0.875rem" }}
                    name="timezone"
                    value={selectedTimeZone}
                    onChange={(event) => {
                      const { value } = event.target;
                      setSelectedTimeZone(value);
                    }}
                    label={t(
                      "shiftConfiguration.add_new_modal.new_plant_textfield_labels.timezone"
                    )}
                    // error={formik.touched.role && Boolean(formik.errors.role)}
                    MenuProps={MenuProps}
                  >
                    {addNewPlantDepartmentState.timezonesList.map((option) => {
                      return (
                        <MenuItem
                          key={option.timezoneId}
                          value={option.timezoneId}
                        >
                          {option.timezoneFullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          {plantValue === "existing_plant" && (
            <FormControl sx={{ mt: 1, width: 235 }} size="small" required>
              <InputLabel id="existing-plant-name-small-label">
                {t(
                  "shiftConfiguration.add_new_modal.existing_plant_textfield_labels.plant_name"
                )}
              </InputLabel>
              <Select
                labelId="existing-plant-name-small-label"
                id="existing-plant-name-small"
                size="small"
                name="existingPlantName"
                value={existingPlantName}
                onChange={(event) => {
                  const { value } = event.target;
                  setExistingPlantName(value);
                }}
                label={t(
                  "shiftConfiguration.add_new_modal.existing_plant_textfield_labels.plant_name"
                )}
                MenuProps={MenuProps}
              >
                {addNewPlantDepartmentState.plantsList.map((option) => {
                  return (
                    <MenuItem key={option.plantId} value={option.plantId}>
                      {option.plantName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>

        <Box>
          <RadioGroup
            row
            aria-label="department type"
            defaultValue="new_department"
            value={departmentValue}
            onChange={handleDepartmentValueChange}
            name="department-radio-buttons-group"
          >
            <FormControlLabel
              value="new_department"
              control={<Radio />}
              label={t(
                "shiftConfiguration.add_new_modal.radio_button_labels.new_department"
              )}
            />
            <FormControlLabel
              value="existing_department"
              control={<Radio />}
              label={t(
                "shiftConfiguration.add_new_modal.radio_button_labels.existing_department"
              )}
            />
          </RadioGroup>
          {departmentValue === "new_department" && (
            <Box display="flex" gap="1rem">
              <TextField
                margin="dense"
                name="departmentName"
                label={t(
                  "shiftConfiguration.add_new_modal.new_department_textfield_labels.department_name"
                )+ "*  \u00A0"}
                type="text"
                size="small"
                // required
                value={newDepartmentFormValues.departmentName}
                onChange={(event) => {
                  const { value } = event.target;
                  const uppercaseValue = value.toUpperCase();
                  if (uppercaseValue === '' || onlyCapitalAlphanumeric.test(uppercaseValue)) {
                    handleNewDepartmentInputChange(event);
                  }
                }}
                sx={{ width: "300px" }}
                inputProps={{ maxLength: 12 }}
              />
              <TextField
                margin="dense"
                name="departmentFullName"
                label={t(
                  "shiftConfiguration.add_new_modal.new_department_textfield_labels.department_full_name"
                )+ "*  \u00A0"}
                type="text"
                size="small"
                // required
                value={newDepartmentFormValues.departmentFullName}
                onChange={handleNewDepartmentInputChange}
                sx={{ width: "300px" }}
                inputProps={{ maxLength: 30 }}
              />
              <TextField
                margin="dense"
                name="departmentType"
                label={t(
                  "shiftConfiguration.add_new_modal.new_department_textfield_labels.department_type"
                ) + "*  \u00A0"}
                type="text"
                size="small"
                value={newDepartmentFormValues.departmentType}
                onChange={handleNewDepartmentInputChange}
                sx={{ width: "300px" }}
                inputProps={{ maxLength: 12 }}
              />
            </Box>
          )}
          {departmentValue === "existing_department" && (
            <FormControl sx={{ mt: 1, width: 235 }} size="small" required>
              <InputLabel id="existing-department-name-small-label">
                {t(
                  "shiftConfiguration.add_new_modal.existing_department_textfield_labels.department_name"
                )}
              </InputLabel>
              <Select
                labelId="existing-department-name-small-label"
                id="existing-department-name-small"
                size="small"
                name="existingDepartmentName"
                value={existingDepartmentName}
                onChange={(event) => {
                  const { value } = event.target;
                  setExistingDepartmentName(value);
                }}
                label={t(
                  "shiftConfiguration.add_new_modal.existing_department_textfield_labels.department_name"
                ) + "*  \u00A0"}
                MenuProps={MenuProps}
              >
                {addNewPlantDepartmentState.departmentsList.map((option) => {
                  return (
                    <MenuItem
                      key={option.departmentId}
                      value={option.departmentId}
                    >
                      {option.department}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onCancelClick();
            resetValues();
          }}
          sx={buttonStyles}
        >
          {t("shiftConfiguration.add_new_modal.cancel_btn")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const payload = generatePayload();
            onCreateClick(payload, resetValues);
          }}
          sx={buttonStyles}
          disabled={
            !(checkDisabledOfPlantLevel() && checkDisabledOfDepartmentLevel())
          }
        >
          {isLoading ? (
            <CircularProgress size={10} color="secondary" />
          ) : (
            t("shiftConfiguration.add_new_modal.create_btn")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(NewPlantModal);
