import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: [],
  plants: [],
  message: "",
};

export const getPlantsData = createAsyncThunk(
  "getPlantsList",
  async (params, thunkAPI) => {
    let url = `${JSON.parse(localStorage.berryConfig).URL}plant-details`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get Plants list");
    }
  }
);

export const getCurrentEntriesData = createAsyncThunk(
  "getCurrentEntriesList",
  async (params, thunkAPI) => {
    const { date, plantId } = params;
    let url = `${
      JSON.parse(localStorage.berryConfig).URL
    }currententries-report/${date}/${plantId}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get Current Entries list");
    }
  }
);

export const CurrentEntriesSlice = createSlice({
  initialState,
  name: "currentEntriesState",
  reducers: {},
  extraReducers: {
    [getPlantsData.pending]: (state) => {
      state.isLoading = true;
    },
    [getPlantsData.fulfilled]: (state, action) => {
      state.message = "success";
      state.plants = action.payload;
      state.isLoading = false;
    },
    [getPlantsData.rejected]: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
    [getCurrentEntriesData.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentEntriesData.fulfilled]: (state, action) => {
      state.message = "success";
      const sequence = action.payload.map(
        ({ inputDate, shift, machineCode, runtime }) => ({
          inputDate,
          shift,
          machineCode,
          runtime,
        })
      );
      state.items = sequence;
      state.isLoading = false;
    },
    [getCurrentEntriesData.rejected]: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
  },
});

export default CurrentEntriesSlice.reducer;
