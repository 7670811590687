import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  NEW_REQUEST_TABLE_COLUMNS,
  ROWS_PER_PAGE_OPTIONS,
  ROW_PER_PAGE_INITIAL_COUNT,
  sampleData,
} from "../../constants/NewPlantRequest";
import { useDispatch, useSelector } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RejectRequestModal from "../Modal/RejectRequestModal";
import {
  getAccessRequests,
  updateAccessRequest,
} from "../../reducers/AccessRequestSlice";
import Notification from "../Notification/Notification";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

function MultipleSelect({ selectedOptions, allOptions, handleOptions }) {
  // const theme = useTheme();
  const [options, setOptions] = React.useState(
    selectedOptions.map((dept) => dept.departmentId)
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // If value is an empty array, return without updating options
    if (Array.isArray(value) && value.length === 0) {
      return;
    }
    setOptions(value);
    handleOptions(value);
  };

  return (
    <div>
      <FormControl sx={{ width: 200 }}>
        <Select
          multiple
          value={options}
          onChange={handleChange}
          renderValue={(selected) =>
            selected
              .map(
                (departmentId) =>
                  allOptions.find((e) => e.departmentId === departmentId)
                    .department
              )
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {allOptions.map((option) => (
            <MenuItem key={option.departmentId} value={option.departmentId}>
              <Checkbox
                checked={options.indexOf(option.departmentId) > -1}
                size="small"
                sx={{ p: 0.2 }}
              />
              <ListItemText primary={option.department} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const NewRequest = ({ t }) => {
  const theme = useTheme();

  const tableStyles = {
    "@media (width <= 1140px)": {
      height: "53vh",
    },
    "@media (min-width: 1400px)": {
      height: "65vh",
    },
  };

  const fieldWithoutBorder = {
    ".MuiInputBase-root": {
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: "13px",
    },
  };

  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 0.5,
  };

  const tableCellStyles = {
    p: 0,
  };

  const dispatch = useDispatch();

  const [searchRequest, setSearchRequest] = React.useState("");
  const [data, setData] = useState([]);
  const [rejectIdData, setRejectIdData] = useState({});
  const [approveIdData, setApproveIdData] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_INITIAL_COUNT);

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);

  const [showResponseMessage, setShowResponseMessage] = useState(false);

  const { isLoading: requestsLoading, items } = useSelector(
    (state) => state.accessRequestState
  );

  const accessRequestState = useSelector((state) => state.accessRequestState);

  useEffect(() => {
    dispatch(getAccessRequests())
  }, []);
  useEffect(()=>{
    if (searchRequest) {
      const searchingData = [...items];
      const result = searchData(searchingData, searchRequest);
      setData(result);
    } else {
      setData(items);
    }
  },[items])
  const visibleData = useMemo(
    () =>
      Array.isArray(data)
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [page, rowsPerPage, data]
  );

  function searchData(data, searchTerm) {

    return data.filter((item) => {
      return (
        item.userFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.userLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.userEmailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.plantName.toLowerCase().includes(searchTerm.toLowerCase())||
        item.employeeId.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  const handleSearchRequestChange = (e) => {
    setSearchRequest(e.target.value);
    setPage(0);
    const searchingData = [...items];
    if (e.target.value === "") setData(items); // changed implementation
    const result = searchData(searchingData, e.target.value);
    setData(result);
  };

  //Pagination Code
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleApprove = (requestId, updatedDepartments, currentRow) => {
    // Find by unqiueId and Call the Approve APi

    setOpenApproveModal(true);

    const params = {
      requestId: currentRow.requestId,
      plantId: currentRow.plantId,
      deptId: updatedDepartments.join(","),
      userEmailId: currentRow.userEmailId,
      role: currentRow.role,
      status: "Approve",
      employeeId: currentRow.employeeId,
    };

    setApproveIdData(params);
  };

  const handleReject = (newOptions, row) => {
    // Find by unqiueId and Call the Approve APi
    setOpenRejectModal(true);
    setRejectIdData({
      updatedOptions: newOptions,
      currentRow: row,
    });
  };

  const handleYesClickModal = () => {
    const params = {
      requestId: rejectIdData.currentRow.requestId,
      status: "Reject",
      plantId: rejectIdData.currentRow.plantId,
      deptId: rejectIdData.updatedOptions.join(","),
      userEmailId: rejectIdData.currentRow.userEmailId,
      role: rejectIdData.currentRow.role,
      employeeId: rejectIdData.currentRow.employeeId,
    };

    // reject api call implementation
    dispatch(updateAccessRequest(params))
      .then((res) => {
        setOpenRejectModal(false);
        setShowResponseMessage(true);
        dispatch(getAccessRequests());
      })
      
  };

  const handleYesClickApproveModal = () => {
    // approve api call implementation
    dispatch(updateAccessRequest(approveIdData))
      .then((res) => {
        setOpenApproveModal(false);
        setShowResponseMessage(true);
     dispatch(getAccessRequests());
      })
     
  };

  const handleNoClickModal = () => {
    setOpenRejectModal(false);
  };

  const handleNoClickApproveModal = () => {
    setOpenApproveModal(false);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <TextField
          name="search_requests"
          value={searchRequest}
          onChange={handleSearchRequestChange}
          label={t("admin_page.search_request_label")}
          size="small"
          sx={{
            height: "32px",
            fontSize: "13px",
            width: 300,
            ...fieldWithoutBorder,
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer sx={{ ...tableStyles }}>
          <Table
            stickyHeader
            sx={{ border: "1px solid #a6a7a985" }}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {NEW_REQUEST_TABLE_COLUMNS.map(
                  ({ id, translationKey, width }, index) => (
                    <TableCell
                      variant="head"
                      key={id}
                      sx={{
                        ...tableHeaderStyles,
                        pl: index === 0 ? "5px" : 0,
                      }}
                      // width={width}
                    >
                      {t(translationKey)}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestsLoading ? (
                <TableRow>
                  <TableCell colSpan="9" align="center">
                    <CircularProgress size={20} color="primary" />
                  </TableCell>
                </TableRow>
              ) : visibleData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan="9">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        letterSpacing: "0.25px",
                        lineHeight: "32px",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {t("admin_page.empty_list_text")}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                visibleData.map((row) => {
                  const {
                    requestId,
                    userFirstName,
                    userLastName,
                    userEmailId,
                    supervisorEmailId,
                    plantName,
                    departmentList,
                    allDepartmentList,
                    roleName,
                    additionalInformation,
                    employeeId,
                  } = row;

                  let newOptions = [
                    ...departmentList.map((dept) => dept.departmentId),
                  ];

                  const handleOptions = (updatedOptions) => {
                    newOptions = updatedOptions;
                  };

                  return (
                    <TableRow key={requestId} hover>
                      <TableCell sx={{ ...tableCellStyles, pl: "5px" }}>
                        {userFirstName}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>{userLastName}</TableCell>
                      <TableCell sx={tableCellStyles}>{userEmailId}</TableCell>
                      <TableCell sx={tableCellStyles}>{employeeId}</TableCell>
                      <TableCell sx={tableCellStyles}>
                        {supervisorEmailId}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {plantName}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <MultipleSelect
                          selectedOptions={departmentList}
                          allOptions={allDepartmentList}
                          handleOptions={handleOptions}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <Tooltip
                          title={additionalInformation}
                          arrow
                        >
                          <span>{roleName}</span>
                        </Tooltip>
                      </TableCell>
                      {/* Removed additional information column */}
                      {/* <TableCell
                        // sx={{
                        //   whiteSpace: "nowrap",
                        //   overflow: "hidden",
                        //   textOverflow: "ellipsis",
                        //   maxWidth: "inherit",
                        // }}
                        sx={tableCellStyles}
                        // sx={{...tableCellStyles, pl: '4px'}}
                      >
                        <Tooltip
                          title={additionalInformation}
                          sx={{
                            tooltip: {
                              backgroundColor: "red",
                            },
                          }}
                          arrow
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "500",
                            }}
                          >
                            {additionalInformation.length > 30
                              ? `${additionalInformation.slice(0, 30)}...`
                              : additionalInformation}
                          </span>
                        </Tooltip>
                      </TableCell> */}
                      <TableCell
                        sx={{
                          ...tableCellStyles,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={t("admin_page.access_request_table.approve")}
                          color="success"
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleApprove(requestId, newOptions, row)
                          } //API of approve
                        />
                        <Chip
                          label={t("admin_page.access_request_table.reject")}
                          sx={{
                            backgroundColor: theme.palette.custom.lightOrange,
                            color: theme.palette.common.white,
                            ml: 1,
                            cursor: "pointer",
                            "&&:hover": {
                              backgroundColor: theme.palette.custom.lightOrange,
                            },
                            "&&:focus": {
                              backgroundColor: theme.palette.custom.lightOrange,
                            },
                          }}
                          onClick={() => handleReject(newOptions, row)} // API of reject
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rows_per_page")}
          labelDisplayedRows={({ from, to, count }) =>
            `${t("pagination.from_to_count", { from, to, count })}`
          }
        />
      </Box>

      {/* Modal For approve */}
      <RejectRequestModal
        open={openApproveModal}
        title={t("admin_page.approve_request_modal.title")}
        descriptionText={t("admin_page.approve_request_modal.description")}
        noButtonText={t("admin_page.approve_request_modal.no_button_text")}
        yesButtonText={t("admin_page.approve_request_modal.yes_button_text")}
        onNoClick={handleNoClickApproveModal}
        onYesClick={handleYesClickApproveModal}
        requestsLoading={requestsLoading}
      />

      {/* Modal For reject */}
      <RejectRequestModal
        open={openRejectModal}
        title={t("admin_page.reject_request_modal.title")}
        descriptionText={t("admin_page.reject_request_modal.description")}
        noButtonText={t("admin_page.reject_request_modal.no_button_text")}
        yesButtonText={t("admin_page.reject_request_modal.yes_button_text")}
        onNoClick={handleNoClickModal}
        onYesClick={handleYesClickModal}
        requestsLoading={requestsLoading}
      />

      {/* Snackbar for api success, error response */}
      <Notification
        open={showResponseMessage}
        onClose={() => setShowResponseMessage(false)}
        severity={accessRequestState.severity}
        message={accessRequestState.message}
      />
    </Box>
  );
};

export default withTranslation()(NewRequest);
