import {
  Autocomplete,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  DOWNTIME_DETAILS_COLUMNS,
  ROWS_PER_PAGE_OPTIONS,
  ROW_PER_PAGE_INITIAL_COUNT } from '../../constants/DowntimeDetails';
import { useSelector, useDispatch } from "react-redux";
import { getDownTimeData, getReasonSubReasonData, addDownTimeData, deleteDownTimeData } from "../../reducers/DowntimeDetailSlice";
import { ADD_NEW_ROW } from "../../constants/HomeScreen";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { withTranslation } from "react-i18next";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import EmptyList from "./EmptyList";
import Notification from "../Notification/Notification";
import ConfirmationModal from "../Modal/ConfirmationModal";
import WarningModal from "../Modal/WarningModal";
import { DATA_ENTRY, DATA_PROCESSING, QUATERLY_OPTIONS, REMARKS_MAXLENGTH, TEXTFIELD_MAXLENGTH } from "../../constants/GlobalConstants";
import { validateTextfield } from "../../functions/validator";
import {ContentCopy} from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import useDisableAsPerDepartmentType from "../../hooks/useDisableAsPerDepartmentType";


const DowntimeDetails = ({
  machineSelectionValues,
  selectedMachineCode,
  isProceedClicked,
  isDataChanged,
  setDataChangeStatus,
  showWarningModal,
  setShowWarningModal,
  handleTabChange,
  handleMachineSelectionInputChange,
  selectedMachineId,
  t,
}) => {
  const downtimeState = useSelector((state) => state.downtimeState);
  const userStateShifts = useSelector((state) => state.userState.userDetails.shift)
  const [rows, setRows] = useState([]);
  const [filterdData, setFilterdData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_INITIAL_COUNT);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  // const [showAddRow, setShowAddRow] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showDowntimeErrorSnackBar, setshowDowntimeErrorSnackBar] = useState(false);
  const [showMachineEmptyErrorSnackBar, setShowMachineEmptyErrorSnackBar] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);

  const [showShiftHoursError, setShowShiftHoursError] = useState(false)

  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [autoCompleteValue, setAutoCompleteValue] = useState({label: ""});

  const [groupData, setGroupData] = useState([])
  const [workorderMap, setWorkorderMap] = useState({})

  const {items} = useSelector((state)=> state.departments)

  const currentUser = useSelector((state) => state.currentUserRole.userRole.role);

  const autoCompleteStyles = {
    '.MuiInputBase-root':{
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: '13px'
    }
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  /**
   * Generate option for autocomplete from each machine list row
   */
  const machineListOptions = [
    ...new Set(downtimeState.items.map((obj) => obj.machineCode)),
  ].map((col) => ({
    label: col,
  }));

  //get Reason List
  const reasonListOptions = downtimeState.reasons.map(({reasonId, reasonDesc}) => ({
    label: reasonDesc, id: reasonId
  }));

  const getMachineId = (machine) => {
    const [{ machineId }] = downtimeState.items.filter(
      ({ machineCode }) => machineCode === machine
    );
    return machineId;
  };

  useEffect(() => {
    setRows(
      downtimeState.items.map((obj) => {
        return { ...obj, isUpdated: false, isCopiedEntry: false, isDowntimeDisabled: false, id: uuid() };
      })
    );
    dispatch(getReasonSubReasonData(machineSelectionValues.departmentId));
  }, [downtimeState.items]);

  // const handleAddNewRow = () => {
  //   setRows(
  //     (rows) => [
  //       {
  //         isDowntimeDisabled: false,
  //         isUpdated: true,
  //         reason: "",
  //         subReason: "",
  //         machineCode: "",
  //         machineId: 0,
  //         machineworkDetailId: null,
  //         downtimeId: ADD_NEW_ROW,
  //         downtime: 0,
  //         workorder: "",
  //         remark: "",
  //       },
  //       ...rows,
  //     ],
  //     setShowAddRow(true)
  //   );
  //   if (!isDataChanged) {
  //     setDataChangeStatus(true);
  //   }
  // };

  const getUpdatedList = () => {
    return groupData.filter((obj) => obj.isUpdated === true);
  };

  const handleInputChange = (e, id, val=null, fieldName=null) => {
    const { shift } = machineSelectionValues;
    const list = JSON.parse(JSON.stringify(groupData));
    const obj = list.filter((ele)=> ele.id === id)
    if (fieldName === "downtime") {
      obj[0]["downtime"] = val ? parseFloat(val) : null;
    }else{
      const { name, value } = e.target;
      // if(name === "workorder"){
      //   if(!validateTextfield(value)){return;}
      // }
      // used in add new functionality
      if (name === "machineCode" && value.length > 0) {
        obj[0]["machineId"] = getMachineId(value);
        // setShowAddRow(false);
      }
      if(name === "reason"){
        obj[0]["subReason"] = '';
      }
      if(name=== "reason" & value === "Not Scheduled"){
        // const shiftHrs = items.shift.filter((userShift)=>userShift.shift === shift)
        //           .map((userShift)=> userShift['shiftHrs'])[0]
        // obj[0]['downtime'] = shiftHrs;
        // obj[0]['isDowntimeDisabled'] = true;
      } else if(name=== "reason" & value !== "Not Scheduled") {
        obj[0]['isDowntimeDisabled'] = false;
      }
      obj[0][name] = value;
    }

    if (
      obj[0]["downtimeId"] !== ADD_NEW_ROW &&
      !obj[0]["isUpdated"]
    ) {
      obj[0]["isUpdated"] = true;
    }

    const updatedList = list.filter((ele)=> ele.id !== id)

    const finalList = updatedList.length > 0 ? list.map((ele)=> {
      if(ele?.id === updatedList[0]?.id){
        return {...updatedList[0]}
      }
      return ele
    }) : list

    // setRows(finalList);
    setGroupData(finalList)
    if (!isDataChanged) {
      setDataChangeStatus(true);
    }
  };

  const filterDowntimeList = ({ label }) => {
    if(label){
      setFilterdData(groupData.filter(({ machineCode }) => machineCode === label));
    }else{
      setFilterdData([]);
    }
  };
  const removeNotSavedItem = (i) => {
    setGroupData(() => groupData.filter((obj, index) => obj.id !== i));
  };

  // useMemo(()=>{
  //   const changeAddRowVisibility = rows.filter(({ machineCode }) => machineCode === '');
  //   changeAddRowVisibility.length === 0 && setShowAddRow(false);
  // }, [rows.length]);

  // useEffect(()=>{
  //   if(rows.length === downtimeState.items.length){
  //     setShowAddRow(false);
  //   }
  // }, [rows]);

  const { plantId, date, shift, crew, departmentId, allShiftDetails, deptPlantId } = machineSelectionValues;

  // Get current shift's shiftHours
  let currentShift = allShiftDetails?.find((val) => val.shift===shift);

  /**
   * This will generate Object of machineCodes with sum of runtime and downtime as value
   * @returns Object of machineCodes with total time
   */
  const getTotalTimeOfMachines = () =>{
    const listOfIds ={}
    const totalTime = visibleRows.reduce((acc, curr) => {

      if(acc[curr.machineCode] && listOfIds[curr.machineworkDetailId]){
        acc[curr.machineCode] = acc[curr.machineCode] + curr.downtime || 0
      } else {
        let sum = curr.runtime + curr.downtime || 0
        acc[curr.machineCode] =  acc[curr.machineCode] ? acc[curr.machineCode] + sum : 0 + sum
        listOfIds[curr.machineworkDetailId] = Boolean(curr.machineworkDetailId);
      }
      return acc;
    }, {});
    return totalTime
  }

  /**
   * This function will check if any machine is exceeding the total shift hours
   * @returns Boolean
   */
  const areShiftHoursExceeding = ()=>{
    const keys = getTotalTimeOfMachines();
    const addNewEntries = visibleRows.filter(
      (ele) => ele.downtimeId === ADD_NEW_ROW
    );

    /**
     * This will check only for add new rows entries
     */
    if (addNewEntries.length > 0) {
      let isExceedingNewRows = addNewEntries.some((ele) => {
        const runHrs = ele.downtime;
        const val = keys[ele.machineCode];
        return runHrs + val > currentShift.shiftHrs;
      });

      /**
       * If Add new row are exceeding the shift hours then only return true other wise check
       * for existing entries if any updated
       */
      if (isExceedingNewRows) {
        return isExceedingNewRows;
      }
    }

    /**
     * This will check for existing entries
     */
    const res = Object.entries(keys);
    const val = res.some((ele) => {
      return ele[1] > currentShift.shiftHrs});
    return val;

  }

  const departmentsAreOfSpecificTypes = useDisableAsPerDepartmentType(items)

  const handleSubmit = () => {
    // const checkValidUpdatedList = getUpdatedList().filter(
    //   // (obj) => obj.downtime === null || obj.downtime === ''
    //   // For time being reason and subreason are optional
    //   (obj) => obj.downtime === null || obj.downtime === '' || obj.reason === null || obj.reason === ''
    // );
    const checkValidUpdatedList = getUpdatedList().filter((obj) => {
      const excludeDowntime = departmentsAreOfSpecificTypes
        ? obj.reason === null || obj.reason === ""
        : obj.downtime === null ||
          obj.downtime === "" ||
          obj.reason === null ||
          obj.reason === "";
      return excludeDowntime;
    });
    const checkMachineList = getUpdatedList().filter(
      (obj) => obj.machineCode === null || obj.machineCode === ''
    );
    if(checkMachineList.length > 0){
      setShowMachineEmptyErrorSnackBar(true);
    }else if (checkValidUpdatedList.length > 0) {
      //trigger downtime empty snack bar
      setshowDowntimeErrorSnackBar(true);
    } else if(areShiftHoursExceeding()){
      setShowShiftHoursError(true)
    }else {
      setSaveLoading(true)
      dispatch(
        addDownTimeData({
          plantId,
          shift,
          crew,
          date,
          deptPlantId,
          downtimeInfo: getUpdatedList(),
        })
      ).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(
            getDownTimeData({
              date,
              shift,
              machinecode: selectedMachineCode ? selectedMachineCode : null,
              crew,
              deptPlantId: selectedMachineCode ? null : deptPlantId,
              machineId: selectedMachineId ? selectedMachineId : null
            })
          );
          setDataChangeStatus(false);
        }
        setShowResponseMessage(true);
        setSaveLoading(false);
        setAutoCompleteValue({label: ""})
      });
    }
  };

  //Handle select checkbox
  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelected = downtimeState.items
        .filter((obj) => obj.downtimeId !== null)
        .filter( // Added this filter for data state of data entry and data processing role
          (obj) =>
            (currentUser === DATA_ENTRY &&
              (obj.dataState === null || obj.dataState === 10)) ||
            (currentUser === DATA_PROCESSING &&
              (obj.dataState === null ||
                obj.dataState === 10 ||
                obj.dataState === 20))
        )
        .map((obj) => obj.downtimeId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleCheckBoxClick = (e, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const rowCount = downtimeState.items
    .filter((obj) => obj.downtimeId !== null)
    .filter( // Added this filter for data state of data entry and data processing role
      (obj) =>
        (currentUser === DATA_ENTRY &&
          (obj.dataState === null || obj.dataState === 10)) ||
        (currentUser === DATA_PROCESSING &&
          (obj.dataState === null ||
            obj.dataState === 10 ||
            obj.dataState === 20))
    )
    .map((obj) => obj.downtimeId).length;

  const handleDelete = () => {
    setOpenDeleteModal(true);
  };


  useEffect(()=>{
    const unique = new Set();
    
    const uniquesMachines = rows.filter((r)=>{
      if(!unique.has(r.machineCode) ){
        unique.add(r.machineCode);
        return true
      }
      return false
    })

    const combinedRowsAndUnique = [];

    for(let i =0; i<uniquesMachines.length; i++){
      let found = false
      for(let j=0;j<rows.length; j++){

        if(uniquesMachines[i].machineCode === rows[j].machineCode && rows[j].downtimeId){
          found = true
          combinedRowsAndUnique.push(rows[j])
        }
      }

      if(!found){
        combinedRowsAndUnique.push(uniquesMachines[i])
      }

    }

    setGroupData(combinedRowsAndUnique)
  }, [rows])

  const downtimeList = filterdData.length ? filterdData : groupData;


  useEffect(()=>{

    if(autoCompleteValue && filterdData.length > 0){
      const arr = []

      filterdData.forEach((fd)=>{
        groupData.forEach((r)=>{
          if(fd.id === r.id){
            arr.push(r)
          }
        })
      })
      setFilterdData(arr)
    }
  }, [rows, groupData, autoCompleteValue])

  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 0.2,
  };
  const tableCellStyles = {
    p: 0,
  };
  const fieldWithoutBorder = {
    border: "none",
    "& fieldset": {
      borderColor: "transparent",
    },
    ".MuiInputBase-input": {
      p: 0,
      pl: '6px',
      fontSize: '0.875rem',
    },
  };
  const cssForReasonSubreason = {
    // border: "none",
    // "& fieldset": {
    //   borderColor: "transparent",
    // },
    // width: '10rem',
  };

  //Pagination Code
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, ROW_PER_PAGE_INITIAL_COUNT));
    setPage(0);
  };
  const visibleRows = useMemo(
    () =>
      downtimeList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, downtimeList]
  );

  const handleCancel = () => {
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const onCancelModalCancel = () => {
    handleCloseCancelModal();
  };

  const onCancelModalConfirm = () => {
    handleCloseCancelModal();
    setRows(downtimeState.items.map((obj) => ({...obj, isUpdated: false, id: uuid()})));
    setDataChangeStatus(false);
  };

  const handleCloseAlertModal = () => {
    setOpenDeleteModal(false);
  };

  const onAlertModalConfirm = () => {
    if (selected.length > 0) {
      setDeleteLoading(true)
      dispatch(
        deleteDownTimeData({
          plantId,
          shift,
          downtimeIds: selected,
          deptPlantId
        })
      ).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(
            getDownTimeData({
              date,
              shift,
              machinecode: selectedMachineCode ? selectedMachineCode : null,
              crew,
              deptPlantId: selectedMachineCode ? null : deptPlantId,
              machineId: selectedMachineId ? selectedMachineId : null
            })
          );
        }
        setShowResponseMessage(true);
        setDeleteLoading(false);
        setAutoCompleteValue({label:""})
      });
    }
    handleCloseAlertModal();
    setSelected([]);
  };

  const onAlertModalCancel = () => {
    handleCloseAlertModal();
  };

  const onWarningModalConfirm = () => {
    setShowWarningModal(false)
  };

  const onWarningModalCancel = () => {
    setDataChangeStatus(false);
    setShowWarningModal(false)
    setRows(downtimeState.items);
    const pendingEvent = JSON.parse(localStorage.getItem("pendingEvent"));
    if (pendingEvent) {
      switch (pendingEvent.functionType) {
        case "tab":
          handleTabChange(null, pendingEvent.value, pendingEvent.machineCode, true);
          break;
        case "dropDown":
          handleMachineSelectionInputChange(pendingEvent.e, pendingEvent.obj, pendingEvent.name, true);
          break;
        default:
          break;
      }
    }
  };

  const handleDuplicateRow = (index, machineData, id) =>{
    
    const clickedEntry = groupData.find((ele)=> ele.id=== id)
    let entriesTillIdMatched = [];
    let entriesAfterMatched = [];
    let isMatched = false

    for(let i=0; i<groupData.length; i++){
      if(!isMatched && groupData[i].id !==clickedEntry.id){
        entriesTillIdMatched.push(groupData[i])
      }

      if(groupData[i].id ===clickedEntry.id) break

    }

    for(let i=0; i<groupData.length; i++){
      if(isMatched){
        entriesAfterMatched.push(groupData[i])
      }

      if(groupData[i].id ===clickedEntry.id) {
        isMatched = true
      }

    }


    const latestEnt = {
      ...machineData,
      reason: "",
      subReason: "",
      downtime:"",
      runtime: null,
      downtimeId: null,
      machineworkDetailId: null,
      remark:"",
      isDowntimeDisabled:false,
      isUpdated: true,
      isCopiedEntry: true,
      id: uuid()
    }

    const dupEnt = [
      ...entriesTillIdMatched,
      {...latestEnt},
      {...clickedEntry},
      ...entriesAfterMatched
    ]

    setGroupData(dupEnt)

    
    // setRows(dupEnt)
    // setGroupData(dupEnt)

    if (!isDataChanged) {
      setDataChangeStatus(true);
    }

    return

  }

  const tableStyles = { 
    mt: 1,    
    '@media (min-width: 1140px)': {
      height: '53vh',
    },
    '@media (min-width: 1400px)': {
      height: '65vh',
    },
  }

  const resonsValuesStyles = {
    fontSize: "14px"
  }

  /**
   * When we close filter machine it value becomes null hence making it as ""
   * so it won't break UI
   */
  useEffect(()=>{
    if(autoCompleteValue === null){
      setAutoCompleteValue({label:''})
    }
  }, [autoCompleteValue])


  /**
   * Duplicated entries in filtered rows, to make rows and filtered rows in sync
   * both entitis should have same value
   */
  useEffect(()=>{

    if(autoCompleteValue && autoCompleteValue['label']){
      const newFiltered = groupData.filter((ele)=>ele.machineCode === autoCompleteValue['label'] )
      setFilterdData(newFiltered)
    }
  }, [groupData])

  useEffect(()=>{
    if(autoCompleteValue?.label !== ""){
      setFilterdData([])
      setAutoCompleteValue({label: ""})
    }
  }, [isProceedClicked])

  const getMappingOfWorkOrder = useCallback(() =>{
    const mapping = {};
    const uniqueWorkOrders = {}

    rows.forEach(row => {
      if (!mapping[row.machineCode]) {
        mapping[row.machineCode] = [{ id: row.workorder, label: `${row.workorder}` }];
      } else {
        mapping[row.machineCode].push({ id: row.workorder, label: `${row.workorder}` });
      }
    });


    for (const machineCode in mapping) {
      // Use a Set to remove duplicates based on the "id" property
      const uniqueObjects = Array.from(new Set(mapping[machineCode].map(obj => obj.id))).map(id => {
        return mapping[machineCode].find(obj => obj.id === id);
      });
    
      uniqueWorkOrders[machineCode] = uniqueObjects;
    }

    return uniqueWorkOrders
  }, [rows])

  // const workorderMapping = getMappingOfWorkOrder();

  useEffect(()=>{
    const workorderMapping = getMappingOfWorkOrder();
    setWorkorderMap(workorderMapping)
  }, [groupData, getMappingOfWorkOrder])

  return (
    <Box sx={{ mt: 1 }}>
      {!isProceedClicked ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
          }}
        >
          <EmptyList />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", mt: 1, justifyContent: "flex-end" }}>
            <Button
              size="small"
              onClick={handleDelete}
              variant="outlined"
              sx={{ mr: 2, height: 32, minWidth: '35px' }}
              disabled={!selected.length}
            >
              {
                deleteLoading ? <CircularProgress 
                  size={10} 
                  color="primary" 
                  />
                  :
                  <DeleteOutlineIcon
                  onClick={handleDelete}
                  />
              }
              
            </Button>
            {/* ========= ADD ROW BUTTON ============= */}
            {/* <Button
              onClick={handleAddNewRow}
              size="small"
              variant="outlined"
              disabled={showAddRow}
              sx={{ height: 32, textAlign: 'center', lineHeight: '70px' }}
            >
              <AddIcon />
              {t("Button_group.add_row")}
            </Button> */}
            <Autocomplete
              value={autoCompleteValue}
              sx={{ width: 300, ...autoCompleteStyles }}
              options={machineListOptions}
              onChange={(e, newValue) => {
                setAutoCompleteValue(newValue)
                setPage(0)
                filterDowntimeList(
                  newValue === null ? { label: "" } : newValue
                );
              }}
              size="small"
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === '' || option === value
              }
              renderInput={(params) => (
                <TextField {...params} label={t("search_by_machines")} />
              )}
              disabled={selectedMachineCode ? true : false}
              ListboxProps={
                {
                  style:{
                    fontSize: '0.875rem'
                  }
                }
              }
            />
          </Box>
          <TableContainer sx={tableStyles}>
            <Table stickyHeader sx={{ border: `1px solid #a6a7a985` }}>
              <TableHead>
                <TableRow>
                  {DOWNTIME_DETAILS_COLUMNS.map(
                    ({ id, label, translationKey, width }) => {
                      return id === "checkbox" ? (
                        <TableCell
                          variant="head"
                          key={id}
                          sx={tableHeaderStyles}
                          width={width}
                        >
                          <Checkbox
                            indeterminate={
                              selected.length > 0 && selected.length < rowCount
                            }
                            checked={
                              rowCount > 0 && selected.length === rowCount
                            }
                            onChange={handleSelectAllClick}
                            size="small"
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          variant="head"
                          key={id}
                          sx={tableHeaderStyles}
                          width={width}
                        >
                          {t(translationKey)}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map(
                  (
                    {
                      reason,
                      subReason,
                      machineCode,
                      workorder,
                      downtime,
                      downtimeId,
                      remark,
                      isDowntimeDisabled,
                      isCopiedEntry,
                      isUpdated,
                      id,
                      dataState
                    },
                    index
                  ) => {
                    const isItemSelected = isSelected(downtimeId);
                    const isDataEntryAndNullOrStage10 = currentUser === DATA_ENTRY && (dataState === null || dataState === 10);

                    const isDataProcessingAndStage10or20 = currentUser === DATA_PROCESSING && (dataState === null || dataState === 10 || dataState === 20);

                    const disableAsPerRole = currentUser === DATA_ENTRY ? isDataEntryAndNullOrStage10 : isDataProcessingAndStage10or20;

                    return (
                      <TableRow key={id} hover>
                        <TableCell sx={tableCellStyles}>
                          {downtimeId === ADD_NEW_ROW || isCopiedEntry ? (
                            <HighlightOffIcon
                              sx={{
                                color: theme.palette.error.main,
                                ml: 1,
                                cursor: "pointer",
                              }}
                              onClick={(e) => removeNotSavedItem(id)}
                            />
                          ) : (
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(e) =>
                                handleCheckBoxClick(e, downtimeId)
                              }
                              disabled={downtimeId === null || !disableAsPerRole}
                              sx={{ opacity: downtimeId && disableAsPerRole? 1 : 0.3}}
                              size="small"
                            />
                          )}
                        </TableCell>
                        {/* <TableCell sx={tableCellStyles}>{index + 1}</TableCell> */}
                        <TableCell sx={tableCellStyles}>
                          {downtimeId === ADD_NEW_ROW ? (
                            <Select
                              defaultValue=""
                              displayEmpty
                              autoWidth
                              sx={{
                                ...fieldWithoutBorder,
                                ".MuiOutlinedInput-input": {
                                  p: 0,
                                  pl: '6px',
                                },
                              }}
                              name="machineCode"
                              onChange={(e) => handleInputChange(e, id)}
                              value={machineCode}
                            >
                              <MenuItem value="" sx={{ fontSize: '0.875rem' }}>
                                {t(
                                  "machine_list.downtime_list_placeholder.select_machine"
                                )}
                              </MenuItem>
                              {machineListOptions.map(({ label }, index) => (
                                <MenuItem key={id} value={label} sx={{ fontSize: '0.875rem' }}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            machineCode
                          )}
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          {/* {workorder} */}

                          <Select
                            defaultValue=""
                            displayEmpty
                            // autoWidth
                            sx={{
                              width: "70%"
                            }}
                            name="workorder"
                            onChange={(e) => handleInputChange(e, id)}
                            // value={workorder && downtimeId ? workorder : ""}
                            value={workorder ? workorder : ""}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 250 } },
                            }}
                            disabled={!disableAsPerRole}
                          >
                            {/* <MenuItem value="" sx={resonsValuesStyles}>
                              {t(
                                "machine_list.downtime_list_placeholder.select_workorder"
                              )}
                            </MenuItem> */}
                            {workorderMap[machineCode].map(({label}, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={label}
                                  sx={resonsValuesStyles}
                                >
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          {/* ===== Uncomment this code when Workorder is manual entry field ===== */}

                          {/* <TextField
                            name="workorder"
                            placeholder={t(
                              "machine_list.downtime_list_placeholder.Add_Workorder"
                            )}
                            value={workorder ? workorder : ""}
                            onChange={(e) => handleInputChange(e, index)}
                            sx={fieldWithoutBorder}
                            inputProps={{ maxLength: TEXTFIELD_MAXLENGTH }}
                          /> */}
                        </TableCell>
                        <TableCell sx={{...tableCellStyles, pointerEvents: (departmentsAreOfSpecificTypes || !disableAsPerRole) && "none"}}>
                          <Autocomplete
                            options={QUATERLY_OPTIONS}
                            size='small'
                            value={downtime !== null ? String(downtime) : ""}
                            onChange={(e,val) => handleInputChange(e, id, val, 'downtime')}
                            disabled={departmentsAreOfSpecificTypes  || !disableAsPerRole}
                            sx={{
                              // ...fieldWithoutBorder,
                              ".MuiOutlinedInput-input": {
                                p: 0,
                              },
                              // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              //   border: isDowntimeDisabled && 'transparent'
                              // },
                              ".Mui-disabled .MuiOutlinedInput-notchedOutline":
                                departmentsAreOfSpecificTypes && {
                                  borderColor: "transparent",
                                },
                              width: "80%",
                              border: departmentsAreOfSpecificTypes ? 'none' : isUpdated && !downtime && `1px solid ${theme.palette.error.main}`
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option === "" || option === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='downtime'
                                placeholder={t(
                                  "machine_list.downtime_list_placeholder.add_downtime"
                                )}
                              />
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          <Select
                            defaultValue=""
                            displayEmpty
                            // autoWidth
                            sx={{
                              // ...cssForReasonSubreason, 
                              border: isUpdated && !reason && `1px solid ${theme.palette.error.main}`,
                              width: "80%"
                            }}
                            name="reason"
                            onChange={(e) => handleInputChange(e, id)}
                            value={reason ? reason : ""}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 250 } },
                            }}
                            disabled={!disableAsPerRole}
                          >
                            <MenuItem value="" sx={resonsValuesStyles}>
                              {t(
                                "machine_list.downtime_list_placeholder.select_reason"
                              )}
                            </MenuItem>
                            {reasonListOptions.map(({ label }, index) => (
                              <MenuItem
                                key={index}
                                value={label}
                                sx={resonsValuesStyles}
                              >
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          <Select
                            defaultValue=""
                            displayEmpty
                            // autoWidth
                            sx={{
                              // fieldWithoutBorder,
                              width: "80%"
                            }}
                            name="subReason"
                            onChange={(e) => handleInputChange(e, id)}
                            value={subReason ? subReason : ""}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 250 } },
                            }}
                            disabled={!disableAsPerRole}
                          >
                            <MenuItem value="" sx={resonsValuesStyles}>
                              {t(
                                "machine_list.downtime_list_placeholder.select_subreason"
                              )}
                            </MenuItem>
                            {downtimeState.subReasons
                              .filter((obj) => obj.reasonDesc === reason)
                              .map(({ subreason }) =>
                                subreason.map(({ subreasonDesc }, index) => (
                                  <MenuItem
                                    key={index}
                                    value={subreasonDesc}
                                    sx={resonsValuesStyles}
                                  >
                                    {subreasonDesc}
                                  </MenuItem>
                                ))
                              )}
                          </Select>
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          {/* ===== comment this code when remark is not manual entry field ===== */}
                          <TextField
                            name="remark"
                            placeholder={t(
                              "machine_list.downtime_list_placeholder.add_remarks"
                            )}
                            onChange={(e) => handleInputChange(e, id)}
                            value={remark ? remark : ""}
                            sx={{ 
                              // ...fieldWithoutBorder
                             }}
                            inputProps={{ maxLength: REMARKS_MAXLENGTH }}
                            disabled={!disableAsPerRole}
                          />
                          {/* {remark} */}
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          <Button
                            size="small"
                            sx={{ height: 32, minWidth: "35px" }}
                            onClick={() => {
                              handleDuplicateRow(
                                index,
                                { ...visibleRows[index] },
                                id
                              );
                            }}
                            disabled={!downtime || !disableAsPerRole}
                            title="Duplicate record"
                          >
                            <ContentCopy />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={downtimeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("pagination.rows_per_page")}
            labelDisplayedRows={({ from, to, count }) =>
              `${t("pagination.from_to_count", { from, to, count })}`
            }
          />
          <ConfirmationModal
            open={openCancelModal}
            handleClose={handleCloseCancelModal}
            title={t("confirmation.alert")}
            descriptionText={t("confirmation.cancel_confirmation")}
            noButtonText={t("confirmation.no")}
            yesButtonText={t("confirmation.yes")}
            onNoClick={onCancelModalCancel}
            onYesClick={onCancelModalConfirm}
          />

          <ConfirmationModal
            open={openDeleteModal}
            handleClose={handleCloseAlertModal}
            title={t("confirmation.alert")}
            descriptionText={t("confirmation.delete_confirmation")}
            noButtonText={t("confirmation.no")}
            yesButtonText={t("confirmation.yes")}
            onNoClick={onAlertModalCancel}
            onYesClick={onAlertModalConfirm}
          />
          <WarningModal
            open={showWarningModal}
            handleClose={() => setShowWarningModal(false)}
            title={t("confirmation.warning")}
            subTitle={t("confirmation.confirm_navigation")}
            descriptionText={t("confirmation.data_lost_warning")}
            cancelButtonText={t("confirmation.leave_without_saving")}
            confirmButtonText={t("confirmation.stay_on_this_page")}
            onCancelClick={onWarningModalCancel}
            onConfirmClick={onWarningModalConfirm}
          />
          <Notification
            open={showDowntimeErrorSnackBar}
            onClose={() => setshowDowntimeErrorSnackBar(false)}
            severity="error"
            message={t("message.mandatory_fields")}
          />
          <Notification
            open={showMachineEmptyErrorSnackBar}
            onClose={() => setShowMachineEmptyErrorSnackBar(false)}
            severity="error"
            message={t("message.machine_empty")}
          />
          <Notification
            open={showResponseMessage}
            onClose={() => setShowResponseMessage(false)}
            severity={downtimeState.severity}
            message={downtimeState.message}
          />
           {/* Snackbar for exceeding shift hours */}
           <Notification
            open={showShiftHoursError}
            onClose={() => setShowShiftHoursError(false)}
            severity="error"
            message={t("message.shift_hours_exceeds")}
          />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={!isDataChanged}
              sx={{ height: 32 }}
            >
              {t("Button_group.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={(e) => handleSubmit()}
              disabled={!isDataChanged}
              sx={{ height: 32 }}
            >
              {
                saveLoading ? <CircularProgress size={10} color="secondary" /> : t("Button_group.save")
              }
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default withTranslation()(DowntimeDetails);
