import {
  Box,
  Table,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  useTheme,
  TextField,
  Button,
  TableBody,
  MenuItem,
  TablePagination,
  Select,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateShift,
  updateShiftHrs,
  updateShiftHrsStartTime,
  getShiftConfigurationDetails,
  updateScheduleJobTime,
  updateShiftConfigurations,
  deleteShiftConfiguration,
} from "../../reducers/ShiftConfigurationSlice";
import Notification from "../Notification/Notification";
import {
  NEW_REQUEST_TABLE_COLUMNS,
  ROWS_PER_PAGE_OPTIONS,
} from "../../constants/ShiftConfiguration";
import UpdatePermissionModal from "../Modal/UpdatePermissionModal";
import NewPlantModal from "../Modal/NewPlantModal";
import { createNewPlantAndDepartment } from "../../reducers/AddNewPlantDepartmentSlice";

const ShiftConfiguration = ({ t }) => {
  const theme = useTheme();
  const shiftConfigurationState = useSelector(
    (state) => state.shiftConfigurationState
  );
  const tableData = useSelector((state) => state.shiftConfigurationState.items);
  const scheduledJobTimeValues = useSelector(
    (state) => state.shiftConfigurationState.scheduleJobTime
  );
  const shiftHrDataValue = useSelector(
    (state) => state.shiftConfigurationState.shift_hrs_data
  );
  const shiftStartTimeValues = useSelector(
    (state) => state.shiftConfigurationState.shift_startTime_data
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showapiResponseMessage, setshowapiResponseMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(tableData);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowData, setRowData] = useState();
  const { isLoading: requestsLoading, items } = useSelector(
    (state) => state.shiftConfigurationState
  );

  const [openAddNewPlantModal, setOpenAddNewPlantModal] = useState(false);

  const [addNewPlantResponse, setAddNewPlantResponse] = useState(false);

  const addNewPlantDepartmentState = useSelector(
    (state) => state.addNewPlantDepartmentState
  );
  const [shiftHrErrorMsg, setShiftHrErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const tableRef = useRef();
  useEffect(() => {
    if (searchTerm) {
      const searchingData = [...tableData];
      const result = searchData(searchingData, searchTerm);
      setData(result);
    } else {
      setData(tableData);
    }
  }, [tableData]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
    const searchingData = [...tableData];
    if (event.target.value === "") setData(tableData); 
    const result = searchData(searchingData, event.target.value);
    setData(result);
  };
  function searchData(data, searchTerm) {
    return data.filter((item) => {
      return (
        (item.plantName &&
          item.plantName
            .toLowerCase()
            .includes(searchTerm && searchTerm.toLowerCase())) ||
        (item.departmentName &&
          item.departmentName
            .toLowerCase()
            .includes(searchTerm && searchTerm.toLowerCase()))
      );
    });
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShiftConfigurationDetails());
  }, []);

  const tableStyles = {
    "@media (width <= 1140px)": {
      height: "53vh",
    },
    "@media (min-width: 1400px)": {
      height: "65vh",
    },
  };
  const fieldWithoutBorder = {
    ".MuiInputBase-root": {
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: "13px",
    },
  };


  const handleChangePage = (event, newPage) => {
    tableRef.current.scrollIntoView();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleScheduledJobTime(plantMappingIDDept, newScheduledJobTime) {
    dispatch(
      updateScheduleJobTime({ plantMappingIDDept, newScheduledJobTime })
    );
  }

  function handleShiftStartTime(
    plantMappingIDDept,
    shiftArray,
    shiftId,
    newShiftStartTime
  ) {
    const updatedShiftsHrsStartTime = shiftArray.map((shift) => {
      if (shift.shift === shiftId) {
        return { ...shift, shiftStartTime: newShiftStartTime };
      } else {
        return shift;
      }
    });
    dispatch(
      updateShiftHrsStartTime({ plantMappingIDDept, updatedShiftsHrsStartTime })
    );
  }
  function handleShiftHrChange(
    plantMappingIDDept,
    shiftArray,
    shiftId,
    newShiftHour
  ) {
   const updatedShiftsHrs = shiftArray.map((shift) => {
      if (shift.shift === shiftId) {
        return { ...shift, shiftHrs: newShiftHour };
      } else {
        return shift;
      }
    });
    const totalShiftHrs = updatedShiftsHrs.reduce(
      (total, shift) => total + shift.shiftHrs,
      0
    );
    
    const severity = totalShiftHrs > 24 ? "error" : "success";
    const message =
      totalShiftHrs > 24 
        ? t('shiftConfiguration.shift_hrs_error_message_greater_than_24')
        : t('shiftConfiguration.shift_hours_minimum')
 
    if (totalShiftHrs > 24 || totalShiftHrs < 1) {
      setShowResponseMessage(true);
      setShiftHrErrorMsg(message);
    } else {
      dispatch(
        updateShiftHrs({
          plantMappingIDDept,
          updatedShiftsHrs,
          severity,
          message,
        })
      );
    }
  }
  const handleDelete = (row) => {
    setOpenDeleteModal(true);
    setRowData(row);
  };
  const handleNoClickModal = () => {
    setOpenDeleteModal(false);
  };
  const handleYesClickModal = () => {
    const row = rowData;
    const shift = row.shiftData[row.selectedShiftIndex];
    dispatch(deleteShiftConfiguration(row.deptPlantMappingId)).then((res) => {
      setshowapiResponseMessage(true);
      dispatch(getShiftConfigurationDetails());
    });
    setOpenDeleteModal(false);
  };
  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 0.5,
  };
  const tableCellStyles = {
    p: 0,
  };
  function handleShiftChange(rowId, selectedShift) {
    let rowIndex = tableData.findIndex(
      (row) => row.deptPlantMappingId === rowId
    );
    if (rowIndex !== -1) {
      const shiftIndex = tableData[rowIndex].shiftData.findIndex(
        (shift) => shift.shift === selectedShift
      );
      if (shiftIndex !== -1) {
        dispatch(updateShift({ rowId, shiftIndex }));
      }
    }
  }

  function handleUpdate(rowData, mappingId) {
    if (
      rowData.dataStateChange &&
      rowData.shiftData[rowData.selectedShiftIndex].shift 
      // &&
      // rowData.shiftData[rowData.selectedShiftIndex].shiftHrs > 0
    ) {
      const data = {
        ...rowData,
        // shiftData: [rowData.shiftData[rowData.selectedShiftIndex]],
        shiftData: [...rowData.shiftData],
      };
      dispatch(updateShiftConfigurations(data)).then((res) => {
        setshowapiResponseMessage(true);
      });
    } else {
      setError(true);
    }
  }

  // Slice the data array to get only the items for the current page
  const visibleData = useMemo(
    () =>
      Array.isArray(data)
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [page, rowsPerPage, data]
  );

  return (
    <Box sx={{ height: "100%" }}>
      {/* Textfield */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenAddNewPlantModal(true);
          }}
        >
          {t("shiftConfiguration.add_new_modal.button_title")}
        </Button>
        <TextField
          name="search_requests"
          value={searchTerm}
          onChange={handleSearchChange}
          label={t("shiftConfiguration.search_placeholder")}
          size="small"
          sx={{
            height: "32px",
            fontSize: "13px",
            width: 300,
            ...fieldWithoutBorder,
          }}
        />
      </Box>

      {/* Table */}
      <Box sx={{ mt: 2 }}>
        <TableContainer sx={tableStyles}>
          <Table
            stickyHeader
            sx={{ border: "1px solid #a6a7a985" }}
            aria-label="sticky table"
            ref={tableRef}
          >
            <TableHead>
              <TableRow>
                {NEW_REQUEST_TABLE_COLUMNS.map(
                  ({ id, translationKey, width }) => {
                    return (
                      <TableCell
                        variant="head"
                        key={id}
                        sx={tableHeaderStyles}
                        width={width}
                      >
                        {t(translationKey)}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestsLoading ? (
                <TableRow>
                  <TableCell colSpan="9" align="center">
                    <CircularProgress size={20} color="primary" />
                  </TableCell>
                </TableRow>
              ) : visibleData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan="7">
                    {/* <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        letterSpacing: "0.25px",
                        lineHeight: "32px",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {t("admin_page.empty_list_text")}
                    </Typography> */}
                  </TableCell>
                </TableRow>
              ) : (
                visibleData &&
                visibleData.map((row, key) => (
                  <TableRow
                    key={row.plantMappingIDDept}
                    hover
                    sx={{ margin: 0 }}
                  >
                    <TableCell sx={{ ...tableCellStyles, pl: "5px" }}>
                      {row.plantName}
                    </TableCell>
                    <TableCell sx={tableCellStyles}>
                      {row.departmentName}
                    </TableCell>
                    <TableCell sx={tableCellStyles}>{row.timezone}</TableCell>
                    <TableCell sx={tableCellStyles}>
                      <Select
                        value={
                          row.dataStateChange
                            ? row.dataStateChange
                            : ""
                        }
                        defaultValue={
                          row.dataStateChange
                            ? row.dataStateChange
                            : ""
                        }
                        sx={{ width: "80%" }}
                        onChange={(event) => {
                          handleScheduledJobTime(
                            row && row.deptPlantMappingId,
                            event.target.value
                          );
                        }}
                        error={
                          error &&
                          row.dataStateChange === null &&
                          row.isDataChanged
                        }
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {scheduledJobTimeValues &&
                          scheduledJobTimeValues.map((time) => (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>

                    <TableCell sx={tableCellStyles}>
                      <Select
                        value={
                          row.shiftData &&
                          row.shiftData[row.selectedShiftIndex] &&
                          row.shiftData[row.selectedShiftIndex].shift
                        }
                        defaultValue={
                          row.shiftData &&
                          row.shiftData[row.selectedShiftIndex] &&
                          row.shiftData[row.selectedShiftIndex].shift
                        }
                        sx={{ width: "80%" }}
                        onChange={(event) =>
                          handleShiftChange(
                            row.deptPlantMappingId,
                            event.target.value
                          )
                        }
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {row.shiftData &&
                          row.shiftData.map((shift) => (
                            <MenuItem key={shift.shift_id} value={shift.shift}>
                              {shift.shift}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>

                    <TableCell sx={tableCellStyles}>
                      <Select
                        sx={{ width: "80%" }}
                        value={parseInt(
                          row.shiftData &&
                            row.shiftData[row.selectedShiftIndex] &&
                            row.shiftData[row.selectedShiftIndex].shiftHrs,
                          10
                        )}
                        defaultValue={parseInt(
                          row.shiftData &&
                            row.shiftData[row.selectedShiftIndex] &&
                            row.shiftData[row.selectedShiftIndex].shiftHrs,
                          10
                        )}
                        onChange={(event) => {
                          handleShiftHrChange(
                            row.deptPlantMappingId,
                            row.shiftData,
                            row.shiftData &&
                              row.shiftData[row.selectedShiftIndex].shift,
                            event.target.value
                          );
                        }}
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                        // error={
                        //   row.isDataChanged &&
                        //   row.shiftData[row.selectedShiftIndex].shiftHrs === 0
                        // }
                      >
                        {shiftHrDataValue &&
                          shiftHrDataValue.map((time) => (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                    <TableCell sx={tableCellStyles}>
                      <Select
                        sx={{ width: "80%" }}
                        value={
                          row.shiftData &&
                          row.shiftData[row.selectedShiftIndex] &&
                          row.shiftData[row.selectedShiftIndex].shiftStartTime
                            ? 
                                row.shiftData &&
                                  row.shiftData[row.selectedShiftIndex] &&
                                  row.shiftData[row.selectedShiftIndex]
                                    .shiftStartTime
                              
                            : ""
                        }
                        defaultValue={
                          row.shiftData &&
                          row.shiftData[row.selectedShiftIndex] &&
                          row.shiftData[row.selectedShiftIndex].shiftStartTime
                            ? 
                                row.shiftData &&
                                  row.shiftData[row.selectedShiftIndex] &&
                                  row.shiftData[row.selectedShiftIndex]
                                    .shiftStartTime
                                
                            : ""
                        }
                        onChange={(event) =>
                          handleShiftStartTime(
                            row.deptPlantMappingId,
                            row.shiftData,
                            row.shiftData &&
                              row.shiftData[row.selectedShiftIndex].shift,
                            event.target.value
                          )
                        }
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {shiftStartTimeValues &&
                          shiftStartTimeValues.map((time) => (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                    <TableCell sx={tableCellStyles}>
                      <Box>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid black",
                            cursor: "pointer",
                            borderRadius: "20px",
                            textTransform: "none",
                            mr: 3,
                            "&:hover": {
                              backgroundColor: "white",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "white",
                            },
                          }}
                          disabled={!row.isDataChanged}
                          onClick={() => handleUpdate(row)}
                        >
                          {t("shiftConfiguration.update")}
                        </Button>

                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "red",
                            cursor: "pointer",
                            borderRadius: "20px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "red",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "red",
                            },
                          }}
                          onClick={() => handleDelete(row)}
                        >
                          {t("shiftConfiguration.delete")}
                        </Button>
                      </Box>
                    </TableCell>
                    {/* Modal For Delete */}
                    {/* <AlertModal  open={openDeleteModal}
                      title={t("shiftConfiguration.delete_modal.title")}
                      descriptionText={t(
                        "shiftConfiguration.delete_modal.description"
                      )}
                      noButtonText={t(
                        "shiftConfiguration.delete_modal.no_button_text"
                      )}
                      yesButtonText={t(
                        "shiftConfiguration.delete_modal.yes_button_text"
                      )}
                      onNoClick={handleNoClickModal}
                      onYesClick={handleYesClickModal}/> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={tableData && tableData.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <UpdatePermissionModal
        open={openDeleteModal}
        title={t("shiftConfiguration.delete_modal.title")}
        descriptionText={t("shiftConfiguration.delete_modal.description")}
        noButtonText={t("shiftConfiguration.delete_modal.no_button_text")}
        yesButtonText={t("shiftConfiguration.delete_modal.yes_button_text")}
        onNoClick={handleNoClickModal}
        onYesClick={handleYesClickModal}
        isLoading={requestsLoading}
      />
      {/* Snackbar for api success, error response */}
      <Notification
        open={showResponseMessage}
        onClose={() => setShowResponseMessage(false)}
        severity={"error"}
        message={shiftHrErrorMsg}
      />
      <Notification
        open={error}
        onClose={() => setError(false)}
        severity={"error"}
        message={t("shiftConfiguration.fill_empty_fields")}
      />
      <Notification
        open={showapiResponseMessage}
        onClose={() => setshowapiResponseMessage(false)}
        severity={shiftConfigurationState.severity}
        message={shiftConfigurationState.message}
      />

      <NewPlantModal
        open={openAddNewPlantModal}
        title={t("shiftConfiguration.add_new_modal.modal_title")}
        isLoading={addNewPlantDepartmentState.isLoading}
        handleClose={() => setOpenAddNewPlantModal(false)}
        onCreateClick={(data, resetModalValues) => {
          dispatch(createNewPlantAndDepartment(data))
            .then((res) => {
              if(res.error && res.meta.requestStatus === 'rejected'){
                setAddNewPlantResponse(true);
                return;
              }

              if(res.meta.requestStatus === 'fulfilled'){
                setAddNewPlantResponse(true);
                setOpenAddNewPlantModal(false)
                dispatch(getShiftConfigurationDetails())
                resetModalValues() // reset modal values on sucess
              }
            })
          }}
        onCancelClick={() => setOpenAddNewPlantModal(false)}
      />
      {/* Snackbar for error or sucess of add new plant */}
      <Notification
        open={addNewPlantResponse}
        onClose={() => setAddNewPlantResponse(false)}
        severity={addNewPlantDepartmentState.severity}
        message={addNewPlantDepartmentState.message}
      />
    </Box>
  );
};

export default withTranslation()(ShiftConfiguration);
