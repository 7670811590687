import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  MANAGE_PERMISSIONS_TABLE_COLUMNS,
  ROW_PER_PAGE_INITIAL_COUNT,
  ROWS_PER_PAGE_OPTIONS,
  getRoleId,
} from "../../constants/ManagePermissions";
import { withTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteManagePermissions,
  getManagePermissions,
  updateManagePermissions,
  copyPermissionForNewEmail,
} from "../../reducers/ManagePermissionsSlice";
import UpdatePermissionModal from "../Modal/UpdatePermissionModal";
import Notification from "../Notification/Notification";
import { ContentCopy } from "@mui/icons-material";
import CopyPermissionModal from "../Modal/CopyPermissionModal";
import {
  getDepartmentFromPlantId,
  getPlantRequestDetails,
} from "../../reducers/PlantRequestSlice";
import { DATA_ENTRY, DATA_PROCESSING } from "../../constants/GlobalConstants";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function MultipleSelect({
  selectedOptions,
  allOptions,
  handleOptions,
  userId,
}) {
  const [options, setOptions] = useState(
    selectedOptions.map((dept) => dept.departmentId)
  );

  useEffect(() => {
    setOptions(selectedOptions.map((dept) => dept.departmentId));
  }, [selectedOptions]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // If value is an empty array, return without updating options
    if (Array.isArray(value) && value.length === 0) {
      return;
    }
    setOptions(value);
    handleOptions(value, userId);
  };

  return (
    <div>
      <FormControl sx={{ width: "80%" }}>
        <Select
          multiple
          value={options}
          onChange={handleChange}
          renderValue={(selected) =>
            selected
              .map(
                (departmentId) =>
                  allOptions.find((e) => e.departmentId === departmentId)
                    ?.department
              )
              .join(", ")
          }
          MenuProps={MenuProps}
          sx={{ maxWidth: 400 }}
        >
          {allOptions.map((option) => (
            <MenuItem key={option.departmentId} value={option.departmentId}>
              <Checkbox
                checked={options.indexOf(option.departmentId) > -1}
                size="small"
                sx={{ p: 0.2 }}
              />
              <ListItemText primary={option.department} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const ManagePermissions = ({ t }) => {
  const theme = useTheme();

  const tableStyles = {
    "@media (width <= 1140px)": {
      height: "53vh",
    },
    "@media (min-width: 1400px)": {
      height: "65vh",
    },
  };

  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 0.5,
  };

  const tableCellStyles = {
    p: 0,
  };

  const fieldWithoutBorder = {
    ".MuiInputBase-root": {
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: "13px",
    },
  };

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [searchRequest, setSearchRequest] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_INITIAL_COUNT);
  const [page, setPage] = useState(0);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedRowData, setUpdatedRowData] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});

  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const { isLoading: permissionsLoading, items } = useSelector(
    (state) => state.managePermissionsState
  );

  const managePermissionsState = useSelector(
    (state) => state.managePermissionsState
  );

  const plantList = useSelector((state) => state.plantRequestState.plantList);

  useEffect(() => {
    dispatch(getManagePermissions());
    dispatch(getPlantRequestDetails());

    // dispatch(getManagePermissions()).then((res) => {
    // setData(res.payload);
    // setData(res.payload.map((row) => ({ ...row, isDirty: false })));
  }, []);

  useEffect(() => {
    if (searchRequest) {
      const searchingData = [...items];
      const result = searchData(searchingData, searchRequest);
      setData(result);
    } else {
      setData(items);
    }
    // setData(items)
  }, [items]);

  const visibleData = useMemo(
    () =>
      Array.isArray(data)
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [page, rowsPerPage, data]
  );

  function searchData(data, searchTerm) {
    return data.filter((item) => {
      return (
        item.userEmailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.plantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.employeeId.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  const handleSearchRequestChange = (e) => {
    setSearchRequest(e.target.value);
    setPage(0);
    const searchingData = [...items];
    if (e.target.value === "") setData(items); // changed implementation
    const result = searchData(searchingData, e.target.value);
    setData(result);
  };

  //Pagination Code
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleChange = (event, userId) => {
    const newRole = event.target.value;
    const users = [...data];

    const updatedList = users.map((user) =>
      user.userId === userId
        ? { ...user, roleName: newRole, isDirty: true }
        : user
    );
    /*iterating updatedList based on Role if role is 
     data_processing then all departments get selected and
     when role is Data_entry, previously selected departments should appear */
    updatedList.map((user) => {
      if (user.userId === userId) {
        if (user.roleName === DATA_ENTRY) {
          user.departments = user.dataEntryDepartments
            ? user.dataEntryDepartments
            : user.departments;
        }
        if (user.roleName === DATA_PROCESSING) {
          user.dataEntryDepartments = user.departments;
          user.departments = user.allDepartments;
        }
      }
    });
    setData(updatedList);
  };

  const handlePlantChange = (e, userId) => {
    const users = [...data];
    const newPlant = e.target.value;

    const selectedPlantId = plantList.find(
      (plant) => plant.plantName === newPlant
    ).plantId;

    dispatch(getDepartmentFromPlantId(selectedPlantId)).then((res) => {
      // setDepartmentListToConsider(res.payload);
        const updatedList = users.map((user) =>
        user.userId === userId
          ? {
              ...user,
              plantName: newPlant,
              newPlantName: newPlant,
              newPlantId: selectedPlantId,
              isPlantChanged: true,
              isDirty: true,
              departments: [res.payload[0]],
              allDepartments: res.payload,
              dataEntryDepartments:[res.payload[0]]
            }
          : user
      );
        /*For Plant Change, iterating updatedList based on Role if role is 
     data_processing then all departments get selected and
     when role is Data_entry, previously selected departments should appear */
      updatedList.map((user) => {
        if (user.userId === userId) {
          if (user.roleName ===DATA_ENTRY) {
            user.departments = user.dataEntryDepartments
              ? user.dataEntryDepartments
              : user.departments;
          }
          if (user.roleName === DATA_PROCESSING) {
            user.dataEntryDepartments = user.departments;
            user.departments = user.allDepartments;
          }
        }
      });
      setData(updatedList);
    });
  };

  const updatePermissions = (newOptions, row) => {
    // dispatch update permission api call with data

    const params = {
      emailId: row.userEmailId,
      plantId: row.plantId,
      departmentIds: newOptions.join(","),
      roleId: getRoleId(row.roleName),
      employeeId: row.employeeId,
      newPlantId: row.isPlantChanged ? row.newPlantId : 0,
    };

    dispatch(updateManagePermissions(params)).then((res) => {
      setOpenUpdateModal(false);
      setShowResponseMessage(true);
      // setSearchRequest("");
      return dispatch(getManagePermissions());
    });
    // .then((res) => {
    //   // setData(res.payload);
    //   setData(res.payload.map((row) => ({ ...row, isDirty: false })));
    // });
  };

  const handleUpdate = (newOptions, row) => {
    const allEntries = [...data];

    // Find all entries with the same email id as the current row
    const sameEmailEntries = allEntries.filter(
      (entry) => entry.userEmailId === row.userEmailId
    );

    if (sameEmailEntries.length > 1) {
      // Get the roles of all entries with the same email id
      const roles = sameEmailEntries.map((entry) => entry.roleName);

      // Check if all roles are the same
      const allRolesSame = roles.every((val, i, arr) => {
        return val === arr[0];
      });

      if (!allRolesSame) {
        // If the roles are not the same, show modal
        setOpenUpdateModal(true);
        setUpdatedRowData({
          updatedOptions: newOptions,
          currentRow: row,
        });
      } else {
        // duplicate entry present but roles are same
        updatePermissions(newOptions, row);
      }
    } else {
      // dispatch update api call with data
      // no duplicate entry of the user
      updatePermissions(newOptions, row);
    }
  };

  const handleNoClickModal = () => {
    // trigger when you click no button on modal
    setOpenUpdateModal(false);
  };

  const handleYesClickModal = () => {
    // trigger when you click yes button on modal
    updatePermissions(updatedRowData.updatedOptions, updatedRowData.currentRow);
  };

  const onDeleteModalNoClick = () => {
    setOpenDeleteModal(false);
  };

  const onDeleteModalYesClick = () => {
    const params = {
      emailId: deleteRowData.userEmailId,
      plantId: deleteRowData.plantId,
    };
    dispatch(deleteManagePermissions(params))
      .then((res) => {
        setOpenDeleteModal(false);
        setShowResponseMessage(true);
        // setSearchRequest("");
        return dispatch(getManagePermissions());
      })
      .then((res) => {
        setData(res.payload.map((row) => ({ ...row, isDirty: false })));
      });
  };

  const handleDelete = (row) => {
    setDeleteRowData(row);
    setOpenDeleteModal(true);
  };

  const handleCopyPermissionSubmit = (finalObj, closeModalHandler) => {
    dispatch(copyPermissionForNewEmail(finalObj))
      .then((res) => {
        //need to check if the form is reset after successful api call
        closeModalHandler();
        setOpenCopyModal(false);
        setShowResponseMessage(true);
        // setSearchRequest("");
        return dispatch(getManagePermissions());
      })
      .then((res) => {
        setData(res.payload.map((row) => ({ ...row, isDirty: false })));
      });
  };
  return (
    <Box sx={{ height: "100%" }}>
      {/* Textfield */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <TextField
          name="search_requests"
          value={searchRequest}
          onChange={handleSearchRequestChange}
          label={t(
            "admin_page.manage_permissions.manage_permissions_search_user_label"
          )}
          size="small"
          sx={{
            height: "32px",
            fontSize: "13px",
            width: 300,
            ...fieldWithoutBorder,
          }}
        />
      </Box>

      {/* Table */}
      <Box sx={{ mt: 2 }}>
        <TableContainer sx={tableStyles}>
          <Table
            stickyHeader
            sx={{ border: "1px solid #a6a7a985" }}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {MANAGE_PERMISSIONS_TABLE_COLUMNS.map(
                  ({ id, translationKey, width }, index) => (
                    <TableCell
                      variant="head"
                      key={id}
                      sx={{
                        ...tableHeaderStyles,
                        pl: index === 0 ? "5px" : 0,
                      }}
                      width={width}
                    >
                      {t(translationKey)}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {permissionsLoading ? (
                <TableRow>
                  <TableCell colSpan="6" align="center">
                    <CircularProgress size={20} color="primary" />
                  </TableCell>
                </TableRow>
              ) : visibleData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan="6">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        letterSpacing: "0.25px",
                        lineHeight: "32px",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {t("admin_page.manage_permissions.no_user_found")}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                visibleData.map((row) => {
                  const {
                    userId,
                    userEmailId,
                    plantName,
                    departments,
                    allDepartments,
                    roleName,
                    employeeId,
                    isDirty,
                  } = row;

                  let newOptions = [
                    ...departments.map((dept) => dept.departmentId),
                  ];

                  const handleOptions = (updatedOptions, userId) => {
                    newOptions = updatedOptions;

                    // saving updated options in data state
                    const users = [...data];
                    const updatedOptionsList = users.map((user) =>
                      user.userId === userId
                        ? {
                            ...user,
                            departments: allDepartments.filter((dept) =>
                              updatedOptions.includes(dept.departmentId)
                            ),
                            isDirty: true,
                          }
                        : user
                    );
                    setData(updatedOptionsList);
                  };

                  return (
                    <TableRow key={userId} hover>
                      <TableCell sx={{ ...tableCellStyles, pl: "5px" }}>
                        {userEmailId}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>{employeeId}</TableCell>
                      <TableCell sx={tableCellStyles}>
                        <Select
                          value={plantName}
                          onChange={(event) => handlePlantChange(event, userId)}
                          sx={{
                            width: "80%",
                          }}
                          MenuProps={MenuProps}
                        >
                          {plantList.map(
                            ({ plantName: plantOption, plantId }) => {
                              // Check if there is a user with the same email id having the same plantName
                              const isDuplicate = visibleData.some(
                                (user) =>
                                  user.userEmailId === userEmailId &&
                                  user.plantName === plantOption
                              );
                              // Return the MenuItem with the disabled prop set to the value of isDuplicate
                              return (
                                <MenuItem
                                  value={plantOption}
                                  key={plantId}
                                  disabled={isDuplicate}
                                >
                                  {plantOption}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <MultipleSelect
                          selectedOptions={departments}
                          allOptions={allDepartments}
                          handleOptions={handleOptions}
                          userId={userId}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <Select
                          value={roleName}
                          onChange={(event) => handleChange(event, userId)}
                          sx={{
                            width: "80%",
                          }}
                        >
                          <MenuItem value="Data Entry">Data Entry</MenuItem>
                          <MenuItem value="Data Processing">
                            Data Processing
                          </MenuItem>
                        </Select>
                        {/* {roleName} */}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <Chip
                          label={t(
                            "admin_page.manage_permissions.update_button"
                          )}
                          variant="outlined"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleUpdate(newOptions, row);
                          }} //API of approve
                          disabled={!isDirty}
                        />
                        <Chip
                          label={t(
                            "admin_page.manage_permissions.delete_button"
                          )}
                          color="error"
                          sx={{ cursor: "pointer", ml: 1 }}
                          onClick={() => {
                            // handleUpdate(newOptions, row);
                            handleDelete(row);
                          }} //API of delete
                        />
                        <IconButton
                          sx={{ cursor: "pointer", ml: 1 }}
                          onClick={() => {
                            setOpenCopyModal(true);
                            setRowData(row);
                          }}
                          size="small"
                        >
                          <ContentCopy />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rows_per_page")}
          labelDisplayedRows={({ from, to, count }) =>
            `${t("pagination.from_to_count", { from, to, count })}`
          }
        />

        {/* Modal for Copy Permission */}
        <CopyPermissionModal
          open={openCopyModal}
          handleClose={() => setOpenCopyModal(false)}
          data={rowData}
          handleCopyPermissionSubmit={(finalObj, closeModalHandler) => {
            handleCopyPermissionSubmit(finalObj, closeModalHandler);
          }}
        />
        {/* Modal for Update Permission */}
        <UpdatePermissionModal
          open={openUpdateModal}
          title={t("admin_page.manage_permissions.update_modal.title")}
          descriptionText={t(
            "admin_page.manage_permissions.update_modal.description"
          )}
          noButtonText={t(
            "admin_page.manage_permissions.update_modal.no_button_text"
          )}
          yesButtonText={t(
            "admin_page.manage_permissions.update_modal.yes_button_text"
          )}
          onNoClick={handleNoClickModal}
          onYesClick={handleYesClickModal}
          isLoading={permissionsLoading}
        />

        {/* Modal for Delete Permision */}
        <UpdatePermissionModal
          open={openDeleteModal}
          title={t("admin_page.manage_permissions.delete_modal.title")}
          descriptionText={t(
            "admin_page.manage_permissions.delete_modal.description"
          )}
          noButtonText={t(
            "admin_page.manage_permissions.delete_modal.no_button_text"
          )}
          yesButtonText={t(
            "admin_page.manage_permissions.delete_modal.yes_button_text"
          )}
          onNoClick={onDeleteModalNoClick}
          onYesClick={onDeleteModalYesClick}
          isLoading={permissionsLoading}
        />

        {/* Snackbar for api success, error response */}
        <Notification
          open={showResponseMessage}
          onClose={() => setShowResponseMessage(false)}
          severity={managePermissionsState.severity}
          message={managePermissionsState.message}
        />
      </Box>
    </Box>
  );
};

export default withTranslation()(ManagePermissions);
