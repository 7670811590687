import { Box, CircularProgress } from '@mui/material';
import MainSelection from './MainSelection';
import MachineList from './MachineList';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from '../../reducers/UserSlice';
import {DATE_FORMAT} from '../../constants/GlobalConstants';
import {ALL_CREWS} from '../../constants/HomeScreen';
import {getPlantData} from '../../reducers/PlantSlice';
import { getDepartmentData } from '../../reducers/DepartmentSlice';
// import AdminPage from '../Admin/AdminPage';
// import { useParams } from 'react-router-dom';

const Home = () => {
  // const queryParams = useParams();  //get query params for any passed route
  const [isProceedClicked, setIsProceedClicked] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [machineSelectionValues, setMachineSelectionValues] = useState(
    {
      plantId: 0,
      plant: '',
      departmentId: 0,
      department: '',
      date: format(new Date(), DATE_FORMAT),
      shift: '',
      crew: '',
      deptPlantId: 0
    }
  );
  const [isDataChanged, setDataChangeStatus] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const dispatch = useDispatch();
  const {userDetails} = useSelector((state) => state.userState);
  const {items} = useSelector((state)=> state.departments);

  useEffect(()=>{
    dispatch(getUserData());
  },[dispatch]);


  useEffect(()=>{
    Object.keys(userDetails).length > 0 && setMachineSelectionValues(
       {
         plantId: userDetails.plantId,
         plant: userDetails.plantName,
         departmentId: userDetails.dept[0]['departmentId'],
         department: userDetails.dept[0]['departmentName'],
         date: format(new Date(), DATE_FORMAT),
         shift: userDetails.shift[0]['shift'],
         crew: ALL_CREWS,
         allShiftDetails: items?.shift,
         deptPlantId: userDetails.dept[0]['deptPlantId']
       }
     );
   }, [userDetails]);

   useEffect(() => {
     Object.keys(userDetails).length > 0 &&
       setMachineSelectionValues((prevState) => ({
         ...prevState,
         allShiftDetails: items?.shift,
       }));
   }, [items]);


   useEffect(()=>{
    setMachineSelectionValues((prevState)=>{
      return {
        ...prevState,
        shift: items['shift'] && items.shift[0]['shift']
      }
    });
   },[items, items['departmentId']])
   
  const handleMainSelection = (obj) => {
    setMachineSelectionValues(obj);
  }

  useEffect(()=>{
    // Calls api when deptId changes or if its truthy boolean value
    if(machineSelectionValues.deptPlantId){
      dispatch(getDepartmentData(machineSelectionValues.deptPlantId))
    }
  },[ machineSelectionValues.departmentId, machineSelectionValues.deptPlantId, dispatch])


  const handleMachineSelectionInputChange = (e, obj, name, pendingEvent = false) => {
    if (isDataChanged && !pendingEvent) {
      setShowWarningModal(true);
      localStorage.setItem('pendingEvent', JSON.stringify({
        functionType: 'dropDown',
        e: name === 'date' ? {target:{value: e.target.value}} : null,
        obj: name === 'date' ? {} : obj,
        name: name,
      }));
      return;
    }
    setIsProceedClicked(false);
    if(obj === null) { return; }
    switch(name){
      case 'plant':
        setMachineSelectionValues({
          ...machineSelectionValues,
          plantId: obj.id,
          [name]: obj.label,
        });
        const fetchApi = () => {
          dispatch(getPlantData(obj.id));
        }
        fetchApi();
        break;
      case 'department':
        setMachineSelectionValues({
          ...machineSelectionValues,
          departmentId: obj.departmentId,
          deptPlantId: obj.id,
          [name]: obj.label,
        });
        break;
      default:
        setMachineSelectionValues({
          ...machineSelectionValues,
          [name]: name === 'date' ? e.target.value : obj.label,
        });
    }
  };

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
          <MainSelection
            isProceedClicked={isProceedClicked}
            setIsProceedClicked={setIsProceedClicked}
            machineSelectionValues={machineSelectionValues}
            setMachineSelectionValues={handleMainSelection}
            tabValue={tabValue}
            handleMachineSelectionInputChange = {handleMachineSelectionInputChange}
            isDataChanged = {isDataChanged}
          />

          <MachineList
            machineSelectionValues={machineSelectionValues}
            isProceedClicked={isProceedClicked}
            tabValue={tabValue}
            setTabValue={setTabValue}
            isDataChanged = {isDataChanged}
            setDataChangeStatus = {setDataChangeStatus}
            showWarningModal = {showWarningModal}
            setShowWarningModal = {setShowWarningModal}
            handleMachineSelectionInputChange = {handleMachineSelectionInputChange}
          />
    </Box>
  );
};

export default Home;
