import {
  Autocomplete,
  Button,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { format, subDays } from 'date-fns';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {getMachineData} from '../../reducers/MachineSlice';

import { getRunTimeData } from '../../reducers/RuntimeDetailSlice';
import { getDownTimeData } from '../../reducers/DowntimeDetailSlice';
import { DATE_FORMAT } from '../../constants/GlobalConstants';
import {ALL_CREWS} from '../../constants/HomeScreen';
import { getScrapDetails } from '../../reducers/ScrapDetailsSlice';
// import { getNotScheduledData } from '../../reducers/NotScheduledDetailsSlice';

const autoCompleteStyles = {
  width: 226,
  '.MuiInputBase-root':{
    height: 36
  }
};

const MainSelection = ({ 
  setIsProceedClicked, 
  machineSelectionValues, 
  setMachineSelectionValues, 
  tabValue,
  handleMachineSelectionInputChange,
  isDataChanged,
  t 
}) => {

  const {userDetails} = useSelector((state) => state.userState);
  const {plantDetails} = useSelector((state) => state.plantState);
  const {items} = useSelector((state)=> state.departments);
  const dispatch = useDispatch();
  const todayDate = new Date();
  const yesterdayDate = subDays(todayDate, 1);
  const dayBefore = subDays(todayDate, 2); //Three days ago date
  let plantOptions=[], departmentOptions=[], shiftOptions=[], crewOptions=[];

  if(userDetails.plant?.length>0){
    plantOptions = userDetails.plant.map((obj)=>{
      return {label: obj.plantName, id: obj.plantId};
    });

    const mainSelectionData = Object.keys(plantDetails).length > 0 ? plantDetails : userDetails;

    departmentOptions = mainSelectionData.dept.map((obj)=>{
      return {label: obj.departmentName, id: obj.deptPlantId, departmentId: obj.departmentId};
    });

    /*
    Load shift from dept api otherwise from current machine selection data

    */
    shiftOptions = items['shift'] ? items.shift.map((obj)=>{
      return {label: obj.shift};
    }) : mainSelectionData.shift.map((obj)=>{
      return {label: obj.shift};
    });
    
    crewOptions = mainSelectionData.crew.map((obj)=>{
      return {label: obj.crew};
    });
    crewOptions.unshift({label: ALL_CREWS});
  }
 
  const dateOptions = [
    {
      id: 'today',
      label: `${todayDate.toLocaleDateString()} - 
        ${t('dates.today')}`,
      value: format(todayDate, DATE_FORMAT),
    },
    {
      id: 'yesterday',
      label: `${yesterdayDate.toLocaleDateString()} - 
        ${t('dates.yesterday')}`,
      value: format(yesterdayDate, DATE_FORMAT),
    },
    {
      id: 'dayBefore',
      label: `${dayBefore.toLocaleDateString()} - 
        ${t('dates.day_before')}`,
      value: format(dayBefore, DATE_FORMAT),
    },
  ];

  useEffect(()=>{
    Object.keys(plantDetails).length > 0 && setMachineSelectionValues(
      {
        ...machineSelectionValues,
        plantId: plantDetails.plantId,
        plant: plantDetails.plantName,
        departmentId: plantDetails.dept[0]['departmentId'],
        department: plantDetails.dept[0]['departmentName'],
        deptPlantId: plantDetails.dept[0]['deptPlantId'],
        // shift: plantDetails.shift[0]['shift'], // Shift is coming from department API call earlier it was from plant API
        crew: ALL_CREWS,
      }
    );
  },[plantDetails]);

  /**
   * This is done because Mui-Autocomplete component doesn't provide
   * name attribute as prop
   * @param {*} e
   * @param {*} newValue Value that user typing
   * @param {*} name name of the field
   */

  const { plant, department, date, shift, crew, deptPlantId } = machineSelectionValues;

  const getMachineList = () => {
    switch(tabValue){
      case 1:
        const fetchApi = () => {
          dispatch(getRunTimeData({
            date,
            shift,
            machinecode:null,
            crew,
            deptPlantId
          }));
        }
        fetchApi();
        break;
      case 2:
        const fetchDownTimeApi = () => {
          dispatch(getDownTimeData({
            date,
            shift,
            machinecode:null,
            crew,
            deptPlantId
          }));
        }
        fetchDownTimeApi();
        break;
      // case 3:
      //   const fetchNotScheduledApi = () => {
      //     dispatch(
      //       getNotScheduledData({
      //         date,
      //         shift,
      //         machinecode: null,
      //         crew,
      //         deptId: departmentId,
      //       })
      //     );
      //   };
      //   fetchNotScheduledApi();
      case 3:
        const fetchScrapDetails = () => {
          dispatch(
            getScrapDetails({
              date,
              shift,
              machinecode: null,
              crew,
              deptPlantId
            })
          );
        };
        fetchScrapDetails();
        break;
      default:
        dispatch(getMachineData(machineSelectionValues));
    }
  };

  return (
    <Box
      sx={{
        mt: '3rem',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Typography fontWeight="600" fontSize="18px" color="text.primary">
        {t('main_selection.heading')}
      </Typography>
      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Autocomplete
          sx={autoCompleteStyles}
          options={plantOptions}
          size='small'
          value={plant}
          autoComplete
          autoHighlight
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.label === value
          }
          onChange={(e, newValue) => {
            handleMachineSelectionInputChange(e, newValue, "plant");
          }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              name='plant' 
              label={t('main_selection.plant_label')} 
              required 
            />
          )}
        />
        <Autocomplete
          sx={autoCompleteStyles}
          options={departmentOptions}
          size='small'
          value={department}
          onChange={(e, newValue) => {
            handleMachineSelectionInputChange(e, newValue, "department");
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.label === value
          }
          renderInput={(params) => (
            <TextField 
              {...params} 
              name='department' 
              label={t('main_selection.department_label')} 
              required
            />
          )}
        />

        <FormControl sx={{ width: 220 }} size='small' required>
          <InputLabel>{t('main_selection.date_label')}</InputLabel>
          <Select
            value={date}
            label={t('main_selection.date_label')}
            onChange={(e, newValue) => {
              handleMachineSelectionInputChange(e, newValue, "date");
            }}
            name='date'
          >
            {dateOptions.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          sx={autoCompleteStyles}
          options={shiftOptions}
          size='small'
          value={shift}
          onChange={(e, newValue) => {
            handleMachineSelectionInputChange(e, newValue, "shift");
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.label === value
          }
          renderInput={(params) => (
            <TextField 
              {...params} 
              name='shift' 
              label={t('main_selection.shift_label')} 
              required 
            />
          )}
        />

        <Autocomplete
          sx={autoCompleteStyles}
          options={crewOptions}
          size='small'
          value={crew}
          onChange={(e, newValue) => {
            handleMachineSelectionInputChange(e, newValue, "crew");
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.label === value
          }
          renderInput={(params) => <TextField 
            {...params} 
            name='crew' 
            label={t('main_selection.crew_label')} 
          />}
        />

        <Button
          type="submit"
          variant="contained"
          onClick={() => {
            setIsProceedClicked(true);
            getMachineList();
          }}
          size="small"
          sx={{
            height: '2.25rem'
          }}
          disabled={isDataChanged}
        >
          {t('main_selection.proceed_button_label')}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation()(MainSelection);