import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Chip,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Checkbox,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { useFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlantRequestDetails,
  getAllRoles,
  getDepartmentFromPlantId,
  addAccessRequestDetails,
} from "../../reducers/PlantRequestSlice";
import BerryLogo from "../../assets/png/Berry Logo.png";
import Work from "../../assets/png/Work.png";
import * as Yup from "yup";
import Notification from "../Notification/Notification";
import { useNavigate } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PlantRequest = ({ t }) => {

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t("plant_request.validations_text.firstName_required")),
    lastname: Yup.string().required(t("plant_request.validations_text.lastName_required")),
    emailId: Yup.string()
      .email(t("plant_request.validations_text.invalid_email"))
      .test(
        "is-berryglobal",
        t("plant_request.validations_text.invalid_email"),
        (value) => {
          return /@berryglobal\..+$/i.test(value);
        }
      )
      .required(t("plant_request.validations_text.email_required")),
    supervisorEmailId: Yup.string()
      .email(t("plant_request.validations_text.invalid_email"))
      .test("is-berryglobal", t("plant_request.validations_text.invalid_email"), (value) => {
        return /@berryglobal\..+$/i.test(value);
      })
      .required(t("plant_request.validations_text.email_required")),
    additionalInfo: Yup.string().max(50, t("plant_request.validations_text.additional_information_max_fifty")),
    plantName: Yup.string().required(t("plant_request.validations_text.plant_name_required")),
    role: Yup.number().required(t("plant_request.validations_text.role_required")),
    dept: Yup.array().min(1, t("plant_request.validations_text.department_required")),
    employeeId: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, t("plant_request.validations_text.only_alphanumeric"))
      .max(10, t("plant_request.validations_text.max_ten_characters"))
      .required(t("plant_request.validations_text.employeeId_required")),
  });

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const [selectedPlant, setSelectedPlant] = useState("");
  const plantRequestDetails = useSelector(
    (state) => state.plantRequestState.plantList
  );
  const rolesList = useSelector((state) => state.plantRequestState.roles);
  const departmentsList = useSelector(
    (state) => state.plantRequestState.departments
  );
  const plantRequestState = useSelector((state) => state.plantRequestState);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getPlantRequestDetails());
    dispatch(getAllRoles());
  }, []);

  useEffect(() => {
    if (selectedPlant) {
      dispatch(getDepartmentFromPlantId(selectedPlant));
      setSelectedRoles("");
      setSelectedDepartments([]);
    }
  }, [selectedPlant]);
  useEffect(() => {
    //get roles based on roleID
    const selectedRole = rolesList.filter(
      (role) => role.roleId === selectedRoles
    );
    if (
      selectedRole &&
      selectedRole[0] &&
      selectedRole[0].role === "Data Processing"
    ) {
      const sortedDepartmentIds =
        departmentsList && departmentsList.map((dept) => dept.departmentId);
      setSelectedDepartments(sortedDepartmentIds);
      formik.setFieldValue("dept", sortedDepartmentIds);
    } else {
      setSelectedDepartments([]);
      formik.setFieldValue("dept", []);
    }
  }, [selectedRoles, selectedPlant]);
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      emailId: "",
      supervisorEmailId: "",
      additionalInfo: "",
      plantName: "",
      role: "",
      dept: [],
      employeeId: "",
    },
    validationSchema: validationSchema,
    // enableReinitialize: true,
  });
  return (
    <Box
      height={"100vh"}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height={"100vh"}
        width={"32%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          height={"100%"}
          sx={{
            backgroundImage: `linear-gradient(to bottom, rgba(18, 71, 128, 0.416), rgba(5, 67, 134, 0.8)),url(${Work})`,
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{ padding: "10%", mt: "35%" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: 210,
                  mr: 1,
                  mb: 7,
                  backgroundColor: "white",
                }}
                alt="berry global logo"
                src={BerryLogo}
              />
            </Box>
            <Box sx={{ flex: 1, width: "70%" }}>
              <Typography
                variant="h6"
                color={"white"}
                sx={{ fontWeight: "bold" }}
              >
                {t("plant_request.form_description")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box p={1} height={"100vh"} flexBasis="80%" padding={3}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("plant_request.heading")}
        </Typography>
        <form
          height={100}
          style={{ marginTop: "2%" }}
          onSubmit={formik.handleSubmit}
        >
          <Box display="flex" 
          sx={{ marginBottom: "1%" }}
          >
            <TextField
              errorStyle={{ position: "absolute" }}
              variant="outlined"
              sx={{
                flex: 1,
                marginRight: "2%",
                justifyContent: "center",
                fontSize: "0.875rem",
              }}
              size="small"
              // required
              name="firstname"
              type="text"
              label={
                " " +
                `${t("plant_request.plant_request_form.firstName")}` +
                "*  \u00A0"
              }
              value={formik.values.firstname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={
                <FormHelperText style={{margin:'0'}}>
                  { formik.touched.firstname &&  formik.errors.firstname ? formik.errors.firstname : " "}
                </FormHelperText>
              }
            />
            <TextField
              size="small"
              errorStyle={{ position: "absolute" }}
              sx={{ flex: 1, marginRight: "2%", fontSize: "0.875rem" }}
              name="lastname"
              type="text"
              variant="outlined"
              // required
              label={
                t("plant_request.plant_request_form.lastName") + "*  \u00A0"
              }
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={
                <FormHelperText style={{margin:'0'}}>
                  {formik.touched.lastname &&  formik.errors.lastname ? formik.errors.lastname : " "}
                </FormHelperText>
              }
            />
          </Box>
          <Box
            display="flex"
            sx={{ marginBottom: "1%" }}
            justifyContent="center"
          >
            <FormControl size="small" fullWidth sx={{ marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("plant_request.plant_request_form.plant") + "*  \u00A0"}
              </InputLabel>
              <Select
                //  errorStyle={{position: "absolute"}}
                labelId="demo-simple-select-helper-label"
                value={selectedPlant}
                name="plantName"
                InputLabelProps={{ fontSize: "0.875rem" }}
                inputProps={{ fontSize: "0.875rem" }}
                label={
                  t("plant_request.plant_request_form.plant") + " *   \u00A0"
                }
                onChange={(event) => {
                  setSelectedPlant(event.target.value);
                  formik.setFieldValue("plantName", event.target.value);
                }}
                error={
                  formik.touched.plantName && Boolean(formik.errors.plantName)
                }
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 250 } },
                }}
              >
                {plantRequestDetails &&
                  plantRequestDetails.map((plant) => (
                    <MenuItem key={plant.plantId} value={plant.plantId}>
                      <ListItemText primary={plant.plantName} />
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText
                errorStyle={{ position: "absolute" }}
                error={
                  formik.touched.plantName && Boolean(formik.errors.plantName)
                }
              >
                {formik.touched.plantName &&  formik.errors.plantName ? formik.errors.plantName : " "}
              </FormHelperText>
            </FormControl>
            <FormControl size="small" fullWidth sx={{ marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("plant_request.plant_request_form.role") + "*  \u00A0"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedRoles}
                label={t("plant_request.plant_request_form.role") + "*  \u00A0"}
                name="role"
                inputProps={{ fontSize: "0.875rem" }}
                InputLabelProps={{ fontSize: "1.8rem" }}
                error={formik.touched.role && Boolean(formik.errors.role)}
                onChange={(event) => {
                  setSelectedRoles(event.target.value);
                  formik.setFieldValue("role", event.target.value);
                }}
              >
                {rolesList &&
                  rolesList.map((role) => (
                    <MenuItem key={role.roleId} value={role.roleId}>
                      <ListItemText primary={role.role} />
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText errorStyle={{ position: "absolute" }}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                 { formik.touched.role &&  formik.errors.role ? formik.errors.role : " "}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box
            display="flex"
            sx={{ marginBottom: "1%" }}
            justifyContent="center"
          >
            <FormControl
              size="small"
              sx={{
                marginRight: "2%",
                flex: 1,
                width: 470,
                fontSize: "0.837rem",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {t("plant_request.plant_request_form.department") +
                  " *  \u00A0       \u00A0"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                size="small"
                inputProps={{ fontSize: "0.875rem" }}
                name="dept"
                value={selectedDepartments}
                onChange={(event) => {
                  const { value } = event.target;
                  setSelectedDepartments(value);
                  formik.setFieldValue("dept", event.target.value);
                }}
                label={
                  t("plant_request.plant_request_form.department") +
                  "*  \u00A0       \u00A0"
                }
                error={formik.touched.dept && Boolean(formik.errors.dept)}
                // input={<OutlinedInput label="Department" />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (deptId) =>
                        departmentsList.find((e) => e.departmentId === deptId)
                          ?.department
                    )
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {departmentsList &&
                  departmentsList.map((department) => (
                    <MenuItem
                      key={department.departmentId}
                      value={department.departmentId}
                    >
                      <Checkbox
                        checked={
                          selectedDepartments.indexOf(department.departmentId) >
                          -1
                        }
                      />
                      <ListItemText primary={department.department} />
                    </MenuItem>
                  ))}
              </Select>
              {/* {formik.touched.dept && formik.errors.dept ? (
                <FormHelperText error={formik.errors.dept}>{formik.errors.dept}</FormHelperText>
              ) : null} */}
              {/* <FormHelperText
                error={formik.touched.dept && Boolean(formik.errors.dept)}
              >
                {formik.touched.dept && formik.errors.dept}
              </FormHelperText> */}
              <FormHelperText style={{margin:'0'}} errorStyle={{ position: "absolute" }} error={selectedDepartments.length === 0}>
                {selectedDepartments.length === 0 ? selectedDepartments.length === 0 : ' '}
              </FormHelperText>
            </FormControl>
            <TextField
              errorStyle={{ position: "absolute" }}
              sx={{
                flex: 1,
                marginRight: "2%",
                fontSize: "0.875rem",
              }}
              size="small"
              name="emailId"
              variant="outlined"
              type="email"
              label={t("plant_request.plant_request_form.email") + "*  \u00A0"}
              value={formik.values.emailId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.emailId && Boolean(formik.errors.emailId)}
              helperText={<FormHelperText style={{margin:'0'}}>{formik.touched.emailId && formik.errors.emailId ? formik.errors.emailId : " "}</FormHelperText> }
            />
          </Box>
          <Box
            display="flex"
            sx={{ marginBottom: "1%" }}
            justifyContent="flex-start"
          >
            <TextField
              errorStyle={{ position: "absolute" }}
              size="small"
              variant="outlined"
              key="supervisorEmailId"
              sx={{ width: "48%", marginRight: "2%", fontSize: "0.875rem" }}
              name="supervisorEmailId"
              type="email"
              // required
              label={
                t("plant_request.plant_request_form.supervisorEmail") +
                " *   \u00A0 \u00A0"
              }
              value={formik.values.supervisorEmailId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.supervisorEmailId &&
                Boolean(formik.errors.supervisorEmailId)
              }
              helperText={<FormHelperText style={{margin:'0'}}>{ formik.touched.supervisorEmailId && formik.errors.supervisorEmailId ? formik.errors.supervisorEmailId : " "}</FormHelperText> }
            />

            <TextField
              errorStyle={{ position: "absolute" }}
              variant="outlined"
              sx={{
                flex: 1,
                marginRight: "2%",
                justifyContent: "center",
                fontSize: "0.875rem",
              }}
              size="small"
              // required
              name="employeeId"
              type="text"
              label={
                " " +
                `${t("plant_request.plant_request_form.employeeId")}` +
                "*  \u00A0"
              }
              value={formik.values.employeeId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.employeeId && Boolean(formik.errors.employeeId)
              }
              helperText={<FormHelperText style={{margin:'0'}}>{formik.touched.employeeId && formik.errors.employeeId ? formik.errors.employeeId : " "}</FormHelperText> }
              inputProps={{
                maxLength: 10,
              }}
            />
          </Box>
          <Box component="form" noValidate autoComplete="off" style={{marginRight:'2%'}}>
            <TextField
              errorStyle={{ position: "absolute" }}
              key="additionalInfo"
              size="small"
              id="outlined-multiline-static"
              multiline
              rows={4}
              name="additionalInfo"
              fullWidth
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{ shrink: true, width: "100%" }}
              label={
                t("plant_request.plant_request_form.additionaInfo") +
                " \u00A0  \u00A0"
              }
              value={formik.values.additionalInfo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.additionalInfo &&
                Boolean(formik.errors.additionalInfo)
              }
              helperText={<FormHelperText style={{margin:'0'}}>{formik.touched.additionalInfo &&  formik.errors.additionalInfo ? formik.errors.additionalInfo : " "}</FormHelperText> }
              defaultValue="Default Value"
              sx={{
                ".MuiInputBase-root": {
                  height: "inherit",
                },
              }}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ marginTop: "15%",marginRight:'2%' }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "20%",
                textTransform: "none",
                color: "#A6A7A9",
                borderColor: "#A6A7A9",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              {t("plant_request.plant_request_form.back")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "20%", textTransform: "none" }}
              color="primary"
              disabled={
                formik.errors.firstname || 
                formik.errors.lastname || 
                formik.errors.emailId || 
                formik.errors.supervisorEmailId || 
                formik.errors.employeeId || 
                formik.errors.dept || 
                formik.errors.role || 
                formik.errors.plantName || 
                selectedDepartments.length === 0}
              onClick={() => {
                if (formik.values !== formik.initialValues) {
                  // console.log(formik.values);
                  const {
                    firstname,
                    lastname,
                    emailId,
                    supervisorEmailId,
                    additionalInfo,
                    dept,
                    employeeId,
                  } = formik.values;
                  const data = {
                    userFirstName: formik.values.firstname,
                    userLastName: formik.values.lastname,
                    userEmailId: formik.values.emailId,
                    superviseorEmailId: formik.values.supervisorEmailId,
                    additionalInformation: formik.values.additionalInfo,
                    status: "Pending",
                    plantId: selectedPlant,
                    role: selectedRoles,
                    deptId: selectedDepartments.join(","),
                    employeeId,
                  };
                  if (
                    !(
                      firstname &&
                      lastname &&
                      emailId &&
                      supervisorEmailId &&
                      // additionalInfo &&
                      selectedPlant &&
                      selectedRoles &&
                      selectedDepartments &&
                      selectedDepartments.length > 0 &&
                      employeeId
                    )
                  ) {
                    return;
                  }
                  dispatch(addAccessRequestDetails(data)).then((resp) => {
                    setShowResponseMessage(true);
                    setSaveLoading(false);
                    formik.resetForm();
                    setSelectedPlant("");
                    setSelectedRoles("");
                    setSelectedDepartments([]);
                  });
                }
              }}
            >
              {t("plant_request.plant_request_form.request")}
              {/* {saveLoading ? <CircularProgress size={10} color="secondary" /> : "Request"} */}
            </Button>
          </Box>
          {/* Snackbar for api success, error response */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Notification
              open={showResponseMessage}
              onClose={() => setShowResponseMessage(false)}
              severity={plantRequestState.severity}
              message={plantRequestState.message}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default withTranslation()(PlantRequest);
