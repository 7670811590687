import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoading: false,
    message: '',
};

export const changeLanguage = createAsyncThunk('changeLanguage', async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}local?lang=${params}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  });

const changeLanguageSlice = createSlice({
    initialState,
    name: 'languageState',
    reducers: {},
    extraReducers: {
        [changeLanguage.pending]: (state) => {
            state.isLoading = true;
        },
        [changeLanguage.fulfilled]: (state, action) => {
            state.message = action.payload;
            state.isLoading = false;
        },
        [changeLanguage.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        }
    }
});
export default changeLanguageSlice.reducer;