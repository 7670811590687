export const SCRAP_DETAILS_COLUMNS = [
  {
    id: "checkbox",
    label: "",
    translationKey: "",
    width: "5%",
  },
  {
    id: "machine",
    label: "Machine",
    translationKey: "machine_list.scrap_list_table.machine",
    width: "10%",
  },
  {
    id: "workorder",
    label: "Workorder",
    translationKey: "machine_list.scrap_list_table.workorder",
    width: "15%",
  },
  {
    id: "scrapQuantity",
    label: "",
    translationKey: "machine_list.scrap_list_table.scrapQuantity",
    width: "21%",
  },
  {
    id: "uom",
    label: "",
    translationKey: "machine_list.scrap_list_table.uom",
    width: "10%",
  },
  {
    id: "scrapItem",
    label: "",
    translationKey: "machine_list.scrap_list_table.scrapItem",
    width: "21%",
  },
  { 
    id: "duplicate",
    label: "",
    translationKey: "machine_list.scrap_list_table.action",
    width: "5%",
  },
];

export const ROW_PER_PAGE_INITIAL_COUNT = 10;
export const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];

export const allowedDecimalValues = /^\d*\.?\d*$/;
export const allowedWholeNumbers = /^[0-9]*$/;
export const NumbersNotStartWithZero = /^(?!0)\d*$/; // value should not start with 0 but in between 0 is allowed
