
export const MACHINE_LIST = 'Machine List';
export const PRODUCTION_DETAILS = 'Production details';
export const DOWNTIME_DETAILS = 'Downtime details';

export const FILTER_BY_MACHINES = 'Filter by machines';
export const SEARCH_BY_MACHINES = 'Search by machines';

export const MACHINE_LIST_COLUMNS = [
  // {
  //   id: 'sno',
  //   label: 'SNO',
  //   translationKey: 'machine_list.machine_list_table.sno'
  // },
  {
    id: 'machine',
    label: 'Machine',
    translationKey: 'machine_list.machine_list_table.machine',
    width: '10%'
  },
  {
    id: 'workorder',
    label: 'Workorder',
    translationKey: 'machine_list.machine_list_table.workorder',
    width: '10%'
  },
  {
    id: 'item',
    label: 'Item',
    translationKey: 'machine_list.machine_list_table.item',
    width: '10%'
  },
  {
    id: 'runtime',
    label: 'Runtime',
    translationKey: 'machine_list.machine_list_table.runtime',
    width: '10%'
  },
  {
    id: 'downtime',
    label: 'Downtime',
    translationKey: 'machine_list.machine_list_table.downtime',
    width: '10%'
  },
  {
    id: 'productionQuantity',
    label: 'Production Quantity',
    translationKey: 'machine_list.machine_list_table.productionQuantity',
    width: '12%'
  },
  {
    id: 'uom',
    label: 'UOM',
    translationKey: 'machine_list.machine_list_table.uom',
    width: '8%'
  },
  {
    id: 'scrapQuantity',
    label: 'Scrap Quantity',
    translationKey: 'machine_list.machine_list_table.scrapQuantity',
    width: '10%'
  },
  // {
  //   id: 'notScheduled',
  //   label: 'Not Scheduled (hrs)',
  //   translationKey: 'machine_list.machine_list_table.notScheduled'
  // },
  {
    id: 'status',
    label: 'Status',
    translationKey: 'machine_list.machine_list_table.status',
    width: '10%'
  },
];

export const MACHINE_LIST_ROWS = [
  {
    id: 1,
    machine: 'FEX407001',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '4',
    status: 'good',
  },
  {
    id: 2,
    machine: 'FEX407001',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '2',
    status: 'error',
  },
  {
    id: 3,
    machine: 'FEX407002',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '4',
    status: 'empty',
  },
  {
    id: 4,
    machine: 'FEX407002',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'error',
  },
  {
    id: 5,
    machine: 'FEX407003',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '2',
    status: 'good',
  },
  {
    id: 6,
    machine: 'FEX407003',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'empty',
  },
  {
    id: 7,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 8,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 9,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 10,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 11,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 12,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 13,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 14,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 15,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
  {
    id: 16,
    machine: 'FEX407004',
    workorder: '81349037',
    item: '1458902',
    runtime: '4',
    downtime: '2',
    production: '56003',
    notScheduled: '3',
    status: 'good',
  },
];

export const STATUS_POPOVER_TEXT = 'Status';
export const GOOD_POPOVER_TEXT = 'Good: Valid Hours';
export const ERROR_POPOVER_TEXT = 'Error: Invalid Hours';
export const EMPTY_POPOVER_TEXT = 'Empty: No hours entered';

export const ROW_PER_PAGE_INITIAL_COUNT = 10;
export const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];