import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoading: false,
    plantDetails: [],
    message: '',
}
export const getPlantData = createAsyncThunk('getPlantDetails', async (plantId, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}plant/${plantId}`;
    try{
        const resp = await axios.get(url);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue('Failed get Machine list');
    }
});

export const PlantSlice = createSlice({
    initialState,
    name: 'plantState',
    reducers: {

    },
    extraReducers: {
        [getPlantData.pending]: (state) => {
            state.isLoading = true;
        },
        [getPlantData.fulfilled]: (state, action) => {
            state.message = "success";
            state.plantDetails = action.payload;
            state.isLoading = false;
        },
        [getPlantData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        }
    }
});

export default PlantSlice.reducer;