import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useTheme,
  CircularProgress,
} from "@mui/material";

const RejectRequestModal = ({
  open,
  handleClose,
  title,
  descriptionText,
  noButtonText,
  yesButtonText,
  onNoClick,
  onYesClick,
  requestsLoading
}) => {
  const theme = useTheme();
  const buttonStyles = {
    textTransform: "uppercase",
    fontWeight: "500",
    height: 32,
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        variant="h5"
        fontWeight="500"
        lineHeight="2rem"
        fontSize="1.25rem"
        color={theme.palette.common.black}
        sx={{ pb: 0 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <DialogContentText
          sx={{
            mt: 1,
            color: theme.palette.common.black,
            fontSize: "0.875rem",
          }}
        >
          {descriptionText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onNoClick} sx={buttonStyles}>
          {noButtonText}
        </Button>
        <Button variant="contained" onClick={onYesClick} sx={buttonStyles}>
        {requestsLoading ? (
            <CircularProgress size={10} color="secondary" />
          ) : (
            yesButtonText
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectRequestModal;
