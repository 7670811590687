export const NEW_REQUEST_TABLE_COLUMNS = [
    {
      id: "plantName",
      translationKey: "shiftConfiguration.details_table.plantName",
      width: 300,
    },
    {
      id: "departmentName",
      translationKey: "shiftConfiguration.details_table.departmentName",
      width: 300,
    },
    {
      id: "timeZone",
      translationKey: "shiftConfiguration.details_table.timezone",
      width: 300,
    },
    {
      id: "dataStateChange",
      translationKey: "shiftConfiguration.details_table.scheduleJobTime",
      width: 300,
    },
    {
      id: "shift",
      translationKey: "shiftConfiguration.details_table.plantShift",
      width: 300,
    },
    {
      id: "shiftHrs",
      translationKey: "shiftConfiguration.details_table.shiftHrs",
      width: 300,
    },
    {
      id: "shiftStartTime",
      translationKey: "shiftConfiguration.details_table.shiftStartTime",
      width: 300,
    },
 
    {
      id: "action",
      translationKey: "shiftConfiguration.details_table.action",
      width: 400,
    },
  ];
  
  export const ROW_PER_PAGE_INITIAL_COUNT = 50;
  export const ROWS_PER_PAGE_OPTIONS = [10,20,50, 100, 200];