const { createTheme } = require("@mui/material");

export const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
        body: {
          minHeight: "2.25rem",
          maxHeight: "2.25rem",
          height: "2.25rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "2.3rem",
          fontSize: "0.875rem",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 42,
          minHeight: 42,
          maxHeight: 42,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 42,
          minHeight: 42,
          maxHeight: 42,
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: 42,
          minHeight: 42,
          maxHeight: 42,
          fontWeight: 600,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#004a98",
      light: "#004A9833",
    },
    secondary: {
      main: "#f5f6f8",
    },
    error: {
      main: "#ee2225",
      contrastText: "#fff",
    },
    warning: {
      main: "#f26722",
      light: "#ffb23f",
    },
    success: {
      main: "#078241",
    },
    text: {
      primary: '#404040',
      secondary: "#A6A7A9",
    },
    action: {
      hover: "#EDF2F8",
    },
    custom: {
      darkerText: "#404040",
      lightOrange: '#F26722'
    },
  },
});
