import { Box, Button, Card, CardContent, Typography } from "@mui/material";

const CenteredCard = ({ mainText, subText, buttonText, onClick, styles }) => (
  <Card
    sx={{
      borderRadius: "0.625rem",
      border: "1px solid #7213EA",
      opacity: 0.9,
      background: "#FFF",
      boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
      ...styles
    }}
  >
    <CardContent>
      <Box
        sx={{
          display: "flex",
          width: "33.125rem",
          height: "19.25rem",
          padding: "3.125rem 2rem",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1.75rem",
          flexShrink: 0,
          backgroundColor: "",
          textAlign: "center",
        }}
      >
        <Typography
          color="primary"
          variant="h5"
          component="div"
          sx={{
            fontWeight: 600,
          }}
        >
          {mainText}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#2D3E50", fontSize: "0.875rem", fontWeight: 400 }}
        >
          {subText}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "13.8125rem",
            height: "3.4375rem",
            padding: "0.25rem",
            borderRadius: "0.625rem",
            fontWeight: 500,
          }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Box>
    </CardContent>
  </Card>
);

export default CenteredCard;
