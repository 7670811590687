import axios from "axios";
import { authProvider } from "./authProvider";
// const axiosInstance = axios.create({
//   baseURL: berryConfig.URL,
// });

export const setTokenInAxios = (token) => {
    axios.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          config.headers['Cache-Control'] = 'no-cache';
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if(error.response.status === 401){
    authProvider.logout();
  }
  // Do something with response error
  return Promise.reject(error);
});