export const RUNTIME_DETAILS_COLUMNS = [
  {
    id: "checkbox",
    label: "",
    translationKey: ""
  },
  // {
  //   id: "id",
  //   label: "SNO",
  //   translationKey: "machine_list.production_list_table.sno"
  // },
  {
    id: "machine",
    label: "Machine",
    translationKey: 'machine_list.production_list_table.machine'
  },
  {
    id: "workorder",
    label: "Work order",
    translationKey: 'machine_list.production_list_table.workorder'
  },
  {
    id: "item",
    label: "Item",
    translationKey: 'machine_list.production_list_table.item'
  },
  {
    id: "runtime",
    label: "Runtime",
    translationKey: 'machine_list.production_list_table.runtime',
    align: "center",
  },
  {
    id: "productionQuantity",
    label: "Production Quantity",
    translationKey: 'machine_list.production_list_table.productionQuantity',
    align: "center",
  },
  {
    id: "uom",
    label: "UOM",
    translationKey: 'machine_list.production_list_table.uom',
    align: "center",
  },
  { 
    id: "duplicate",
    label: "",
    translationKey: "machine_list.production_list_table.action",
  },
];

export const RUNTIME_DETAILS_ROWS = [
  { id: 1, machine: "FEX407001", workorder: "456789", item:"76534", runtime: "0" },
  { id: 2, machine: "FEX407002", workorder: "456789", item:"75234", runtime: "0" },
  { id: 3, machine: "FEX407003", workorder: "456789", item:"76934", runtime: "0" },
  { id: 4, machine: "FEX407004", workorder: "456789", item:"76234", runtime: "0" },
  { id: 5, machine: "FEX407005", workorder: "456789", item:"76734", runtime: "0" },
];

export const ROW_PER_PAGE_INITIAL_COUNT = 10;
export const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];