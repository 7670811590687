export const DOWNTIME_DETAILS_COLUMNS = [
  {
    id: "checkbox",
    label: "",
    translationKey: "",
    width: "5%",
  },
  // {
  //   id: "id",
  //   label: "SNO",
  //   translationKey: "machine_list.downtime_list_table.sno",
  // },
  {
    id: "machine",
    label: "Machine",
    translationKey: "machine_list.downtime_list_table.machine",
    width: "10%",
  },
  {
    id: "workorder",
    label: "Work order",
    translationKey: "machine_list.downtime_list_table.workorder",
    width: "15%",
  },
  {
    id: "time",
    label: "Time (hrs)",
    translationKey: "machine_list.downtime_list_table.time",
    width: "15%",
  },
  {
    id: "reason",
    label: "Reason",
    translationKey: "machine_list.downtime_list_table.reason",
    width: "15%",
  },
  {
    id: "subreason",
    label: "Sub-reason",
    translationKey: "machine_list.downtime_list_table.subreason",
    width: "15%",
  },
  {
    id: "remarks",
    label: "Remarks",
    translationKey: "machine_list.downtime_list_table.remarks",
    width: "15%",
  },
  { 
    id: "duplicate",
    label: "",
    translationKey: "machine_list.downtime_list_table.action",
    width: "5%",
  },
];

export const ROW_PER_PAGE_INITIAL_COUNT = 10;
export const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];
