import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const TOTAL_SHIFT_CONSTANT = 4;
const initialState = {
  isLoading: false,
  staticShifts: [
    {
      shiftId: 0,
      shift: 1,
      shiftHrs: 0,
      // deptPlantId: item && item.deptPlantMappingId,
      shiftStartTime: "",
    },
    {
      shiftId: 0,
      shift: 2,
      shiftHrs: 0,
      // deptPlantId: item && item.deptPlantMappingId,
      shiftStartTime: "",
    },
    {
      shiftId: 0,
      shift: 3,
      shiftHrs: 0,
      // deptPlantId: item && item.deptPlantMappingId,
      shiftStartTime: "",
    },
    {
      shiftId: 0,
      shift: 4,
      shiftHrs: 0,
      // deptPlantId: item && item.deptPlantMappingId,
      shiftStartTime: "",
    },
  ],
  message: "",
  statusText: "",
  severity: "",
  scheduleJobTime: [],
  shift_hrs_data: Array.from({ length: 25 }, (_, i) => i),
  shift_startTime_data: [],
  items: [],
};
for (let i = 0; i <= 24; i += 0.5) {
  let hours = Math.floor(i);
  let minutes = (i - hours) * 60;
  let timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:00`;
  initialState.scheduleJobTime.push(timeString);
}

for (let i = 0; i <= 24; i += 0.5) {
  let hours = Math.floor(i);
  let minutes = (i - hours) * 60;
  let timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:00`;
  initialState.shift_startTime_data.push(timeString);
}

/*
  Get shifts details API

*/
export const getShiftConfigurationDetails = createAsyncThunk(
  "getShiftConfigurationDetails",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}shift-config`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        "Failed to get shift configuration details"
      );
    }
  }
);

/**
 * Save Shift COnfiguration Changes Details
 */

/*
  Get shifts details API

*/
export const deleteShiftConfiguration = createAsyncThunk(
  "deleteShiftConfiguration",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }shift-config/deletes/${params}`;
    try {
      const resp = await axios.delete(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to delete shift");
    }
  }
);
export const updateShiftConfigurations = createAsyncThunk(
  "updateShiftConfigurations",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }shift-config/updates`;
    try {
      const resp = await axios.put(url, params);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Failed to update the shift configuration details"
      );
    }
  }
);
export const ShiftConfigurationSlice = createSlice({
  initialState,
  name: "shiftConfigurationState",
  reducers: {
    // initializeShift: (state, action) => {
    //   state.items.map((item) => {
    //     const iterateValue =
    //       TOTAL_SHIFT_CONSTANT - item.shiftData && item.shiftData.length;
    //     for (let i = 0; i < iterateValue; i++) {
    //       item.shiftData.push({
    //         shiftId: 0,
    //         shift: `${item.shiftData && item.shiftData.length + 1}`,
    //         shiftHrs: 0,
    //         shiftStartTime: "0",
    //         addedShift: true,
    //       });
    //     }
    //   });
    // },
    deleteShift: (state, action) => {
      state.items = state.items.filter(
        (shift) => shift.plantMappingIDDept !== action.payload
      );
    },
    updateShift: (state, action) => {
      const rowId = action.payload.rowId;
      const selectedShiftIndex = action.payload.shiftIndex;
      const item = state.items.find(
        (item) => item.deptPlantMappingId === rowId
      );
      if (item) {
        item.selectedShiftIndex = selectedShiftIndex;
      }
      // reset logic
      // if (item) {
      //   let prevShiftIndex = item.selectedShiftIndex;
      //   item.selectedShiftIndex = selectedShiftIndex;
      //   if (item.isDataChanged) {
      //     const prevData = item.backupShiftData;
      //     item.shiftData[prevShiftIndex] = prevData[prevShiftIndex];
      //     if (item.previousScheduleJobTime !== item.dataStateChange) {
      //       item.isDataChanged = true;
      //     } else {
      //       item.isDataChanged = false;
      //     }
      //   }
      // }
    },
    updateShiftHrs: (state, action) => {
      const plantMappingIDDept = action.payload.plantMappingIDDept;
      const newData = action.payload.updatedShiftsHrs;
      const message = action.payload.message;
      state.items.map((item) => {
        if (item.deptPlantMappingId === plantMappingIDDept) {
          item.shiftData = newData;
          item.isDataChanged = true;
        }
      });
    },
    updateShiftHrsStartTime: (state, action) => {
      const plantMappingIDDept = action.payload.plantMappingIDDept;
      const newData = action.payload.updatedShiftsHrsStartTime;
      state.items.map((item) => {
        if (item.deptPlantMappingId === plantMappingIDDept) {
          item.shiftData = newData;
          item.isDataChanged = true;
        }
      });
    },
    updateScheduleJobTime: (state, action) => {
      const plantMappingIDDept = action.payload.plantMappingIDDept;
      const newScheduledJobTime = action.payload.newScheduledJobTime;
      state.items &&
        state.items.map((row) => {
          if (row.deptPlantMappingId === plantMappingIDDept) {
            row.previousScheduleJobTime = row.dataStateChange;
            row.dataStateChange = newScheduledJobTime;
            row.isDataChanged = true;
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShiftConfigurationDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftConfigurationDetails.fulfilled, (state, action) => {
        state.items = action.payload;
        state.items.map((item) => {
          item.selectedShiftIndex = 0;
          item.isDataChanged = false;
          // const staticArray = [
          //   {
          //     shiftId: 0,
          //     shift: 1,
          //     shiftHrs: 0,
          //     shiftStartTime: "0",
          //     deptPlantId: item && item.deptPlantMappingId,
          //   },
          //   {
          //     shiftId: 0,
          //     shift: 2,
          //     shiftHrs: 0,
          //     shiftStartTime: "0",
          //     deptPlantId: item && item.deptPlantMappingId,
          //   },
          //   {
          //     shiftId: 0,
          //     shift: 3,
          //     shiftHrs: 0,
          //     shiftStartTime: "0",
          //     deptPlantId: item && item.deptPlantMappingId,
          //   },
          //   {
          //     shiftId: 0,
          //     shift: 4,
          //     shiftHrs: 0,
          //     shiftStartTime: "0",
          //     deptPlantId: item && item.deptPlantMappingId,
          //   },
          // ];
          // const shiftArray = item.shiftData;
          // staticArray.forEach((staticShifts) => {
          //   if (
          //     !shiftArray.find((shift) => shift.shift === staticShifts.shift)
          //   ) {
          //     shiftArray.push(staticShifts);
          //   }
          // });
          const sampleSortedArray =
            item.shiftData && item.shiftData.sort((a, b) => a.shift - b.shift);
          item.shiftData = sampleSortedArray;
          // item.backupShiftData = item.shiftData;
        });
        state.statusText = "success";
        state.severity = "success";
        state.isLoading = false;
      })

      .addCase(getShiftConfigurationDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "failed";
        state.severity = "error";
      })
      .addCase(updateShiftConfigurations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateShiftConfigurations.fulfilled, (state, action) => {
        state.message = action.payload.statusMessage;
        const updatedData = action.payload.shiftConfigDTO;
        const updatedItems = state.items.map((item) => {
          if (item) {
            if (
              item.dataStateChange !== updatedData.dataStateChange &&
              item.plantName === updatedData.plantName
            ) {
              if (item.deptPlantMappingId === updatedData.deptPlantMappingId) {
                item = updatedData;
                item.isDataChanged = false;
                item.selectedShiftIndex = 0;
              } else {
                item.dataStateChange = updatedData.dataStateChange;
                item.isDataChanged = false;
                item.selectedShiftIndex = 0;
              }
            }
            if (item.deptPlantMappingId === updatedData.deptPlantMappingId) {
              item = updatedData;
              item.selectedShiftIndex = 0;
              item.isDataChanged = false;
            }
            item.isDirty = true;
          }
          return item;
        });
        state.isLoading = false;
        state.statusText = "success";
        state.severity = "success";
        state.items = updatedItems;
      })
      .addCase(updateShiftConfigurations.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
        state.statusText = "failed";
        state.severity = "error";
      })
      .addCase(deleteShiftConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteShiftConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.isLoading = false;
        state.statusText = "success";
        state.severity = "success";
      })

      .addCase(deleteShiftConfiguration.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "failed";
        state.severity = "error";
      });
  },
});
// export const { initializeShift } = ShiftConfigurationSlice.actions;
export const {
  deleteShift,
  updateShift,
  initializeShift,
  updateShiftHrs,
  updateShiftHrsStartTime,
  updateScheduleJobTime,
} = ShiftConfigurationSlice.actions;
export default ShiftConfigurationSlice.reducer;
