import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {ALL_CREWS} from '../constants/HomeScreen';

const initialState = {
  isLoading: false,
  items: [],
  message: '',
};
export const getMachineData = createAsyncThunk('getMachineList', async (params, thunkAPI) => {
    const { deptPlantId, date, shift, crew } = params;
    let url = (crew.length > 0 && crew !== ALL_CREWS)
      ? `${JSON.parse(localStorage.berryConfig).URL}machines/${deptPlantId}/${shift}/${date}/${crew}`
      : `${JSON.parse(localStorage.berryConfig).URL}machines/${deptPlantId}/${shift}/${date}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get Machine list");
    }
  }
);

export const MachineSlice = createSlice({
    initialState,
    name: 'machineListState',
    reducers: {

    },
    extraReducers: {
        [getMachineData.pending]: (state) => {
            state.isLoading = true;
        },
        [getMachineData.fulfilled]: (state, action) => {
            state.message = "success";
            state.items = action.payload;
            state.isLoading = false;
        },
        [getMachineData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        }
    }
});

export default MachineSlice.reducer;