import {
  AppBar,
  Box,
  Toolbar,
} from "@mui/material";
import BerryLogo from "../../assets/png/Berry Logo.png";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { LanguageChange } from "./Header";

const LandingPageHeader = ({}) => {

  return (
    <AppBar color="secondary">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: "0.938rem",
        }}
        variant="dense"
        disableGutters
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            sx={{ height: 40, width: 65, mr: 1 }}
            alt="berry global logo"
            src={BerryLogo}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <LanguageIcon sx={{ color: theme.palette.primary.main }} />
          <Typography
            sx={{
              ml: 1,
              cursor: "pointer",
              display: "flex",
              borderRight: `1px solid ${theme.palette.text.secondary}`,
              alignItems: "center",
              paddingRight: "0.938rem",
              color: theme.palette.text.primary,
              ...fontSizeStyle,
            }}
            onClick={handleLanguageChange}
          >
            {language.toUpperCase()}
            {Boolean(anchorLanguage) ? <ArrowDropUp /> : <ArrowDropDown />}
          </Typography>
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorLanguage}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorLanguage)}
            onClose={closeLanguageChoose}
            autoFocus={false}
          >
            {languageList.map((lang, index) => {
              return (
                <MenuItem
                  data-lang={lang}
                  key={index}
                  onClick={closeLanguageChoose}
                >
                  <Typography>{lang.toUpperCase()}</Typography>
                </MenuItem>
              );
            })}
          </Menu> */}
          <LanguageChange />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default withTranslation()(LandingPageHeader);
