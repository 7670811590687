import { configureStore } from "@reduxjs/toolkit";
import UserReduer from "../reducers/UserSlice";
import MachineReducer from "../reducers/MachineSlice";
import PlantReducer from "../reducers/PlantSlice";
import ChangeLanguageSlice from "../reducers/ChangeLanguageSlice";
import RunTimeReducer from "../reducers/RuntimeDetailSlice";
import DownTimeReducer from "../reducers/DowntimeDetailSlice";
import NotScheduledDetailsSlice from "../reducers/NotScheduledDetailsSlice";
import CurrentEntriesSlice from "../reducers/CurrentEntriesReportsSlice";
import DowntimeReportsSlice from "../reducers/DowntimeReportsSlice";
import ScrapDetailsSlice from "../reducers/ScrapDetailsSlice";
import DepartmentSlice from "../reducers/DepartmentSlice";
import currentUserRoleSlice  from "../reducers/CurrentUserRoleSlice";
import PlantRequestSlice from "../reducers/PlantRequestSlice";
import AccessRequestSlice from "../reducers/AccessRequestSlice";
import ShiftConfigurationSlice from "../reducers/ShiftConfigurationSlice";
import ManagePermissionsSlice from "../reducers/ManagePermissionsSlice";
import AddNewPlantDepartmentSlice from "../reducers/AddNewPlantDepartmentSlice";

export const store = configureStore({
  reducer: {
    userState: UserReduer,
    plantState: PlantReducer,
    machineListState: MachineReducer,
    languageState: ChangeLanguageSlice,
    runtimeState: RunTimeReducer,
    downtimeState: DownTimeReducer,
    notScheduledState: NotScheduledDetailsSlice,
    currentEntriesState: CurrentEntriesSlice,
    downtimeReportsState: DowntimeReportsSlice,
    scrapDetailsState: ScrapDetailsSlice,
    departments: DepartmentSlice,
    currentUserRole: currentUserRoleSlice,
    plantRequestState : PlantRequestSlice,
    accessRequestState : AccessRequestSlice,
    shiftConfigurationState : ShiftConfigurationSlice,
    managePermissionsState: ManagePermissionsSlice,
    addNewPlantDepartmentState: AddNewPlantDepartmentSlice
  },
});
