import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const devConfig = {
  authority: 'https://login.microsoftonline.com/7deec3ae-9b4d-4b7e-ab17-6a1c54c51e8f',
  clientId: '7196723f-07ac-4064-b825-5b8263b2aadd',
  redirectUri: 'https://aomcui.azurewebsites.net/home',
  postLogoutRedirectUri: 'https://aomcui.azurewebsites.net/',


};

const prodConfig = {
  authority: 'https://login.microsoftonline.com/7deec3ae-9b4d-4b7e-ab17-6a1c54c51e8f',
  clientId: 'c4f11c5f-455a-405d-8884-300b0e048a79',
  redirectUri: 'https://aomcentry.berryglobal.com/home',
  postLogoutRedirectUri: 'https://aomcentry.berryglobal.com/',
};

// Msal Configurations
const config = {
  auth: process.env.NODE_ENV === "production" ? prodConfig : devConfig,
  cache: {
    storeAuthStateInCookie: true,
    secureCookies: true,
    // cacheLocation: "localStorage",
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options) 
// //to restrict user to open multiple tabs
// // Create a unique session identifier
// const sessionID = Math.random().toString(36).substring(2, 15);

// // Store the session identifier in local storage
// localStorage.setItem("sessionID", sessionID);

// // Set up an event listener for local storage changes
// window.addEventListener("storage", (event) => {
//   if (event.key === "sessionID" && event.newValue && event.newValue !== sessionID ) {
//     // If the session identifier changes, log the user out
//     authProvider.logout();
//   }
// });


//  const authProvider = new MsalAuthProvider(config, authenticationParameters, options)

//  authProvider.handleRedirectCallback((error, response) => {
//   if (response) {
//     // When a new tab is opened and a login is successful, change the session identifier in local storage
//     localStorage.setItem("sessionID", Math.random().toString(36).substring(2, 15));
//   }
// });
//  export  {authProvider};