import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ALL_CREWS } from "../constants/HomeScreen";

const initialState = {
  isLoading: false,
  items: [],
  message: '',
  statusText: '',
  severity:'',
};

export const getNotScheduledData = createAsyncThunk(
  "getNotScheduledData",
  async (params, thunkAPI) => {
    const newParams = {
      ...params,
      crew: params.crew === ALL_CREWS ? null : params.crew,
    };
    const url = `${JSON.parse(localStorage.berryConfig).URL}notscheduled`;
    try {
      const resp = await axios.post(url, newParams);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Failed to get not scheduled details list"
      );
    }
  }
);

export const addNotScheduledData = createAsyncThunk(
  "addNotScheduledData",
  async (params, thunkAPI) => {
    const newParams = {
      ...params,
      crew: params.crew === ALL_CREWS ? null : params.crew,
    };
    const url = `${JSON.parse(localStorage.berryConfig).URL}notscheduled/add`;
    try {
      const resp = await axios.post(url, newParams);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Failed to add not scheduled details data"
      );
    }
  }
);

export const deleteNotScheduledData = createAsyncThunk(
  "deleteNotScheduledData",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }notscheduled/delete`;

    try {
      const resp = await axios.delete(url, { data: params });
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Failed to delete not scheduled details data"
      );
    }
  }
);

export const NotScheduledDetailSlice = createSlice({
  initialState,
  name: "notScheduledDetailsState",
  reducers: {},
  extraReducers: {
    [getNotScheduledData.pending]: (state) => {
      state.isLoading = true;
    },
    [getNotScheduledData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
    [getNotScheduledData.rejected]: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },

    [addNotScheduledData.pending]: (state) => {
      state.isLoading = true;
    },
    [addNotScheduledData.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.isLoading = false;
      state.statusText = 'success';
      state.severity = 'success';
    },
    [addNotScheduledData.rejected]: (state, action) => {
      state.message = action.payload.message;
      state.isLoading = false;
      state.statusText = 'failed';
      state.severity = 'error';
    },

    [deleteNotScheduledData.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteNotScheduledData.fulfilled]: (state, action) => {
      state.message = action.payload.data.message;
      state.isLoading = false;
      state.statusText = 'success';
      state.severity = 'success';
    },
    [deleteNotScheduledData.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      state.isLoading = false;
      state.statusText = 'failed';
      state.severity = 'error';
    },
  },
});

export default NotScheduledDetailSlice.reducer;
