import { useTheme } from "@emotion/react";
import { Info } from "@mui/icons-material";
import {
  Typography,
  IconButton,
  styled,
  ClickAwayListener,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useState } from "react";
import {
  STATUS_POPOVER_TEXT,
  GOOD_POPOVER_TEXT,
  ERROR_POPOVER_TEXT,
  EMPTY_POPOVER_TEXT,
} from "../../constants/MachineList";
import { withTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[3],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const InnerContent = ({ t }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "12px",
        gap: "5px",
        width: "185px",
        height: "120px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {t("machine_list.machine_list_table.status_popover.status_label")}
      </Typography>
      <div>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: theme.palette.success.main,
          }}
        >
          {t("machine_list.machine_list_table.status_popover.good")}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: theme.palette.error.main,
          }}
        >
          {t("machine_list.machine_list_table.status_popover.error")}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: theme.palette.warning.light,
          }}
        >
          {t("machine_list.machine_list_table.status_popover.empty")}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: theme.palette.custom.lightOrange,
          }}
        >
          {t("machine_list.machine_list_table.status_popover.not_scheduled")}
        </Typography>
      </div>
    </div>
  );
};

const StatusPopover = ({ t }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {t("machine_list.machine_list_table.status")}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={<InnerContent t={t} />}
          >
            <IconButton onClick={handleTooltipOpen}>
              <Info
                sx={{ fontSize: "15px", color: theme.palette.primary.main }}
              />
            </IconButton>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default withTranslation()(StatusPopover);