import React, { useState } from "react";
import { TextField, Button, Modal, Typography, Box,FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "1em",
};

function CopyPermissionModal({ open, handleClose, data,handleCopyPermissionSubmit,t }) {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .email(t("admin_page.manage_permissions.copy_modal.validations_translations.invalid_email"))
    .test('is-berryglobal', t("admin_page.manage_permissions.copy_modal.validations_translations.invalid_email"), value => {
      return /@berryglobal\..+$/i.test(value);
    })
    .required(t("admin_page.manage_permissions.copy_modal.validations_translations.required")),
    employeeId: Yup.string()  
    .matches(/^[a-zA-Z0-9]*$/, t("admin_page.manage_permissions.copy_modal.validations_translations.only_alphanumeric"))
    .max(10, t("admin_page.manage_permissions.copy_modal.validations_translations.max_ten_characters"))
    .required(t("admin_page.manage_permissions.copy_modal.validations_translations.employeeId_required")),
  });

  const formik = useFormik({
    initialValues: { email: "", employeeId: "" },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const finalObj = {
        previousUserEmailId: data && data.userEmailId,
        plantId: data && data.plantId,
        newUserEmailId: formik.values.email,
        employeeId: formik.values.employeeId,
      };
      handleCopyPermissionSubmit(finalObj, closeModalHandler);
    },
  });

  const closeModalHandler = () => {
    formik.resetForm();
    handleClose();
  }
 return (
    <Modal open={open} onClose={closeModalHandler}>
      <Box
        sx={{
          backgroundColor: "white",
          // height: "20%",
          width: "50%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "1em",
          paddingBottom: "0",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          {t("admin_page.manage_permissions.copy_modal.title")}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
        
          <Box display="flex" sx={{ margin: "2%" }}>
            <TextField
              variant="outlined"
              errorStyle={{position: "absolute"}}
              sx={{
                flex: 1,
                marginRight: "2%",
                justifyContent: "center",
                fontSize: "0.875rem",
              }}
              inputProps={{
                maxLength: 10,
              }}
              size="small"
              // required
              name="employeeId"
              type="text"
              label={
                
                `${t("admin_page.manage_permissions.copy_modal.employee_id")}` +
                "*  \u00A0"
              }
              value={formik.values.employeeId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.employeeId && Boolean(formik.errors.employeeId)
              }
              helperText={<FormHelperText>{formik.touched.employeeId &&  formik.errors.employeeId ? formik.errors.employeeId : " "}</FormHelperText> }
            />
            <TextField
              size="small"
              errorStyle={{position: "absolute"}}
              sx={{ flex: 1, marginRight: "2%", fontSize: "0.875rem" }}
              name="email"
              type="text"
              variant="outlined"
              // required
              label={
                `${t("admin_page.manage_permissions.copy_modal.email_id")}` + "*  \u00A0"
              }
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={<FormHelperText>{formik.touched.email &&  formik.errors.email? formik.errors.email : " "}</FormHelperText> }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              // backgroundColor: "red",
            }}
          >
            <Button
              sx={{ margin: 2 }}
              variant="outlined"
              type="button"
              onClick={closeModalHandler}
            >
              {t("admin_page.manage_permissions.copy_modal.cancel")}
            </Button>
            <Button
              sx={{ margin: 2, marginRight: 5 }}
              variant="contained"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {t("admin_page.manage_permissions.copy_modal.submit")}
            </Button>
          </Box>
          {/* </Box> */}
        </form>
      </Box>
    </Modal>
  );
}

export default  withTranslation()(CopyPermissionModal);
