import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: [],
  message: "",
  statusText: "",
  severity: "",
};

/*
  Get Access Requests

*/
export const getAccessRequests = createAsyncThunk(
  "getAccessRequests",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}getPlantRequest`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get access requests");
    }
  }
);

/*

Approve reject access request

*/
export const updateAccessRequest = createAsyncThunk(
  "updateAccessRequest",
  async (params, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }updatePlantRequestStatus`;
    try {
      const resp = await axios.post(url, params);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response && err.response.data && err.response.data.message);
    }
  }
);

export const accessRequestSlice = createSlice({
  initialState,
  name: "accessRequests",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccessRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccessRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
        // state.statusText = "success";
        // state.severity = "success";
      })
      .addCase(getAccessRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "failed";
        state.severity = "error";
      })
      .addCase(updateAccessRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccessRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(updateAccessRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "failed";
        state.severity = "error";
      });
  },
});

export default accessRequestSlice.reducer;
