import {
  Autocomplete,
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ProductionDetails from "./ProductionDetails";
import DowntimeDetails from "./DowntimeDetails";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MACHINE_LIST_COLUMNS,
  ROWS_PER_PAGE_OPTIONS,
  ROW_PER_PAGE_INITIAL_COUNT,
} from "../../constants/MachineList";
import EmptyList from "./EmptyList";
import Status from "../Status/Status";
import StatusPopover from "./StatusPopover";
import { useTheme } from "@emotion/react";
import { withTranslation } from "react-i18next";
import { getRunTimeData } from "../../reducers/RuntimeDetailSlice";
import { getDownTimeData } from "../../reducers/DowntimeDetailSlice";
import { getMachineData } from "../../reducers/MachineSlice";
// import NotScheduledDetails from "./NotScheduledDetails";
// import { getNotScheduledData } from "../../reducers/NotScheduledDetailsSlice";
import ScrapDetails from "./ScrapDetails";
import { getScrapDetails } from "../../reducers/ScrapDetailsSlice";
import { v4 as uuid } from 'uuid';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const MachineList = ({
  machineSelectionValues,
  isProceedClicked,
  tabValue,
  setTabValue,
  isDataChanged,
  setDataChangeStatus,
  showWarningModal,
  setShowWarningModal,
  handleMachineSelectionInputChange,
  t,
}) => {
  const [filterdData, setFilterdData] = useState([]);
  const machineListState = useSelector((state) => state.machineListState);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_INITIAL_COUNT);
  const [page, setPage] = useState(0);
  const [selectedMachineCode, setMachineCode] = useState(null);
  const [selectedMachineId, setMachineId] = useState(null)

  const [rows, setRows] = useState([]);
  const [groupData, setGroupData] = useState([])

  const [autoCompleteValue, setAutoCompleteValue] = useState({label: ""});

  const autoCompleteStyles = {
    ".MuiInputBase-root": {
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: "13px",
    },
  };

  const dispatch = useDispatch();

  const machineListOptions = [
    ...new Set(machineListState.items.map((obj) => obj.machineCode)),
  ].map((col) => ({
    label: col,
  }));

  const { date, shift, crew, departmentId, deptPlantId } = machineSelectionValues;

  const handleTabChange = (
    e,
    newValue,
    machineCode = null,
    pendingEvent = false,
    machineId = null
  ) => {
    if (isDataChanged && !pendingEvent) {
      setShowWarningModal(true);
      localStorage.setItem(
        "pendingEvent",
        JSON.stringify({
          functionType: "tab",
          value: newValue ? newValue : 0,
          machineCode: machineCode,
        })
      );
      return;
    }
    setTabValue(newValue);
    setMachineCode(machineCode);
    setMachineId(machineId)
    if (newValue === 0) {
      const fetchMachineList = () => {
        dispatch(getMachineData(machineSelectionValues));
      };
      fetchMachineList();
    }
    if (newValue === 1) {
      const fetchApi = () => {
        dispatch(
          getRunTimeData({
            date,
            shift,
            machinecode: machineCode ? machineCode : null,
            crew,
            machineId,
            deptPlantId: machineCode ? null : deptPlantId,
          })
        );
      };
      fetchApi();
    }
    if (newValue === 2) {
      const fetchApi = () => {
        dispatch(
          getDownTimeData({
            date,
            shift,
            machinecode: machineCode ? machineCode : null,
            crew,
            machineId,
            deptPlantId: machineCode ? null : deptPlantId,
          })
        );
      };
      fetchApi();
    }
    // if (newValue === 3) {
    //   const fetchApi = () => {
    //     dispatch(
    //       getNotScheduledData({
    //         date,
    //         shift,
    //         machinecode: machineCode ? machineCode : null,
    //         crew,
    //         deptId: machineCode ? null : departmentId,
    //       })
    //     );
    //   };
    //   fetchApi();
    // }
    if (newValue === 3) {
      const fetchApi = () => {
        dispatch(
          getScrapDetails({
            date,
            shift,
            machinecode: machineCode ? machineCode : null,
            crew,
            machineId,
            deptPlantId: machineCode ? null : deptPlantId,
          })
        );
      };
      fetchApi();
    }
  };

  const machineList = filterdData.length ? filterdData : groupData;

  useEffect(() => {
    setRows(
      machineListState.items.map((obj) => {
        return { ...obj, id: uuid() }; // added new unique uuid
      })
    );
  }, [machineListState.items]);

  const filterMachineList = ({ label }) => {
    if(label){
      setFilterdData(
        groupData.filter(({ machineCode }) => machineCode === label)
      );
    } else {
      setFilterdData([])
    }
  };

  // let listOfMachines = filterdData.length
  //   ? filterdData
  //   : groupData;

  // if (!isProceedClicked) {
  //   listOfMachines = [];
  // }

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const theme = useTheme();

  const visibleRows = useMemo(
    () =>
      machineList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, machineList]
  );

  const tableCellStyles = {
    p: "0.313rem",
  };
  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 1,
    pl: "0.313rem"
  };

  const tableStyles = {
    mt: 1,
    "@media (min-width: 1140px)": {
      height: "53vh",
    },
    "@media (min-width: 1400px)": {
      height: "65vh",
    },
  };

  const emptyListStyles = {
    "@media (max-width: 1130px)": {
      height: "60vh",
    },
    "@media (min-width: 1140px)": {
      height: "60vh",
    },
    "@media (min-width: 1400px)": {
      height: "70vh",
    },
  };

  const tabStyles = {
    color: !isProceedClicked
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
  };

  useEffect(()=>{
    const unique = new Set();
    
    const uniquesMachines = rows.filter((r)=>{
      if(!unique.has(r.machineCode) ){
        unique.add(r.machineCode);
        return true
      }
      return false
    })

    const combinedRowsAndUnique = [];

    for(let i =0; i<uniquesMachines.length; i++){
      let found = false
      for(let j=0;j<rows.length; j++){

        if(uniquesMachines[i].machineCode === rows[j].machineCode && rows[j].machineworkDetailId){
          found = true
          combinedRowsAndUnique.push(rows[j])
        }
      }

      if(!found){
        combinedRowsAndUnique.push(uniquesMachines[i])
      }

    }

    setGroupData(combinedRowsAndUnique)
  }, [rows])

  useEffect(()=>{
    if(autoCompleteValue?.label !== ""){
      setFilterdData([])
      setAutoCompleteValue({label: ""})
    }
  }, [tabValue, isProceedClicked])

  return (
    <Box>
      <Box sx={{ mt: 1.5 }}>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor={!isProceedClicked ? "" : "primary"}
        >
          <Tab
            label={t("machine_list.machine_list_tab")}
            index={0}
            disabled={!isProceedClicked}
            sx={tabStyles}
          />
          <Tab
            label={t("machine_list.production_details_tab")}
            index={1}
            disabled={!isProceedClicked}
            sx={tabStyles}
          />
          <Tab
            label={t("machine_list.downtime_details_tab")}
            index={2}
            disabled={!isProceedClicked}
            sx={tabStyles}
          />
          <Tab
            label={t("machine_list.scrap_details_tab")}
            index={3}
            disabled={!isProceedClicked}
            sx={tabStyles}
          />
          {/* <Tab
            label={t("machine_list.not_scheduled_details_tab")}
            index={3}
            disabled={!isProceedClicked}
            sx={{
              color: !isProceedClicked
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
            }}
          /> */}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {!isProceedClicked ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // height: 300,
                ...emptyListStyles,
              }}
            >
              <EmptyList />
            </Box>
          ) : (
            <>
              <Autocomplete
                value={autoCompleteValue}
                sx={{ width: 300, mt: 1, ml: "auto", ...autoCompleteStyles }}
                options={machineListOptions}
                disabled={!isProceedClicked} // add pointer styles for disabled state
                onChange={(e, newValue) => {
                  setAutoCompleteValue(newValue)
                  setPage(0)
                  filterMachineList(
                    newValue === null ? { label: "" } : newValue
                  );
                }}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label={t("search_by_machines")} />
                )}
                ListboxProps={{
                  style: {
                    fontSize: "0.875rem",
                  },
                }}
              />
              <TableContainer sx={tableStyles}>
                <Table stickyHeader sx={{ border: `1px solid #a6a7a985` }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "&.MuiTableRow-root": {
                          opacity: !isProceedClicked ? 0.5 : 1,
                        },
                      }}
                    >
                      {MACHINE_LIST_COLUMNS.map(
                        ({ id, label, translationKey, width }) => {
                          return id === "status" ? (
                            <TableCell
                              variant="head"
                              key={id}
                              sx={tableHeaderStyles}
                              width={width}
                            >
                              <StatusPopover />
                            </TableCell>
                          ) : (
                            <TableCell
                              variant="head"
                              key={id}
                              sx={tableHeaderStyles}
                              width={width}
                            >
                              {t(translationKey)}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map(
                      (
                        {
                          downTime,
                          item,
                          machineCode,
                          // notScheduled,
                          runTime,
                          status,
                          workorder,
                          scrapQuantity,
                          uom,
                          machineId,
                          productionQuantity
                        },
                        idx
                      ) => (
                        <TableRow key={idx + 1}>
                          {/* <TableCell sx={tableCellStyles}>{idx + 1}</TableCell> */}
                          <TableCell sx={tableCellStyles}>
                            {machineCode}
                          </TableCell>
                          <TableCell sx={tableCellStyles}>
                            {workorder}
                          </TableCell>
                          <TableCell sx={tableCellStyles}>{item}</TableCell>
                          <TableCell sx={tableCellStyles}>
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: "0.875rem",
                                color: theme.palette.primary.main,
                              }}
                              onClick={(e) =>
                                handleTabChange(e, 1, machineCode,false, machineId)
                              }
                            >
                              {runTime === null ? 0 : runTime}
                            </Typography>
                          </TableCell>
                          <TableCell sx={tableCellStyles}>
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: "0.875rem",
                                color: theme.palette.primary.main,
                              }}
                              onClick={(e) =>
                                handleTabChange(e, 2, machineCode,false, machineId)
                              }
                            >
                              {downTime === null ? 0 : downTime}
                            </Typography>
                          </TableCell>
                          <TableCell sx={tableCellStyles}>
                            {productionQuantity === null ? 0: productionQuantity}
                          </TableCell>
                          <TableCell sx={tableCellStyles}>
                            {uom === null ? 0 : uom}
                          </TableCell>
                          <TableCell sx={tableCellStyles}>
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: "0.875rem",
                                color: theme.palette.primary.main,
                              }}
                              onClick={(e) =>
                                handleTabChange(e, 3, machineCode, false, machineId)
                              }
                            >
                              {scrapQuantity === null ? 0 : scrapQuantity}
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontSize: '0.875rem',
                              ...tableCellStyles,
                            }}
                          >
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: "0.875rem",
                                color: theme.palette.primary.main
                              }}
                              onClick={(e) =>
                                handleTabChange(e, 3, machineCode)
                              }
                            >
                              {notScheduled === null ? 0 : notScheduled}
                            </Typography>
                          </TableCell> */}
                          <TableCell sx={tableCellStyles}>
                            <Status status={status} />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                component="div"
                count={machineList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("pagination.rows_per_page")}
                labelDisplayedRows={({ from, to, count }) =>
                  `${t("pagination.from_to_count", { from, to, count })}`
                }
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProductionDetails
            machineSelectionValues={machineSelectionValues}
            selectedMachineCode={selectedMachineCode}
            isProceedClicked={isProceedClicked}
            isDataChanged={isDataChanged}
            setDataChangeStatus={setDataChangeStatus}
            showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
            handleTabChange={handleTabChange}
            handleMachineSelectionInputChange={
              handleMachineSelectionInputChange
            }
            selectedMachineId={selectedMachineId}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <DowntimeDetails
            machineSelectionValues={machineSelectionValues}
            selectedMachineCode={selectedMachineCode}
            isProceedClicked={isProceedClicked}
            isDataChanged={isDataChanged}
            setDataChangeStatus={setDataChangeStatus}
            showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
            handleTabChange={handleTabChange}
            handleMachineSelectionInputChange={
              handleMachineSelectionInputChange
            }
            selectedMachineId={selectedMachineId}
          />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={3}>
          <NotScheduledDetails 
            machineSelectionValues={machineSelectionValues}
            selectedMachineCode={selectedMachineCode}
            isProceedClicked={isProceedClicked}
            isDataChanged = {isDataChanged}
            setDataChangeStatus = {setDataChangeStatus}
            showWarningModal = {showWarningModal}
            setShowWarningModal = {setShowWarningModal}
            handleTabChange = {handleTabChange}
            handleMachineSelectionInputChange = {handleMachineSelectionInputChange}
           />
        </TabPanel> */}

        <TabPanel value={tabValue} index={3}>
          <ScrapDetails
            machineSelectionValues={machineSelectionValues}
            selectedMachineCode={selectedMachineCode}
            isProceedClicked={isProceedClicked}
            isDataChanged={isDataChanged}
            setDataChangeStatus={setDataChangeStatus}
            showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
            handleTabChange={handleTabChange}
            handleMachineSelectionInputChange={
              handleMachineSelectionInputChange
            }
            selectedMachineId={selectedMachineId}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default withTranslation()(MachineList);
