import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  userRole: {},
  message: "",
  statusText: "",
  severity: "",
};

/*
  Get Roles
*/

export const getCurrentUserRole = createAsyncThunk(
  "getCurrentUserRole",
  async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}getroles`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get current user role");
    }
  }
);

export const currentUserRoleSlice = createSlice({
  initialState,
  name: "currentUserRole",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUserRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRole = action.payload;
        state.statusText = "success";
        state.severity = "success";
      })
      .addCase(getCurrentUserRole.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.statusText = "failed";
        state.severity = "error";
      });
  },
});

export default currentUserRoleSlice.reducer;
