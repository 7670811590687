import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import EmptyList from "./EmptyList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ADD_NEW_ROW } from "../../constants/HomeScreen";
import {
  ROWS_PER_PAGE_OPTIONS,
  ROW_PER_PAGE_INITIAL_COUNT,
  SCRAP_DETAILS_COLUMNS,
  NumbersNotStartWithZero,
} from "../../constants/ScrapDetails";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfirmationModal from "../Modal/ConfirmationModal";
import WarningModal from "../Modal/WarningModal";
import Notification from "../Notification/Notification";
import {
  addScrapDetails,
  deleteScrapDetails,
  getScrapDetails,
} from "../../reducers/ScrapDetailsSlice";
import { testRegex } from "../../functions/validator";
import { v4 as uuid } from 'uuid';
import { DATA_ENTRY, DATA_PROCESSING, SCRAP_ITEMS_MAXLENGTH } from "../../constants/GlobalConstants";
import {ContentCopy} from '@mui/icons-material';

const ScrapDetails = ({
  machineSelectionValues,
  selectedMachineCode,
  isProceedClicked,
  isDataChanged,
  setDataChangeStatus,
  showWarningModal,
  setShowWarningModal,
  handleTabChange,
  handleMachineSelectionInputChange,
  selectedMachineId,
  t,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const scrapState = useSelector((state) => state.scrapDetailsState);

  const [rows, setRows] = useState([]);
  const [filterdData, setFilterdData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_INITIAL_COUNT);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  // const [showAddRow, setShowAddRow] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [showMachineEmptyError, setShowMachineEmptyError] = useState(false);
  const [showScrapErrorSnackBar, setShowScrapErrorSnackBar] = useState(false);

  const [showResponseMessage, setShowResponseMessage] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [autoCompleteValue, setAutoCompleteValue] = useState({label: ""});

  const [groupData, setGroupData] = useState([])
  const [workorderMap, setWorkorderMap] = useState({})

  const [duplicateWorkorderEntry, setDuplicatedWorkorderEntry] = useState(false);

  const currentUser = useSelector((state) => state.currentUserRole.userRole.role);

  const { plantId, date, shift, crew, departmentId, deptPlantId } = machineSelectionValues;

  const autoCompleteStyles = {
    ".MuiInputBase-root": {
      height: 32,
    },
    ".MuiFormLabel-root": {
      fontSize: "13px",
    },
  };

  const tableStyles = {
    mt: 1,
    // border: "1px solid green",
    "@media (min-width: 1140px)": {
      height: "53vh",
      // border: "1px solid red",
    },
    "@media (min-width: 1400px)": {
      height: "65vh",
      // border: "1px solid blue",
    },
  };

  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 0.2,
  };

  const tableCellStyles = {
    p: 0,
  };

  const fieldWithoutBorder = {
    border: "none",
    "& fieldset": {
      borderColor: "transparent",
    },
    ".MuiInputBase-input": {
      p: 0,
      pl: "6px",
      fontSize: "0.875rem",
    },
  };

  const cssForWorkorder = {
    border: "none",
    "& fieldset": {
      borderColor: "transparent",
    },
    width: '10rem',
  };

  const menuValuesStyles = {
    fontSize: "14px"
  }

  useEffect(()=>{
    const unique = new Set();
    
    const uniquesMachines = rows.filter((r)=>{
      if(!unique.has(r.machineCode) ){
        unique.add(r.machineCode);
        return true
      }
      return false
    })

    const combinedRowsAndUnique = [];

    for(let i =0; i<uniquesMachines.length; i++){
      let found = false
      for(let j=0;j<rows.length; j++){

        if(uniquesMachines[i].machineCode === rows[j].machineCode && rows[j].scrapDetailId){
          found = true
          combinedRowsAndUnique.push(rows[j])
        }
      }

      if(!found){
        combinedRowsAndUnique.push(uniquesMachines[i])
      }

    }

    setGroupData(combinedRowsAndUnique)
  }, [rows])

  const scrapList = filterdData.length ? filterdData : groupData;

  /**
   * Generate option for autocomplete from each machine list row
   */
  const machineListOptions = [
    ...new Set(scrapState.items.map((obj) => obj.machineCode)),
  ].map((col) => ({
    label: col,
  }));

  const getMachineId = (machine) => {
    const [{ machineId }] = scrapState.items.filter(
      ({ machineCode }) => machineCode === machine
    );
    return machineId;
  };

  useEffect(() => {
    setRows(
      scrapState.items.map((obj) => {
        return { ...obj, isUpdated: false, isCopiedEntry: false,id: uuid() }; // added new unique uuid
      })
    );
  }, [scrapState.items]);

  const rowCount = scrapState?.items
    .filter((obj) => obj.scrapDetailId !== null)
    .filter(
      (obj) =>
        (currentUser === DATA_ENTRY &&
          (obj.dataState === null || obj.dataState === 10)) ||
        (currentUser === DATA_PROCESSING &&
          (obj.dataState === null ||
            obj.dataState === 10 ||
            obj.dataState === 20))
    )
    .map((obj) => obj.scrapDetailId).length;

  //Pagination Code
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, ROW_PER_PAGE_INITIAL_COUNT));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => scrapList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, scrapList]
  );

  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseAlertModal = () => {
    setOpenDeleteModal(false);
  };

  const onAlertModalCancel = () => {
    handleCloseAlertModal();
  };

  const onAlertModalConfirm = () => {
    if (selected.length > 0) {
      setDeleteLoading(true)
      dispatch(
        deleteScrapDetails({
          plantId,
          shift,
          scrapDetailIds: selected,
          deptPlantId
        })
      ).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(
            getScrapDetails({
              date,
              shift,
              machinecode: selectedMachineCode ? selectedMachineCode : null,
              crew,
              deptPlantId: selectedMachineCode ? null : deptPlantId,
              machineId: selectedMachineId ? selectedMachineId : null
            })
          );
        }
        setShowResponseMessage(true);
        setDeleteLoading(false)
        setAutoCompleteValue({label:""})

      });
    }
    handleCloseAlertModal();
    setSelected([]);
  };

  const handleCancel = () => {
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const onCancelModalCancel = () => {
    handleCloseCancelModal();
  };

  const onCancelModalConfirm = () => {
    handleCloseCancelModal();
    setRows(
      scrapState.items.map((obj) => ({ ...obj, isUpdated: false, id: uuid() }))
    );
    setDataChangeStatus(false);
  };

  const onWarningModalConfirm = () => {
    setShowWarningModal(false);
  };

  const onWarningModalCancel = () => {
    setDataChangeStatus(false);
    setShowWarningModal(false);
    setRows(scrapState.items);
    const pendingEvent = JSON.parse(localStorage.getItem("pendingEvent"));
    if (pendingEvent) {
      switch (pendingEvent.functionType) {
        case "tab":
          handleTabChange(
            null,
            pendingEvent.value,
            pendingEvent.machineCode,
            true
          );
          break;
        case "dropDown":
          handleMachineSelectionInputChange(
            pendingEvent.e,
            pendingEvent.obj,
            pendingEvent.name,
            true
          );
          break;
        default:
          break;
      }
    }
  };

  const handleDuplicateRow = (index, machineData, id) =>{
    const clickedEntry = groupData.find((ele)=> ele.id=== id)
    let entriesTillIdMatched = [];
    let entriesAfterMatched = [];
    let isMatched = false

    for(let i=0; i<groupData.length; i++){
      if(!isMatched && groupData[i].id !==clickedEntry.id){
        entriesTillIdMatched.push(groupData[i])
      }

      if(groupData[i].id ===clickedEntry.id) break

    }

    for(let i=0; i<groupData.length; i++){
      if(isMatched){
        entriesAfterMatched.push(groupData[i])
      }

      if(groupData[i].id ===clickedEntry.id) {
        isMatched = true
      }

    }


    const latestEnt = {
      ...machineData,
      machineworkDetailId: null,
      scrapQuantity:"",
      scrapItem:"",
      isUpdated: true,
      isCopiedEntry: true,
      id: uuid(),
    }

    const dupEnt = [
      ...entriesTillIdMatched,
      {...latestEnt},
      {...clickedEntry},
      ...entriesAfterMatched
    ]

    setGroupData(dupEnt)

    
    // setRows(dupEnt)
    // setGroupData(dupEnt)

    if (!isDataChanged) {
      setDataChangeStatus(true);
    }

    return

  }

  const getUpdatedList = () => {
    return groupData.filter((obj) => obj.isUpdated === true);
  };

  // const handleAddNewRow = () => {
  //   setRows(
  //     (rows) => [
  //       {
  //         // isDowntimeDisabled: false,
  //         isUpdated: true,
  //         scrapDetailId: ADD_NEW_ROW,
  //         machineworkDetailId: null,
  //         machineId: 0,
  //         machineCode: "",
  //         uom: "",
  //         scrapItem: "",
  //         scrapQuantity: 0,
  //         workorder: "",
  //       },
  //       ...rows,
  //     ],
  //     setShowAddRow(true)
  //   );
  //   if (!isDataChanged) {
  //     setDataChangeStatus(true);
  //   }
  // };

  const filterScrapList = ({ label }) => {
    if (label) {
      setFilterdData(groupData.filter(({ machineCode }) => machineCode === label));
    } else {
      setFilterdData([]);
    }
  };

  const removeNotSavedItem = (i) => {
    setRows(() => groupData.filter((obj, index) => obj.id !== i));
  };

  /**
   * This memo is used for enable/disable add row button based on dropdown value of select machine
   * If machine is not selected then from the dropdown then add row button should be disabled
   */
  // useMemo(() => {
  //   const changeAddRowVisibility = rows.filter(
  //     ({ machineCode }) => machineCode === ""
  //   );
  //   changeAddRowVisibility.length === 0 && setShowAddRow(false);
  // }, [rows]);

  // useEffect(() => {
  //   //  Test this LOC on UI
  //   if (rows.length === scrapState.items.length) {
  //     setShowAddRow(false);
  //   }
  // }, [rows, scrapState.items.length]);

  /**
   *
   * @param {*} e Sythetic Event
   * @param {*} index Index of element
   *
   * Handles change of every textfield
   */
  const handleInputChange = (e, id) => {
    // rewrite whole thing with quality code (in-progress)
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(groupData));
    const obj = list.filter((ele)=> ele.id === id)

    if (name === "scrapQuantity") {
      // regex to allowed only decimal/float values
      if (!testRegex(NumbersNotStartWithZero, value)) {
        return;
      }
    }

    /**
     *  To get machine code value from select machine dropdown
     */
    if (name === "machineCode" && value.length > 0) {
      obj[0]["machineId"] = getMachineId(value);
      // setShowAddRow(false);
    }

    obj[0][name] = value.trimStart();

    // Check if row data is dirty
    if (
      obj[0]["scrapDetailId"] !== ADD_NEW_ROW &&
      !obj[0]["isUpdated"]
    ) {
      obj[0]["isUpdated"] = true;
    }

    /**
     * Make rows and filtered rows in sync
     */
    const updatedList = list.filter((ele)=> ele.id !== id)

    const finalList = updatedList.length > 0 ? list.map((ele)=> {
      if(ele?.id === updatedList[0]?.id){
        return {...updatedList[0]}
      }
      return ele
    }) : list

    // setRows(finalList);
    setGroupData(finalList)
    if (!isDataChanged) {
      setDataChangeStatus(true);
    }
  };

  const checkIfEntryDuplicatedByWorkorder = () =>{
    const isDuplicateWorkordeEntry = getUpdatedList();
    let flag = false;

    for(let i=0;i<isDuplicateWorkordeEntry.length-1; i++){
      let currMachineCode = isDuplicateWorkordeEntry[i].machineCode;
      let currMachineWorkorder = isDuplicateWorkordeEntry[i].workorder;

      for(let j=i+1; j<isDuplicateWorkordeEntry.length; j++){
        if(isDuplicateWorkordeEntry[j].machineCode === currMachineCode 
          && isDuplicateWorkordeEntry[j].workorder === currMachineWorkorder){
            flag = true
            break;
          }
      }

      if(flag){
        break
      }
    }

    return flag
  }

  const checkIfAllVisibleDataHasDuplicateWorkorder = () =>{
    const isDuplicateWorkorderEntry = getUpdatedList();

    const hashMapOfUpdatedList = {}

    for(let entry of isDuplicateWorkorderEntry){
      hashMapOfUpdatedList[`${entry['machineCode']}${entry['workorder']}`] = 0
    }

    for(let i=0;i<isDuplicateWorkorderEntry.length; i++){
      let currMachineCode = isDuplicateWorkorderEntry[i].machineCode;
      let currMachineWorkorder = isDuplicateWorkorderEntry[i].workorder;

      for(let j=0; j<groupData.length; j++){
        if(groupData[j].machineCode === currMachineCode 
          && groupData[j].workorder === currMachineWorkorder){

            let key = `${groupData[j]['machineCode']}${groupData[j]['workorder']}`
            hashMapOfUpdatedList[key]+= 1
          }
      }
    }


    const isAnyMachineWorkorderRepeting = Object.values(hashMapOfUpdatedList).some(val=> val>1)
    return isAnyMachineWorkorderRepeting

  }

  /**
   * This function gets triggered on save of scrap details
   */
  const handleSubmit = () => {
    const checkValidUpdatedList = getUpdatedList().filter(
      (obj) =>
        obj.scrapQuantity === null ||
        obj.scrapQuantity === "" ||
        obj.scrapItem === null ||
        obj.scrapItem === ""
    );

    const checkMachineList = getUpdatedList().filter(
      (obj) => obj.machineCode === null || obj.machineCode === ""
    );

    if (checkMachineList.length > 0) {
      // if Add New Row machine is not selected then error is shown from here
      setShowMachineEmptyError(true);
    } else if (checkValidUpdatedList.length > 0) {
      //triggers when scrap quantity or uom or scrap item is empty or null
      setShowScrapErrorSnackBar(true);
    }else if(checkIfEntryDuplicatedByWorkorder() || checkIfAllVisibleDataHasDuplicateWorkorder()){
      setDuplicatedWorkorderEntry(true)
      return
    }else {
      /**
       * This is to convert scrapQuantity into float value
       */
      const updatedList = getUpdatedList().map((ele) => {
        ele["scrapQuantity"] =
          ele["scrapQuantity"] && parseInt(ele["scrapQuantity"]);
        return ele;
      });
      setSaveLoading(true)
      dispatch(
        // add scrap details data api call
        addScrapDetails({
          plantId,
          shift,
          crew,
          date,
          scrapDetails: updatedList,
          deptPlantId,
        })
      ).then((data) => {
        // check if data is successfully added and fetch new getScrap details api call
        if (data.meta.requestStatus === "fulfilled") {
          // check both conditions
          dispatch(
            getScrapDetails({
              date,
              shift,
              machinecode: selectedMachineCode ? selectedMachineCode : null,
              crew,
              deptPlantId: selectedMachineCode ? null : deptPlantId,
              machineId: selectedMachineId ? selectedMachineId : null
            })
          );
          setDataChangeStatus(false);
        }
        setShowResponseMessage(true);
        setSaveLoading(false);
        setAutoCompleteValue({label: ""})
      });
    }
  };

  /**
   * Select all of the checkboxes on page
   * @param {*} e
   *
   */
  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelected = scrapState.items
        .filter((obj) => obj.scrapDetailId !== null)
        .filter(
          (obj) =>
            (currentUser === DATA_ENTRY &&
              (obj.dataState === null || obj.dataState === 10)) ||
            (currentUser === DATA_PROCESSING &&
              (obj.dataState === null ||
                obj.dataState === 10 ||
                obj.dataState === 20))
        )
        .map((obj) => obj.scrapDetailId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleCheckBoxClick = (e, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    // Check conditions and work

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getMappingOfWorkOrder = useCallback(() =>{
    const mapping = {};
    const uniqueWorkOrders = {}

    rows.forEach(row => {
      if (!mapping[row.machineCode]) {
        mapping[row.machineCode] = [{ id: row.workorder, label: `${row.workorder}`}];
      } else {
        mapping[row.machineCode].push({ id: row.workorder, label: `${row.workorder}`});
      }
    });


    for (const machineCode in mapping) {
      // Use a Set to remove duplicates based on the "id" property
      const uniqueObjects = Array.from(new Set(mapping[machineCode].map(obj => obj.id))).map(id => {
        return mapping[machineCode].find(obj => obj.id === id);
      });
    
      uniqueWorkOrders[machineCode] = uniqueObjects;
    }

    return uniqueWorkOrders
  }, [rows])

  /**
   *  to refelect data if filtered rows are changed then rows should have same value and vice versa
   */
  useEffect(()=>{

    if(autoCompleteValue && filterdData.length > 0){
      const arr = []

      filterdData.forEach((fd)=>{
        groupData.forEach((r)=>{
          if(fd.id === r.id){
            arr.push(r)
          }
        })
      })
      setFilterdData(arr)
    }
  }, [rows, groupData, autoCompleteValue])

  useEffect(()=>{
    if(autoCompleteValue?.label !== ""){
      setFilterdData([])
      setAutoCompleteValue({label: ""})
    }
  }, [isProceedClicked])

  useEffect(()=>{
    const workorderMapping = getMappingOfWorkOrder();
    setWorkorderMap(workorderMapping)
  },[groupData, getMappingOfWorkOrder])

   /**
   * Duplicated entries in filtered rows, to make rows and filtered rows in sync
   * both entitis should have same value
   */
   useEffect(()=>{

    if(autoCompleteValue && autoCompleteValue['label']){
      const newFiltered = groupData.filter((ele)=>ele.machineCode === autoCompleteValue['label'] )
      setFilterdData(newFiltered)
    }
  }, [groupData])

  /**
   * When we close filter machine it value becomes null hence making it as ""
   * so it won't break UI
   */
  useEffect(()=>{
    if(autoCompleteValue === null){
      setAutoCompleteValue({label:''})
    }
  }, [autoCompleteValue])



  return (
    <Box sx={{ mt: 1 }}>
      {!isProceedClicked ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
          }}
        >
          <EmptyList />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              mt: 1,
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              onClick={handleDelete}
              variant="outlined"
              sx={{
                mr: 2,
                height: 32,
                minWidth: "35px",
              }}
              disabled={!selected.length}
            >
              {deleteLoading ? <CircularProgress
                size={10} 
                color="primary" 
              /> : 
                <DeleteOutlineIcon 
                onClick={handleDelete}
              />}
            </Button>
            {/* <Button
              onClick={handleAddNewRow}
              size="small"
              variant="outlined"
              disabled={showAddRow}
              sx={{ height: 32, textAlign: "center", lineHeight: "70px" }}
            >
              <AddIcon />
              {t("Button_group.add_row")}
            </Button> */}
            <Autocomplete
              value={autoCompleteValue}
              sx={{ width: 300, ...autoCompleteStyles }}
              size="small"
              options={machineListOptions}
              disabled={selectedMachineCode ? true : false}
              onChange={(e, newValue) => {
                setAutoCompleteValue(newValue)
                setPage(0)
                filterScrapList(newValue === null ? { label: "" } : newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option === value
              }
              renderInput={(params) => (
                <TextField {...params} label={t("search_by_machines")} />
              )}
              ListboxProps={{
                style: {
                  fontSize: "0.875rem",
                },
              }}
            />
          </Box>
          <TableContainer sx={tableStyles}>
            <Table
              stickyHeader
              sx={{ border: "1px solid #a6a7a985" }}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {SCRAP_DETAILS_COLUMNS.map(({ id, translationKey, width }) => {
                    return id === "checkbox" ? (
                      <TableCell variant="head" key={id} sx={tableHeaderStyles} width={width}>
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < rowCount
                          }
                          checked={rowCount > 0 && selected.length === rowCount}
                          onChange={handleSelectAllClick}
                          size="small"
                        />
                      </TableCell>
                    ) : (
                      <TableCell variant="head" key={id} sx={tableHeaderStyles} width={width}>
                        {t(translationKey)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map(
                  (
                    {
                      scrapDetailId,
                      machineCode,
                      workorder,
                      scrapItem,
                      scrapQuantity,
                      uom,
                      id,
                      isUpdated,
                      isCopiedEntry,
                      dataState
                    },
                    index
                  ) => {
                    const isItemSelected = isSelected(scrapDetailId);

                    const isDataEntryAndNullOrStage10 = currentUser === DATA_ENTRY && (dataState === null || dataState === 10);

                    const isDataProcessingAndStage10or20 = currentUser === DATA_PROCESSING && (dataState === null || dataState === 10 || dataState === 20);

                    const disableAsPerRole = currentUser === DATA_ENTRY ? isDataEntryAndNullOrStage10 : isDataProcessingAndStage10or20;

                    return (
                      <TableRow key={id} hover>
                        <TableCell sx={tableCellStyles} width="100">
                          {scrapDetailId === ADD_NEW_ROW || isCopiedEntry  ? (
                            <HighlightOffIcon
                              sx={{
                                color: theme.palette.error.main,
                                ml: 1,
                                cursor: "pointer",
                              }}
                              onClick={(e) => removeNotSavedItem(id)}
                            />
                          ) : (
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(e) =>
                                handleCheckBoxClick(e, scrapDetailId)
                              }
                              disabled={scrapDetailId === null || !disableAsPerRole}
                              sx={{ opacity: scrapDetailId && disableAsPerRole ? 1 : 0.3}}
                              size="small"
                            />
                          )}
                        </TableCell>

                        <TableCell sx={tableCellStyles}>
                          {scrapDetailId === ADD_NEW_ROW ? (
                            <Select
                              defaultValue=""
                              displayEmpty
                              autoWidth
                              sx={{
                                ...fieldWithoutBorder,
                                ".MuiOutlinedInput-input": {
                                  p: 0,
                                  pl: "6px",
                                },
                              }}
                              name="machineCode"
                              onChange={(e) => handleInputChange(e, id)}
                              value={machineCode}
                            >
                              <MenuItem value="" sx={{ fontSize: "0.875rem" }}>
                                {t(
                                  "machine_list.scrap_list_placeholder.selectMachine"
                                )}
                              </MenuItem>
                              {machineListOptions.map(({ label }, index) => (
                                <MenuItem
                                  key={index}
                                  value={label}
                                  sx={{ fontSize: "0.875rem" }}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            machineCode
                          )}
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          {/* {workorder} */}
                          <Select
                            defaultValue=""
                            displayEmpty
                            // autoWidth
                            // sx={cssForWorkorder}
                            sx={{
                              width: "70%"
                            }}
                            name="workorder"
                            onChange={(e) => handleInputChange(e, id)}
                            // value={workorder && downtimeId ? workorder : ""}
                            value={workorder ? workorder : ""}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 250 } },
                            }}
                            disabled={!disableAsPerRole}
                          >
                            {/* <MenuItem value="" sx={resonsValuesStyles}>
                              {t(
                                "machine_list.downtime_list_placeholder.select_workorder"
                              )}
                            </MenuItem> */}
                            {workorderMap[machineCode].map(({label}, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={label}
                                  sx={menuValuesStyles}
                                >
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {/*
                           ===== Uncomment this code when Workorder is manual entry field =====
                          
                           <TextField
                            name="workorder"
                            placeholder={t(
                              "machine_list.scrap_list_placeholder.addWorkorder"
                            )}
                            value={workorder ? workorder : ""}
                            onChange={(e) => handleInputChange(e, index)}
                            sx={fieldWithoutBorder}
                            // inputProps={{ maxLength: TEXTFIELD_MAXLENGTH }} Ask the maxlength
                          /> */}
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          <TextField
                            name="scrapQuantity"
                            placeholder={t(
                              "machine_list.scrap_list_placeholder.addScrapQuantity"
                            )}
                            value={scrapQuantity ? scrapQuantity : ""}
                            onChange={(e) => handleInputChange(e, id)}
                            sx={{
                              // ...fieldWithoutBorder, 
                              width: "70%",
                              border: isUpdated && !scrapQuantity && `1px solid ${theme.palette.error.main}`
                            }}
                            // inputProps={{ maxLength: TEXTFIELD_MAXLENGTH }} Ask the maxlength
                            disabled={!disableAsPerRole}
                          />
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          {uom}
                          {/* 
                           ===== Uncomment this code when UOM is manual entry field =====
                          
                          <TextField
                            name="uom"
                            placeholder={t(
                              "machine_list.scrap_list_placeholder.addUom"
                            )}
                            value={uom ? uom : ""}
                            onChange={(e) => handleInputChange(e, index)}
                            sx={fieldWithoutBorder}
                            // inputProps={{ maxLength: TEXTFIELD_MAXLENGTH }} Ask the maxlength
                          /> */}
                        </TableCell>

                        <TableCell sx={tableCellStyles}>
                          <TextField
                            name="scrapItem"
                            placeholder={t(
                              "machine_list.scrap_list_placeholder.addScrapItem"
                            )}
                            value={scrapItem ? scrapItem : ""}
                            onChange={(e) => handleInputChange(e, id)}
                            sx={{
                              // ...fieldWithoutBorder,
                              width: "70%",
                              border: isUpdated && !scrapItem && `1px solid ${theme.palette.error.main}`
                            }}
                            inputProps={{ maxLength: SCRAP_ITEMS_MAXLENGTH }}
                            disabled={!disableAsPerRole}
                          />
                        </TableCell>
                        <TableCell sx={tableCellStyles}>
                          <Button
                            size="small"
                            sx={{ height: 32, minWidth: "35px" }}
                            onClick={() => {
                              handleDuplicateRow(
                                index,
                                { ...visibleRows[index] },
                                id
                              );
                            }}
                            disabled={!scrapQuantity || !disableAsPerRole}
                            // title="Duplicate record"
                          >
                            <ContentCopy />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Opens when Cancel Button is clicked */}
          <ConfirmationModal
            open={openCancelModal}
            handleClose={handleCloseCancelModal}
            title={t("confirmation.alert")}
            descriptionText={t("confirmation.cancel_confirmation")}
            noButtonText={t("confirmation.no")}
            yesButtonText={t("confirmation.yes")}
            onNoClick={onCancelModalCancel}
            onYesClick={onCancelModalConfirm}
          />

          {/* Opens when Delete Button is clicked */}
          <ConfirmationModal
            open={openDeleteModal}
            handleClose={handleCloseAlertModal}
            title={t("confirmation.alert")}
            descriptionText={t("confirmation.delete_confirmation")}
            noButtonText={t("confirmation.no")}
            yesButtonText={t("confirmation.yes")}
            onNoClick={onAlertModalCancel}
            onYesClick={onAlertModalConfirm}
          />

          {/* This modal open when data is dirty and user navigate to different tab, plant, department, date, shift, crew*/}
          <WarningModal
            open={showWarningModal}
            handleClose={() => setShowWarningModal(false)}
            title={t("confirmation.warning")}
            subTitle={t("confirmation.confirm_navigation")}
            descriptionText={t("confirmation.data_lost_warning")}
            cancelButtonText={t("confirmation.leave_without_saving")}
            confirmButtonText={t("confirmation.stay_on_this_page")}
            onCancelClick={onWarningModalCancel}
            onConfirmClick={onWarningModalConfirm}
          />

          {/* Snackbar for mandatory fields */}
          <Notification
            open={showScrapErrorSnackBar}
            onClose={() => setShowScrapErrorSnackBar(false)}
            severity="error"
            message={t("message.mandatory_fields")}
          />

          {/* Snackbar trigger if add new row machine is not selected */}
          <Notification
            open={showMachineEmptyError}
            onClose={() => setShowMachineEmptyError(false)}
            severity="error"
            message={t("message.machine_empty")}
          />

          {/* Snackbar for api success, error response */}
          <Notification
            open={showResponseMessage}
            onClose={() => setShowResponseMessage(false)}
            severity={scrapState.severity}
            message={scrapState.message}
          />

          <Notification
            open={duplicateWorkorderEntry}
            onClose={() => setDuplicatedWorkorderEntry(false)}
            severity="error"
            message={t("message.duplicate_entry_for_same_workorder")}
          />

          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={scrapList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("pagination.rows_per_page")}
            labelDisplayedRows={({ from, to, count }) =>
              `${t("pagination.from_to_count", { from, to, count })}`
            }
          />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={!isDataChanged}
              sx={{ height: 32 }}
            >
              {t("Button_group.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={(e) => handleSubmit()}
              disabled={!isDataChanged}
              sx={{ height: 32 }}
            >
              {saveLoading ? <CircularProgress size={10} color="secondary" /> : t("Button_group.save")}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default withTranslation()(ScrapDetails);
