import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {ALL_CREWS} from '../constants/HomeScreen';

const initialState = {
    isLoading: false,
    items: [],
    reasons: [],
    subReasons: [],
    message: '',
    statusText: '',
    severity:'',
}

export const getDownTimeData = createAsyncThunk('getDownTimeData', async (params, thunkAPI) => {
    const newParams = {...params, crew: params.crew === ALL_CREWS ? null : params.crew};
    const url = `${JSON.parse(localStorage.berryConfig).URL}downtimedetails`;
    try{
        const resp = await axios.post(url, newParams);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue('Failed to get Downtime list');
    }
});

export const addDownTimeData = createAsyncThunk('addDownTimeData', async (params, thunkAPI) => {
    const newParams = {...params, crew: params.crew === ALL_CREWS ? null : params.crew};
        const url = `${JSON.parse(localStorage.berryConfig).URL}downtimedetails/add`;
    try{
        const resp = await axios.post(url, newParams);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue(err.response && err.response.data && err.response.data.message);
    }
});

export const deleteDownTimeData = createAsyncThunk('deleteDownTimeData', async (params, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}downtimedetails/delete`;
    try{
        const resp = await axios.delete(url, {data: params});
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue(err.response && err.response.data && err.response.data.message);
    }
});

export const getReasonSubReasonData = createAsyncThunk('getReasonSubReasonData', async (deptId, thunkAPI) => {
    const url = `${JSON.parse(localStorage.berryConfig).URL}reasoninfo/${deptId}`;
    try{
        const resp = await axios.get(url);
        return resp.data;
    } catch (err){
        return thunkAPI.rejectWithValue('Failed to get Reason List');
    }
});

export const RuntimeDetailSlice = createSlice({
    initialState,
    name: 'runtimeState',
    reducers: {

    },
    extraReducers: {
        [getDownTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [getDownTimeData.fulfilled]: (state, action) => {
            state.items = action.payload;
            state.isLoading = false;
        },
        [getDownTimeData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        },

        [addDownTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [addDownTimeData.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.statusText = 'success';
            state.severity = 'success';
        },
        [addDownTimeData.rejected]: (state, action) => {
            state.message = action.payload;
            state.isLoading = false;
            state.statusText = 'failed';
            state.severity = 'error';
        },

        [deleteDownTimeData.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteDownTimeData.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.statusText = 'success';
            state.severity = 'success';
        },
        [deleteDownTimeData.rejected]: (state, action) => {
            state.message = action.payload;
            state.isLoading = false;
            state.statusText = 'failed';
            state.severity = 'error';
        },

        [getReasonSubReasonData.pending]: (state) => {
            state.isLoading = true;
        },
        [getReasonSubReasonData.fulfilled]: (state, action) => {
            state.subReasons = action.payload;
            state.reasons = action.payload.map(({reasonId, reasonCode, reasonDesc}) => {
                return {reasonId, reasonCode, reasonDesc};
            });
            state.isLoading = false;
        },
        [getReasonSubReasonData.rejected]: (state, action) => {
            state.message = action.payload
            state.isLoading = false;
        }
    }
});

export default RuntimeDetailSlice.reducer;