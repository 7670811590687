import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";
import React from "react";

const WarningModal = ({
  open,
  handleClose,
  title,
  subTitle,
  descriptionText,
  cancelButtonText,
  confirmButtonText,
  onCancelClick,
  onConfirmClick,
}) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        variant="h5"
        fontWeight="500"
        lineHeight="2rem"
        fontSize="1.25rem"
        color={theme.palette.common.black}
        sx={{ pb: 0 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <DialogContentText sx={{ letterSpacing: "0.1px" }}>
          {subTitle}
        </DialogContentText>
        <DialogContentText
          sx={{
            mt: 2,
            color: theme.palette.common.black,
          }}
        >
          {descriptionText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelClick}
          sx={{
            textTransform: "uppercase",
            letterSpacing: "1.25px",
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={onConfirmClick}
          sx={{
            textTransform: "uppercase",
            letterSpacing: "1.25px",
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
