import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: [],
  message: "",
};

export const getDowntimeReportsData = createAsyncThunk(
  "getDowntimeReportsList",
  async (params, thunkAPI) => {
    const { date, plantId } = params;
    let url = `${
      JSON.parse(localStorage.berryConfig).URL
    }downtime-report/${date}/${plantId}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed to get Downtime Entries list");
    }
  }
);

export const DowntimeReportsSlice = createSlice({
  initialState,
  name: "downtimeReportsState",
  reducers: {},
  extraReducers: {
    [getDowntimeReportsData.pending]: (state) => {
      state.isLoading = true;
    },
    [getDowntimeReportsData.fulfilled]: (state, action) => {
      state.message = "success";
      const sequence = action.payload.map(
        ({ inputDate, shift, machineCode, downtime, remark, reason }) => ({
          inputDate,
          shift,
          machineCode,
          downtime,
          reason,
          remark,
        })
      );
      state.items = sequence;
      state.isLoading = false;
    },
    [getDowntimeReportsData.rejected]: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
  },
});

export default DowntimeReportsSlice.reducer;
