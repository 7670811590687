import { useTheme } from "@mui/material";

export const CssProps = (propType) => {
  const theme = useTheme();
  const csvButton = {
    outline: "none",
    border: "none",
    background: theme.palette.primary.main,
    padding: "6px 16px",
    fontSize: "0.875rem",
    lineHeight: "2.25rem",
    color: theme.palette.common.white,
    borderRadius: "4px",
    minWidth: "64px",
    textTransform: "uppercase",
    fontWeight: 500,
    height: "40px",
    marginBottom: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    letterSpacing: "1.5px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  };
  switch (propType) {
    case "csvButton":
      return csvButton;
    default:
      return {};
  }
};
