import "./App.css";
import { withTranslation } from "react-i18next";
import Header from "./components/Header/Header";
import { useEffect, useState } from "react";
import Home from "./components/Home/Home";
// import AzureAD from "react-aad-msal";
import { authProvider } from "./authProvider";
import { setTokenInAxios } from "./axiosInterceptor";
import { getCurrentUserRole } from "./reducers/CurrentUserRoleSlice";
import { useDispatch, useSelector } from "react-redux";
import AdminPage from "./components/Admin/AdminPage";
import { Box, CircularProgress } from "@mui/material";
// import Footer from "./components/Footer/Footer";

function App() {
  const [userDetails, setUserDetails] = useState({ userName: "", name: "" });
  const dispatch = useDispatch();

  setTokenInAxios(authProvider["_accountInfo"].jwtIdToken);
  useEffect(() => {
    setTokenInAxios(authProvider["_accountInfo"]?.jwtIdToken);
    localStorage.setItem("username", authProvider?.account?.userName);
    localStorage.setItem("name", authProvider?.account?.name);
    setUserDetails({
      userName: authProvider?.account?.userName,
      name: authProvider?.account?.name,
    });
  }, [authProvider["_accountInfo"].jwtIdToken]);

  useEffect(() => {
    dispatch(getCurrentUserRole());
  }, []);

  const currentUserState = useSelector((state) => state.currentUserRole);
  const { isLoading: userRoleLoading, userRole } = currentUserState;

  return (
    // <AzureAD provider={authProvider} forceLogin={true}>
    <>
      {userRoleLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      ) : userRole.role === "Admin" ? (
        <Box>
          <Header
            userDetails={userDetails}
            authProvider={authProvider}
            isAdmin={userRole.role === "Admin"}
          />
          <AdminPage />
          {/* <Footer /> */}
        </Box>
      ) : userRole.role === "Data Entry" || userRole.role === "Data Processing"  ?(
        <>
          <Header userDetails={userDetails} authProvider={authProvider} />
          <Home />
        </>
      ): null}
    </>
    // </AzureAD>
  );
}

export default withTranslation()(App);
