import React from "react";
import { useNavigate } from "react-router-dom";
import LandingPageHeader from "../Header/LandingPageHeader";
import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import CenteredCard from "../Landing/Card";
import LandingLogo from "../../assets/png/LandingBackground.png";

const LandingPage = ({ t }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 74, 152, 0.80), rgba(0, 74, 152, 0.80)), url(${LandingLogo})`,
        height: "100vh",
        backgroundSize: "cover",
      }}
    >
      <LandingPageHeader />
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        height="95vh"
        gap="2rem"
      >
        <CenteredCard
          mainText={t("landing_page.request_card.main_title")}
          subText={t("landing_page.request_card.sub_title")}
          buttonText={t("landing_page.request_card.button_text")}
          onClick={() => navigate("/plant-request")}
          styles={{ transform: "translateY(-20%)" }}
        />

        <CenteredCard
          styles={{ transform: "translateY(20%)" }}
          mainText={t("landing_page.login_card.main_title")}
          subText={t("landing_page.login_card.sub_title")}
          buttonText={t("landing_page.login_card.button_text")}
          onClick={() => navigate("/home")}
        />
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default withTranslation()(LandingPage);
