export const NEW_REQUEST_TABLE_COLUMNS = [
  {
    id: "user_first_name",
    translationKey: "admin_page.access_request_table.user_first_name",
    width: 300,
  },
  {
    id: "user_last_name",
    translationKey: "admin_page.access_request_table.user_last_name",
    width: 300,
  },
  {
    id: "email_id",
    translationKey: "admin_page.access_request_table.email_id",
    width: 300,
  },
  {
    id: "employee_id",
    translationKey: "admin_page.access_request_table.employee_id",
    width: 300,
  },
  {
    id: "supervisor_email_id",
    translationKey: "admin_page.access_request_table.supervisor_email_id",
    width: 300,
  },
  {
    id: "plant_name",
    translationKey: "admin_page.access_request_table.plant_name",
    width: 300,
  },
  {
    id: "department",
    translationKey: "admin_page.access_request_table.department",
    width: 300,
  },
  {
    id: "role",
    translationKey: "admin_page.access_request_table.role",
    width: 300,
  },
  // {
  //   id: "additional_information",
  //   translationKey: "admin_page.access_request_table.additional_information",
  //   width: 400,
  // },
  {
    id: "action",
    translationKey: "admin_page.access_request_table.action",
    width: 400,
  },
];

export const ROW_PER_PAGE_INITIAL_COUNT = 50;
export const ROWS_PER_PAGE_OPTIONS = [10,20,50, 100, 200];

