export const validateTextfield = (text) => {
  if(text.length === 0) {return true};
  //RegEx pattern for alphabet, number, underscore, dash, space
  const pattern = /^[a-z\d\-_\s]+$/i;
  return pattern.test(text);
};

export const validateWorkorderPerMachine = (list, machine, workorderNumber) => {
  const itemList = list.filter(
    ({ machineCode, workorder }) =>
      machineCode === machine && workorder === workorderNumber
  );
  if(itemList.length >= 1){
    return false;
  }
  return true;
};

export const testRegex = (pattern, value) => {
  return pattern.test(value);
};