import {
  CheckCircleOutline,
  ErrorOutline,
  HighlightOff,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { withTranslation } from "react-i18next";

const Status = ({ status, t }) => {
  const theme = useTheme();
  const getIcons = () => {
    switch (status ? status.toLowerCase() : status) {
      case "good":
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.success.main,
              }}
            >
              <CheckCircleOutline />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                color: theme.palette.success.main,
              }}
            >
              {t("status.good")}
            </div>
          </>
        );
      case "error":
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.error.main,
              }}
            >
              <ErrorOutline />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                color: theme.palette.error.main,
              }}
            >
              {t("status.error")}
            </div>
          </>
        );
      case "not scheduled":
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.custom.lightOrange,
              }}
            >
              <HighlightOff />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                color: theme.palette.custom.lightOrange,
              }}
            >
              {t("status.not_scheduled")}
            </div>
          </>
        );
      default:
        return (
          <>
            <div style={{ color: theme.palette.warning.light }}>
              <DoNotDisturbOnOutlined />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                color: theme.palette.warning.light,
              }}
            >
              {t("status.empty")}
            </div>
          </>
        );
    }
  };
  return (
    <div
      style={{
        display: "flex",
        columnGap: 10,
        alignItems: "center",
      }}
    >
      {getIcons()}
    </div>
  );
};

export default withTranslation()(Status);