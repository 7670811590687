import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  items: {},
  message: "",
};
export const getDepartmentData = createAsyncThunk(
  "getDepartmentData",
  async (deptPlantId, thunkAPI) => {
    const url = `${
      JSON.parse(localStorage.berryConfig).URL
    }department/${deptPlantId}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Failed dapartment data");
    }
  }
);

export const DepartmentSlice = createSlice({
  initialState,
  name: "departmentState",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartmentData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(getDepartmentData.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default DepartmentSlice.reducer;
