import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import CsvDownloadButton from "react-json-to-csv";
import format from "date-fns/format";
import { useSelector, useDispatch } from "react-redux";
import { DOWNTIME_REPORTS_COLUMNS } from "../../constants/DowntimeReports";
import { getPlantsData } from "../../reducers/CurrentEntriesReportsSlice";
import { getDowntimeReportsData } from "../../reducers/DowntimeReportsSlice";
import { CssProps } from "../../themes/css";
import { authProvider } from "../../authProvider";
import Header from "../Header/Header";
import { setTokenInAxios } from "../../axiosInterceptor";

const Downtime = ({ t }) => {
  const [site, setSite] = useState("");
  const downtimeReportsState = useSelector(
    (state) => state.downtimeReportsState
  );
  const [reportData, setReportData] = useState([]);
  const [plantsList, setPlantsList] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();

  const tableStyles = {
    mt: 2,
    "@media (min-width: 1140px)": {
      minHeight: "68vh",
      maxHeight: "68vh",
    },
    "@media (min-width: 1400px)": {
      minHeight: "77vh",
      maxHeight: "77vh",
    },
  };

  const tableHeaderStyles = {
    backgroundColor: theme.palette.secondary.main,
    p: 1,
  };

  const tableCellStyles = {
    p: 1,
  };
  const todayDate = format(new Date(), "yyyy-MM-dd");

  useEffect(() => {
    setTokenInAxios(authProvider["_accountInfo"].jwtIdToken);
    getPlantsList();
  }, []);

  const getPlantsList = () => {
    dispatch(getPlantsData()).then((data) => {
      setPlantsList(data.payload);
      setSite(data.payload[0].plantId);
      getReports({
        date: todayDate,
        plantId: data.payload[0].plantId,
      });
    });
  };

  const getReports = (params) => {
    dispatch(getDowntimeReportsData(params)).then((data) =>
      setReportData(data.payload)
    );
  };

  const onSiteChange = (e) => {
    setSite(e.target.value);
    getReports({
      date: todayDate,
      plantId: e.target.value,
    });
  };

  const buttonStyles = CssProps('csvButton')

  return (
    <>
     <Header
      authProvider={authProvider} 
      userDetails={{
        userName: authProvider?.account?.userName,
        name: authProvider?.account?.name,
      }}
    />
    <Box sx={{ mt: 6, pl: 3, pr: 3, display: "flex", flexDirection: "column" }}>
      <Typography fontWeight="600" fontSize="18px" color="text.primary">
        {t("reports.downtime.label")}
      </Typography>
      <FormControl sx={{ mt: 2, width: "14.125rem" }} size="small" required>
        <InputLabel>{t("reports.site")}</InputLabel>
        <Select value={site} label={t("reports.site")} onChange={onSiteChange}>
          {plantsList.map(({ plantId, plantName }) => {
            return (
              <MenuItem key={plantId} value={plantId}>
                {plantName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TableContainer
        sx={{
          minHeight: "70vh",
          maxHeight: "70vh",
          ...tableStyles,
        }}
      >
        <Table stickyHeader sx={{ border: `1px solid #a6a7a985`}}>
          <TableHead>
            <TableRow>
              {DOWNTIME_REPORTS_COLUMNS.map(({ id, label }, index) => {
                return (
                  <TableCell variant="head" key={id} sx={tableHeaderStyles}>
                    {t(label)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.length === 0 ? (
              <TableRow>
                <TableCell colSpan="7">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      letterSpacing: "0.25px",
                      lineHeight: "32px",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {t("reports.empty_list_text")}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              reportData.map(
                (
                  { inputDate, shift, machineCode, downtime, remark, reason },
                  index
                ) => {
                  return (
                    <TableRow key={index} hover>
                      {/* <TableCell sx={tableCellStyles}>{index + 1}</TableCell> */}
                      <TableCell sx={tableCellStyles}>{inputDate}</TableCell>
                      <TableCell sx={tableCellStyles}>{shift}</TableCell>
                      <TableCell sx={tableCellStyles}>{machineCode}</TableCell>
                      <TableCell sx={tableCellStyles}>{reason}</TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyles,
                          width: "25rem",
                          maxWidth: "25rem",
                          minWidth: "25rem",
                          wordWrap: "break-word",
                        }}
                      >
                        {remark ? remark : ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>{downtime}</TableCell>
                    </TableRow>
                  );
                }
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CsvDownloadButton
          data={downtimeReportsState.items}
          filename={`downtime-${todayDate}.csv`}
          delimiter=","
          style={buttonStyles}
        >
          {t("reports.download_button")}
        </CsvDownloadButton>
      </Box>
    </Box>
  </>

  );
};

export default withTranslation()(Downtime);
