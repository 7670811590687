import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import english from './locales/en/translations.json';
import french from './locales/fr/translations.json';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  .use(LanguageDetector) // to detect language of browser
  .use(initReactI18next) // initializes react-i18next
  .init({
    supportedLanguages: ["en", "fr"],
    fallbackLng: "en",
    resources: {
        en: {
          translation: english
        },
        fr: {
          translation: french
        }
      },
    detection: {
      // order and from where user language should be detected. Default cookie name is: i18next
      order: [
        "cookie",
        "htmlTag",
        "querystring",
        "localStorage",
        "sessionStorage",
      ],
      caches: ["cookie"],
    },
    // backend: {
    //   loadPath: "/assets/locales/{{lng}}/translation.json", //path where resources get loaded from
    // },
  });

  export default i18n;